import React, { useState, useEffect, useContext } from 'react';
import './CompSwimlaneCard.css';
import { getAnchorsPersonasByAnchorId } from '../fxAPI/fxAnchorsAPI';
import { PanelContext } from '../Contexts/PanelContext';


const CompSwimlaneCard = (props) => {
  const [error, setError] = useState(null);
  const [anchorsPersonasByAnchorId, setAnchorsPersonasByAnchorData] = useState([]);
  const [isStepCard, setIsStepCard] = useState(false); // New state for isStepCard

  /* get user personas by orgId */
  const fxAPIFetchAnchorsPersonasByAnchorId = async (anchorId) => {
    await getAnchorsPersonasByAnchorId(anchorId, setError, setAnchorsPersonasByAnchorData);
    console.log('Data fetch initiated for anchorId:', anchorId);
  };

  // Check if anchor_id and user_persona_id match and update isStepCard
  useEffect(() => {
    const checkIfStepCard = () => {
      const match = anchorsPersonasByAnchorId.some(
        (persona) =>
          persona.anchor_id === props.anchorId &&
          persona.user_persona_id === props.userPersonaId
      );
      console.log(`Checking if Step Card for anchorId: ${props.anchorId}, userPersonaId: ${props.userPersonaId}, match: ${match}`);
      setIsStepCard(match); // Set isStepCard to true if match found, otherwise false
    };

    checkIfStepCard();
  }, [anchorsPersonasByAnchorId, props.anchorId, props.userPersonaId]); // Run the effect when state or props change

  useEffect(() => {
    fxAPIFetchAnchorsPersonasByAnchorId(props.anchorId);
  }, [props.anchorId]);

  // Notify parent component about isStepCard state
  useEffect(() => {
    if (props.onStepCardStatusChange) {
        console.log(`Notifying parent about isStepCard state: ${isStepCard} for anchorId: ${props.anchorId}`);
        props.onStepCardStatusChange(isStepCard);
    }
    // Only notify when isStepCard changes
  }, [isStepCard]);

  function ResponsiveSVG() {
    const handleClick = (event, additionalParam) => {
      console.log('SVG clicked', event, additionalParam);
    };

    /* Use Global Context to refresh Subjective Panel */
    const { triggerElementRefresh } = useContext(PanelContext);
  
    const handleRefreshElement = () => {
      triggerElementRefresh('sideContextPanel'); // Trigger refresh for the subjectivePanel
    };
  
    const handleSwitchPanelContext = () => {
      try {
        localStorage.setItem("fxSidePanelContext", "anchor");
        localStorage.setItem("fxSidePanelObjectId", "test-anchor-id");
        localStorage.setItem("fxSidePanelObject", JSON.stringify({ name: "Demo Object", description: "This is a demo object for testing." }));
        handleRefreshElement();
      } catch (e) {
        console.error("Error setting localStorage items", e);
      }
    };

    return (
      <div style={{ width: '100%', maxWidth: '400px' }} onClick={handleSwitchPanelContext}>
        <svg
          viewBox="0 0 200 100"
          xmlns="http://www.w3.org/2000/svg"
          style={{ width: '100%', height: 'auto' }}
        >
          <rect
            x="10"
            y="10"
            width="180"
            height="80"
            rx="15"
            ry="15"
            fill="#ecf3ec"
            stroke="#00a91c"
            strokeWidth="4" // Use camelCase for attributes like 'stroke-width'
          />
          <text
            x="50%"
            y="50%"
            dominantBaseline="middle"
            textAnchor="middle"
            style={{ fill: '#00a91c', fontSize: '14px', fontFamily: 'Arial, sans-serif' }}
          >
            {props.anchorName}
          </text>
        </svg>
      </div>
    );
  }

  return (
    <div>
      <div className="fx-comp-swimlane-card-demo-text">{props.personaName}</div>
      {isStepCard ? (
        <>
        {props.anchorType === "assignment" &&
          <>
          <div>
            <ResponsiveSVG />
          </div>
          </>
        }
        </>
      ) : (
        <div style={{ visibility: 'hidden', width: '150px', height: '90px' }}></div> // Keep dimensions and placement
      )}
    </div>
  );
};

export default CompSwimlaneCard;