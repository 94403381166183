import React, { useEffect, useState } from 'react';
import './CompCRUDFormProject.css';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import CompModalButtons from './CompCRUDModalButtons';
import EditIcon from '@mui/icons-material/Edit';
import CompCRUDModalActionBar from './CompCRUDModalActionBar';
import { createProject } from '../fxAPI/fxProjectsAPI.js';
import { updateProject } from '../fxAPI/fxProjectsAPI.js';
import axios from 'axios';

const CompCRUDFormProject = (props) => {

  // Helper function to generate a UUID
function generateUUID() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    const r = (Math.random() * 16) | 0,
      v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [error, setError] = useState(null);
  const [projectResponse, setProjectResponse] = useState(null);
  const [updateProjectName, setUpdateProjectName] = useState(props.currentComponentData.name);
  const [updateProjectDescription, setUpdateProjectDescription] = useState(props.currentComponentData.description);

  console.log('current project data on form project: ');
  console.dir(props.currentComponentData);

  const [currentEditableState, setCurrentEditableState] = useState('view');

  const triggerViewMode = (currentViewMode) => {
    setCurrentEditableState(currentViewMode);
  };

  const handleCloseModal = () => {
    props.onClose();
  };

  const handleCreateProject = (event) => {
    event.preventDefault();
    const relatedOrg = localStorage.getItem("fxLocalOrgID");
    createProject(name, description, relatedOrg, setError, setProjectResponse);
    handleCloseModal(); // Close the modal after creating the project
  };

  const handleUpdateProjectNameChange = (e) => {
    setUpdateProjectName(e.target.value);
  }

  const handleUpdateProjectDescriptionChange = (e) => {
    setUpdateProjectDescription(e.target.value);
  }


  const handleUpdateProject = async (e) => {
    e.preventDefault();
    const currentOrgId = localStorage.getItem("fxLocalOrgID");
  
    //const projectId = props.currentComponentData.project_id; // Replace with the actual project ID
    const projectId = props.currentComponentData.project_id;
    const name = updateProjectName;
    const description = updateProjectDescription;
    const related_org = currentOrgId;
  
    await updateProject(projectId, name, description, related_org);
    handleCloseModal(); // Close the modal after updating the project
  };


  

  return (
    <div>
        {props.crudMethod === "create" &&
        <>
        <div>
        <form onSubmit={handleCreateProject}>
        <br />
          <div>
            <TextField id="outlined-basic" label="Playbook Name" variant="outlined" value={name} onChange={(e) => setName(e.target.value)} />
          </div>
          <br />
          <div className="fx-global-form-text-area-description">
            <TextField
                id="outlined-multiline-flexible"
                label="Playbook Description"
                multiline
                maxRows={4}
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                fullWidth
            />  
          </div>
          
          <CompModalButtons>
                <Button type="submit">Create Playbook</Button>
                <Button type="button" onClick={handleCloseModal}>Cancel</Button>
              </CompModalButtons>  
        </form>

        {error && <div style={{ color: 'red' }}>{error}</div>}
        {projectResponse && (
          <div>
            <h3>Playbook Created Successfully:</h3>
            <pre>{JSON.stringify(projectResponse, null, 2)}</pre>
          </div>
        )}
        </div>

        {/* 
        <div>
            <br />
            <form onSubmit={handleCreateProject}>
              <div>
                <TextField id="outlined-basic" label="Project Name" variant="outlined" />
              </div>   
              <br />
              <div className="fx-global-form-text-area-description">
              <TextField
                id="outlined-multiline-flexible"
                label="Project Description"
                multiline
                maxRows={4}
                fullWidth
                />  
              </div>
              <CompModalButtons>
                <Button type="submit">Create Project</Button>
              </CompModalButtons>         
            </form>
        </div>
        */}

        </>
        }
        {props.crudMethod === "view-edit" &&
          <div>
            <CompCRUDModalActionBar>
            { currentEditableState === "view" &&
              <Button onClick={() => triggerViewMode("edit")}><EditIcon /></Button>
            }
            { currentEditableState === "edit" &&
              <Button variant="outlined" onClick={() => triggerViewMode("view")}>Save</Button>
            }
          </CompCRUDModalActionBar>

          { currentEditableState === "view" &&
            <div>
              <div>
                <div className='fx-global-form-read-only-label'>Project Name</div>
                <div>{props.currentComponentData.name}</div>
              </div> 
              <div>
                <div className='fx-global-form-read-only-label'>Project Description</div>
                <div>{props.currentComponentData.description}</div>
              </div> 
            </div>
          } 

          { currentEditableState === "edit" &&
            <div>
              <form onSubmit={handleUpdateProject}>
                <div>
                  <TextField id="outlined-basic" label="Playbook Name" variant="outlined" value={updateProjectName} onChange={handleUpdateProjectNameChange} />
                </div>   
                <br />
                <div className="fx-global-form-text-area-description">
                <TextField
                  id="outlined-multiline-flexible"
                  label="Playbook Description"
                  value={updateProjectDescription}
                  onChange={handleUpdateProjectDescriptionChange}
                  multiline
                  maxRows={4}
                  fullWidth
                  />  
                </div>
                <CompModalButtons>
                  <Button type="submit">Save Project</Button>
                  <Button type="button" onClick={handleCloseModal}>Cancel</Button>
                </CompModalButtons>         
              </form>
            </div>
          }     

          </div>
        }
    </div>
  );
};

export default CompCRUDFormProject;