import React, { useState, useContext } from 'react';
import './CompCRUDFormUserFeedback.css';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem'; // Import MenuItem
import Select from '@mui/material/Select'; // Import Select
import CompModalButtons from './CompCRUDModalButtons';
import CompCRUDModalActionBar from './CompCRUDModalActionBar';
import { createFeedback, updateFeedback, deleteFeedback } from '../fxAPI/fxFeedbackAPI';
import { PanelContext, refreshContext } from '../Contexts/PanelContext';
import { useParams } from "react-router-dom";

const CompCRUDFormUserFeedback = (props) => {
  const [currentEditableState, setCurrentEditableState] = useState('view');
  const [feedbackId, setFeedbackId] = useState('');
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [status, setStatus] = useState('');
  const [authoringUser, setAuthoringUser] = useState(localStorage.getItem('fxLocalUserRole') || '');
  const [relatedObjectType, setRelatedObjectType] = useState(props.relatedAnchorId ? 'anchor' : null);
  const [relatedObject, setRelatedObject] = useState(props.relatedObjectId || null);
  const [relatedAnchor, setRelatedAnchor] = useState('');
  const [relatedProject, setRelatedProject] = useState('');
  const [relatedOrg, setRelatedOrg] = useState('');
  const [dateTimeCreated, setDateTimeCreated] = useState('');
  const [dateTimeUpdated, setDateTimeUpdated] = useState('');
  const [relatedDesignSession, setRelatedDesignSession] = useState('');
  const [feedbackResponse, setFeedbackResponse] = useState(null);
  const params = useParams();

  function generateUUID() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      const r = (Math.random() * 16) | 0, v = c === 'x' ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  }

  const { resetElementRefresh, triggerElementRefresh } = useContext(PanelContext);

  const triggerViewMode = (currentViewMode) => {
    setCurrentEditableState(currentViewMode);
  };

  const handleCloseModal = () => {
    refreshContext(resetElementRefresh, triggerElementRefresh, 'dashboard');
    props.onClose();
  };

  const handleCreateFeedback = async (event) => {
    event.preventDefault();
    const feedbackData = {
      feedback_id: generateUUID(),
      name,
      status: 'new',
      authoring_user: authoringUser,
      related_object_type: relatedObjectType,
      related_object: relatedObject,
      related_anchor: props.relatedAnchorId || null,
      related_project: params.projectId,
      related_org: localStorage.getItem("fxLocalOrgID"),
      related_design_session: props.relatedDesignSessionId || null
    };
    await createFeedback(feedbackData, setFeedbackResponse);
    handleCloseModal();
  };

  const handleUpdateFeedback = async (event) => {
    event.preventDefault();
    const feedbackData = {
      feedback_id: feedbackId,
      name,
      description,
      status,
      authoring_user: authoringUser,
      related_object_type: relatedObjectType,
      related_object: relatedObject,
      related_anchor: relatedAnchor,
      related_project: relatedProject,
      related_org: relatedOrg,
      date_time_created: dateTimeCreated,
      date_time_updated: dateTimeUpdated,
      related_design_session: relatedDesignSession
    };
    await updateFeedback(feedbackId, feedbackData, setFeedbackResponse);
    handleCloseModal();
  };

  const handleDeleteFeedback = async () => {
    await deleteFeedback(feedbackId, console.error, setFeedbackResponse);
    handleCloseModal();
  };

  return (
    <div className={props.crudMethod === "create" && props.formStyle === "darkBG" ? "fx-crud-form-darkBG" : ""}>
      {props.crudMethod === "create" &&
        <div>
          <br />
          <form onSubmit={handleCreateFeedback}>
            <div>
              <TextField id="outlined-basic" label="Name" variant="outlined" onChange={(e) => setName(e.target.value)} />
            </div>
            <br />
            <div>
              <TextField
                id="outlined-multiline-static"
                label="Content"
                multiline
                rows={4}
                variant="outlined"
                onChange={(e) => setDescription(e.target.value)}
              />
            </div>
            <br />
            <CompModalButtons>
              <Button className="fx-feedback-form-button" type="submit">Create Feedback</Button>
            </CompModalButtons>
          </form>
        </div>
      }
      {props.crudMethod === "view-edit" &&
        <div>
          <CompCRUDModalActionBar>
            {currentEditableState === "view" &&
              <Button onClick={() => triggerViewMode("edit")}>Edit</Button>
            }
            {currentEditableState === "edit" &&
              <Button variant="outlined" onClick={() => triggerViewMode("view")}>Save</Button>
            }
          </CompCRUDModalActionBar>

          {currentEditableState === "view" &&
            <div>
              <div>
                <div className='fx-global-form-read-only-label'>Feedback ID</div>
                <div>{props.currentComponentData.feedbackId}</div>
              </div>
              <div>
                <div className='fx-global-form-read-only-label'>Name</div>
                <div>{props.currentComponentData.name}</div>
              </div>
              <div>
                <div className='fx-global-form-read-only-label'>Content</div>
                <div>{props.currentComponentData.description}</div>
              </div>
              <div>
                <div className='fx-global-form-read-only-label'>Status</div>
                <div>{props.currentComponentData.status}</div>
              </div>
              <div>
                <div className='fx-global-form-read-only-label'>Authoring User</div>
                <div>{props.currentComponentData.authoringUser}</div>
              </div>
              <div>
                <div className='fx-global-form-read-only-label'>Related Object Type</div>
                <div>{props.currentComponentData.relatedObjectType}</div>
              </div>
              <div>
                <div className='fx-global-form-read-only-label'>Related Object</div>
                <div>{props.currentComponentData.relatedObject}</div>
              </div>
              <div>
                <div className='fx-global-form-read-only-label'>Related Anchor</div>
                <div>{props.currentComponentData.relatedAnchor}</div>
              </div>
              <div>
                <div className='fx-global-form-read-only-label'>Related Project</div>
                <div>{props.currentComponentData.relatedProject}</div>
              </div>
              <div>
                <div className='fx-global-form-read-only-label'>Related Org</div>
                <div>{props.currentComponentData.relatedOrg}</div>
              </div>
              <div>
                <div className='fx-global-form-read-only-label'>Date Time Created</div>
                <div>{props.currentComponentData.dateTimeCreated}</div>
              </div>
              <div>
                <div className='fx-global-form-read-only-label'>Date Time Updated</div>
                <div>{props.currentComponentData.dateTimeUpdated}</div>
              </div>
              <div>
                <div className='fx-global-form-read-only-label'>Related Design Session</div>
                <div>{props.currentComponentData.relatedDesignSession}</div>
              </div>
            </div>
          }

          {currentEditableState === "edit" &&
            <div>
              <form onSubmit={handleUpdateFeedback}>
                <div>
                  <TextField id="outlined-basic" label="Feedback ID" variant="outlined" value={feedbackId} onChange={(e) => setFeedbackId(e.target.value)} />
                </div>
                <br />
                <div>
                  <TextField id="outlined-basic" label="Name" variant="outlined" value={name} onChange={(e) => setName(e.target.value)} />
                </div>
                <br />
                <div>
                  <TextField id="outlined-basic" label="Content" variant="outlined" value={description} onChange={(e) => setDescription(e.target.value)} />
                </div>
                <br />
                <div>
                  <TextField id="outlined-basic" label="Status" variant="outlined" value={status} onChange={(e) => setStatus(e.target.value)} />
                </div>
                <br />
                <div>
                  <TextField id="outlined-basic" label="Authoring User" variant="outlined" value={authoringUser} onChange={(e) => setAuthoringUser(e.target.value)} />
                </div>
                <br />
                <div>
                  <TextField id="outlined-basic" label="Related Object Type" variant="outlined" value={relatedObjectType} onChange={(e) => setRelatedObjectType(e.target.value)} />
                </div>
                <br />
                <div>
                  <TextField id="outlined-basic" label="Related Object" variant="outlined" value={relatedObject} onChange={(e) => setRelatedObject(e.target.value)} />
                </div>
                <br />
                <div>
                  <TextField id="outlined-basic" label="Related Anchor" variant="outlined" value={relatedAnchor} onChange={(e) => setRelatedAnchor(e.target.value)} />
                </div>
                <br />
                <div>
                  <TextField id="outlined-basic" label="Related Project" variant="outlined" value={relatedProject} onChange={(e) => setRelatedProject(e.target.value)} />
                </div>
                <br />
                <div>
                  <TextField id="outlined-basic" label="Related Org" variant="outlined" value={relatedOrg} onChange={(e) => setRelatedOrg(e.target.value)} />
                </div>
                <br />
                <div>
                  <TextField id="outlined-basic" label="Date Time Created" variant="outlined" value={dateTimeCreated} onChange={(e) => setDateTimeCreated(e.target.value)} />
                </div>
                <br />
                <div>
                  <TextField id="outlined-basic" label="Date Time Updated" variant="outlined" value={dateTimeUpdated} onChange={(e) => setDateTimeUpdated(e.target.value)} />
                </div>
                <br />
                <div>
                  <TextField id="outlined-basic" label="Related Design Session" variant="outlined" value={relatedDesignSession} onChange={(e) => setRelatedDesignSession(e.target.value)} />
                </div>
                <br />
                <CompModalButtons>
                  <Button type='submit'>Save Feedback</Button>
                  <Button type="button" onClick={handleCloseModal}>Cancel</Button>
                </CompModalButtons>
              </form>
            </div>
          }
        </div>
      }
    </div>
  );
};

export default CompCRUDFormUserFeedback;
