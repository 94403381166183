import React from 'react';
import './CompActivityHistoryItem.css';
import CompMiniAvatar from './CompMiniAvatar';

// Define the SourceTag component
const SourceTag = (props) => {

  if(props.sourceName === 'fluxity-status-dev') {
    return (
      <div className='fx-comp-history-item-tag fx-comp-history-item-tag-type-fluxity-status-dev'>Dev</div>
    );
  } else if(props.sourceName === 'fluxity-status-stakeholders') {
    return (
      <div className='fx-comp-history-item-tag fx-comp-history-item-tag-type-fluxity-status-stakeholder'>Stakeholder</div>
    );
  } else if(props.sourceName === 'fluxity-status-ba') {
    return (
      <div className='fx-comp-history-item-tag fx-comp-history-item-tag-type-fluxity-status-ba'>BA</div>
    );
  } else if(props.sourceName === 'fluxity-status-ux') {
    return (
      <div className='fx-comp-history-item-tag fx-comp-history-item-tag-type-fluxity-status-ux'>UX</div>
    );
  } else if(props.sourceName === 'fluxity-status-qa') {
    return (
      <div className='fx-comp-history-item-tag fx-comp-history-item-tag-type-fluxity-status-qa'>QA</div>
    );
  } else if(props.sourceName === 'fluxity-status-project-manager') {
    return (
      <div className='fx-comp-history-item-tag fx-comp-history-item-tag-type-fluxity-status-project-manager'>Project Manager</div>
    );
  } else if(props.sourceName === 'fluxity-status-security') {
    return (
      <div className='fx-comp-history-item-tag fx-comp-history-item-tag-type-fluxity-status-dev-ops'>Dev Ops</div>
    );
  } else if(props.sourceName === 'int-jira') {
    return (
      <div className='fx-comp-history-item-tag fx-comp-history-item-tag-type-int-jira'>Jira</div>
    );
  } else if(props.sourceName === 'int-pega-pulse') {
    return (
      <div className='fx-comp-history-item-tag fx-comp-history-item-tag-type-int-pega-pulse'>Pega Pulse</div>
    );
  } else if(props.sourceName === 'int-pega-rules') {
    return (
      <div className='fx-comp-history-item-tag fx-comp-history-item-tag-type-int-pega-rules'>Pega Rules</div>
    );
  } else {
    return (
      <div className='fx-comp-history-item-tag fx-comp-history-item-tag-type-fluxity'>Fluxity</div>
    );
  }
};

const CompActivityHistoryItem = (props) => {

  return (
    <>
    <div className='fx-activity-history-item-container'>
      <div className='fx-activity-history-item-tag-portion'>
        <div className='fx-activity-history-item-tag-label'>
          <SourceTag sourceName={props.activityHistorySource} />
        </div>
      </div>
      <div className='fx-activity-history-item-total-message-portion'>
        <div className='fx-activity-history-item-user-and-status'>
          <div>(Status)</div><CompMiniAvatar user={props.activityHistoryUser} /><div><em>{props.activityHistoryDateTime}</em></div>
        </div>
        <div className='fx-activity-history-item-description'>
          {props.activityHistoryMessage}
        </div>
      </div>
    </div>

    {/* Use the SourceTag component */}
    

    {/*
    <div
              key={props.activityHistoryId}
              className={`fx-activity-history-item`}
            >
              <div className='fx-comp-activity-history-item-avatar'>
                <CompMiniAvatar user={props.activityHistoryUser} />
              </div>
              <div className='fx-comp-activity-history-item-message-stack'>
                <div className='fx-comp-activity-history-item-message-stack-source'>
                {props.activityHistorySource ? (
                  <>
                  {props.activityHistorySource === 'fluxity' &&
                    <div>Fluxity (Other)</div>
                  }
                  {props.activityHistorySource === 'fluxity-status' &&
                    <div>Fluxity Status</div>
                  }
                  </>
                ) : (
                  <>
                    Fluxity
                  </>
                )}
                </div>
                <div className='fx-comp-activity-history-item-message-stack-message'>{props.activityHistoryMessage}</div>
                <div className='fx-comp-activity-history-item-message-stack-date'>{props.activityHistoryDateTime}</div>
              </div>
            </div>
            */}
            </>
  );
};

export default CompActivityHistoryItem;