import React, { useEffect, useState } from 'react';
import './CompCRUDFormPath.css';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import CompModalButtons from './CompCRUDModalButtons';
import EditIcon from '@mui/icons-material/Edit';
import CompCRUDModalActionBar from './CompCRUDModalActionBar';
import { createPath, updatePath } from '../fxAPI/fxPathsAPI.js';

const CompCRUDFormPath = (props) => {
  const [currentEditableState, setCurrentEditableState] = useState('view');
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [parentPath, setParentPath] = useState('');
  const [primaryPersona, setPrimaryPersona] = useState('');
  const [favorite, setFavorite] = useState(false);
  const [pathResponse, setPathResponse] = useState(null);
  const [updatePathName, setUpdatePathName] = useState(props.currentComponentData?.name || null);
  const [updatePathDescription, setUpdatePathDescription] = useState(props.currentComponentData?.description || null);
  const [updateParentPath, setUpdateParentPath] = useState(props.currentComponentData?.parent_path || null);
  const [updatePrimaryPersona, setUpdatePrimaryPersona] = useState(props.currentComponentData?.primary_persona || null);
  const [updateFavorite, setUpdateFavorite] = useState(props.currentComponentData?.favorite === 'yes' ? true : false);

  console.log('current path data on form path: ');
  console.dir(props.currentComponentData);

  const triggerViewMode = (currentViewMode) => {
    setCurrentEditableState(currentViewMode);
  };

  const handleCloseModal = () => {
    props.onClose();
  };

  const generateUUID = () => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      const r = (Math.random() * 16) | 0, v = c === 'x' ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  };

  const handleCreatePath = async (event) => {
    event.preventDefault();
    const related_org = localStorage.getItem("fxLocalOrgID");
    const related_project = props.relatedProject;
    const pathId = generateUUID();

    await createPath(pathId, name, description, parentPath, primaryPersona, favorite ? 'yes' : 'no', related_org, related_project, setPathResponse);
    handleCloseModal(); // Close the modal after creating the path
  };

  const handleUpdatePathNameChange = (e) => {
    setUpdatePathName(e.target.value);
  };

  const handleUpdatePathDescriptionChange = (e) => {
    setUpdatePathDescription(e.target.value);
  };

  const handleUpdateParentPathChange = (e) => {
    setUpdateParentPath(e.target.value);
  };

  const handleUpdatePrimaryPersonaChange = (e) => {
    setUpdatePrimaryPersona(e.target.value);
  };

  const handleUpdateFavoriteChange = (e) => {
    setUpdateFavorite(e.target.checked);
  };

  const handleUpdatePath = async (e) => {
    console.log('ran update path: ' + props.currentComponentData.path_id);
    e.preventDefault();
    const relatedOrg = localStorage.getItem("fxLocalOrgID");
    const pathId = props.currentComponentData.path_id;
    const updateName = updatePathName;
    const updateDescription = updatePathDescription;
    const related_org = relatedOrg;
    const parent_path = updateParentPath;
    const primary_persona = updatePrimaryPersona;
    const favorite = updateFavorite ? 'yes' : 'no';
    const related_project = props.relatedProject;

    await updatePath(pathId, updateName, updateDescription, parent_path, primary_persona, favorite, related_org, related_project);
    handleCloseModal(); // Close the modal after updating the path
  };

  return (
    <div>
      {props.crudMethod === "create" &&
        <div>
          <br />
          <form onSubmit={handleCreatePath}>
            <div>
              <TextField id="outlined-basic" label="Path Name" variant="outlined" onChange={(e) => setName(e.target.value)} />
            </div>
            <br />
            <div className="fx-global-form-text-area-description">
              <TextField
                id="outlined-multiline-flexible"
                label="Path Description"
                multiline
                maxRows={4}
                onChange={(e) => setDescription(e.target.value)}
                fullWidth
              />
            </div>
            <br />
            <div>
              <label>Parent Path</label>
              <select onChange={(e) => setParentPath(e.target.value)}>
                {/* Options for parent path */}
              </select>
            </div>
            <br />
            <div>
              <label>Primary Persona</label>
              <select onChange={(e) => setPrimaryPersona(e.target.value)}>
                {/* Options for primary persona */}
              </select>
            </div>
            <br />
            <div>
              <FormControlLabel
                control={<Checkbox checked={favorite} onChange={(e) => setFavorite(e.target.checked)} />}
                label="Favorite"
              />
            </div>
            <CompModalButtons>
              <Button type="submit">Create Path</Button>
              <Button type="button" onClick={handleCloseModal}>Cancel</Button>
            </CompModalButtons>
          </form>
        </div>
      }
      {props.crudMethod === "view-edit" &&
        <div>
          <CompCRUDModalActionBar>
            {currentEditableState === "view" &&
              <Button onClick={() => triggerViewMode("edit")}><EditIcon /></Button>
            }
            {currentEditableState === "edit" &&
              <Button variant="outlined" onClick={() => triggerViewMode("view")}>Save</Button>
            }
          </CompCRUDModalActionBar>

          {currentEditableState === "view" &&
            <div>
              <div>
                <div className='fx-global-form-read-only-label'>Path Name</div>
                <div>{props.currentComponentData.name}</div>
              </div>
              <div>
                <div className='fx-global-form-read-only-label'>Path Description</div>
                <div>{props.currentComponentData.description}</div>
              </div>
              <div>
                <div className='fx-global-form-read-only-label'>Parent Path</div>
                <div>{props.currentComponentData.parent_path}</div>
              </div>
              <div>
                <div className='fx-global-form-read-only-label'>Primary Persona</div>
                <div>{props.currentComponentData.primary_persona}</div>
              </div>
              <div>
                <div className='fx-global-form-read-only-label'>Favorite</div>
                <div>{props.currentComponentData.favorite === 'yes' ? 'Yes' : 'No'}</div>
              </div>
            </div>
          }

          {currentEditableState === "edit" &&
            <div>
              <form onSubmit={handleUpdatePath}>
                <div>
                  <TextField id="outlined-basic" label="Path Name" variant="outlined" value={updatePathName} onChange={handleUpdatePathNameChange} />
                </div>
                <br />
                <div className="fx-global-form-text-area-description">
                  <TextField
                    id="outlined-multiline-flexible"
                    label="Path Description"
                    value={updatePathDescription}
                    onChange={handleUpdatePathDescriptionChange}
                    multiline
                    maxRows={4}
                    fullWidth
                  />
                </div>
                <br />
                <div>
                  <label>Parent Path</label>
                  <select value={updateParentPath} onChange={handleUpdateParentPathChange}>
                    {/* Options for parent path */}
                  </select>
                </div>
                <br />
                <div>
                  <label>Primary Persona</label>
                  <select value={updatePrimaryPersona} onChange={handleUpdatePrimaryPersonaChange}>
                    {/* Options for primary persona */}
                  </select>
                </div>
                <br />
                <div>
                  <FormControlLabel
                    control={<Checkbox checked={updateFavorite} onChange={handleUpdateFavoriteChange} />}
                    label="Favorite"
                  />
                </div>
                <CompModalButtons>
                  <Button type='submit'>Save Path</Button>
                  <Button type="button" onClick={handleCloseModal}>Cancel</Button>
                </CompModalButtons>
              </form>
            </div>
          }
        </div>
      }
    </div>
  );
};

export default CompCRUDFormPath;