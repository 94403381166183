import React, { useEffect, useState } from "react";
import './CompAccessibilityReportList.css';
import { getReportsByOrgId } from '../fxAPI/fxReportsAPI';
import Paper from '@mui/material/Paper';


const CompAccessibilityReportList = (props) => {

    const [error, setError] = useState(null);

    /* REPORTS BY ORG */
  const [reportsByOrgId, setReportsData] = useState([]); // Initialize as an array
  /* get reports by orgId */
  const fxAPIFetchReportsByOrgId = async (orgId) => {
    await getReportsByOrgId(orgId, setError, setReportsData);
    console.log('all reports by org Id');
    console.dir(reportsByOrgId);
  };

  useEffect(() => {
      const currentOrgId = localStorage.getItem("fxLocalOrgID");
      fxAPIFetchReportsByOrgId(currentOrgId);
    }, []);

  return (
    <div>
      {reportsByOrgId.map((report) => (
                <div className="fx-comp-accessibility-report-list-item">
                    <a href={`/accessibility-report-detail/${report.report_id}`}><div className="fx-comp-accessibility-report-list-item">{report.name}</div></a>
                </div>
      ))}
    </div>
  );
};

export default CompAccessibilityReportList;