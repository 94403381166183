import React, { useState } from "react";
import {
  TextField,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Button,
  Typography,
  Box,
  Snackbar,
  Alert,
  RadioGroup,
  FormControl,
  FormLabel,
  Radio,
} from "@mui/material";
import axios from "axios";
import "./CompWebSendGridForm.css";

const CompWebSendGridForm = () => {
  const [formData, setFormData] = useState({
    inquiryType: "earlyAdopter", // Default selection
    name: "",
    email: "",
    phone: "",
    company: "",
    comments: "",
    purposes: {
      earlyAdoption: false,
      generalInfo: false,
      investorInquiry: false,
    },
  });
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (type === "checkbox") {
      setFormData((prev) => ({
        ...prev,
        purposes: { ...prev.purposes, [name]: checked },
      }));
    } else {
      setFormData((prev) => ({ ...prev, [name]: value }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const selectedPurposes = Object.entries(formData.purposes)
      .filter(([_, value]) => value)
      .map(([key]) => key.replace(/([A-Z])/g, " $1").trim());

    const payload = {
      name: formData.name,
      email: formData.email,
      phone: formData.phone,
      company: formData.company,
      comments: formData.comments,
      ...(selectedPurposes.length > 0 && { purposes: selectedPurposes }), // Include purposes only if not empty
    };

    try {
      await axios.post('/api/send-email', payload);
      setSnackbarOpen(true); // Open the snackbar on success
    } catch (error) {
      console.error("Error submitting form:", error);
      alert("Failed to submit the form.");
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <>
      <Box component="form" onSubmit={handleSubmit} sx={{ width: "100%", mx: "auto", fontFamily: "Open Sans", fontSize: "20px" }}>
        <FormControl component="fieldset" margin="normal" sx={{ fontFamily: "Open Sans", fontSize: "20px" }}>
          <FormLabel component="legend" sx={{ color: "black", fontFamily: "Open Sans", fontSize: "20px" }}>
            Type of Inquiry
          </FormLabel>
          <RadioGroup
            name="inquiryType"
            value={formData.inquiryType}
            onChange={handleChange}
            row // Added row prop to make radio buttons horizontal
            sx={{ fontFamily: "Open Sans", fontSize: "20px" }}
          >
            <FormControlLabel
              value="earlyAdopter"
              control={<Radio />}
              label="Early Adopter"
              sx={{ fontFamily: "Open Sans", fontSize: "20px" }}
            />
            <FormControlLabel
              value="investor"
              control={<Radio />}
              label="Investor"
              sx={{ fontFamily: "Open Sans", fontSize: "20px" }}
            />
            <FormControlLabel
              value="generalInfo"
              control={<Radio />}
              label="General Information"
              sx={{ fontFamily: "Open Sans", fontSize: "20px" }}
            />
          </RadioGroup>
        </FormControl>
        
        <TextField
          fullWidth
          label="Name"
          name="name"
          value={formData.name}
          onChange={handleChange}
          margin="normal"
          required
          sx={{ backgroundColor: "white", fontFamily: "Open Sans", fontSize: "20px" }}
          InputLabelProps={{ style: { color: "black", fontFamily: "Open Sans", fontSize: "20px" } }}
        />
        <TextField
          fullWidth
          label="Email"
          name="email"
          type="email"
          value={formData.email}
          onChange={handleChange}
          margin="normal"
          required
          sx={{ backgroundColor: "white", fontFamily: "Open Sans", fontSize: "20px" }}
          InputLabelProps={{ style: { color: "black", fontFamily: "Open Sans", fontSize: "20px" } }}
        />
        <TextField
          fullWidth
          label="Phone"
          name="phone"
          value={formData.phone}
          onChange={handleChange}
          margin="normal"
          sx={{ backgroundColor: "white", fontFamily: "Open Sans", fontSize: "20px" }}
          InputLabelProps={{ style: { color: "black", fontFamily: "Open Sans", fontSize: "20px" } }}
        />
        <TextField
          fullWidth
          label="Company"
          name="company"
          value={formData.company}
          onChange={handleChange}
          margin="normal"
          sx={{ backgroundColor: "white", fontFamily: "Open Sans", fontSize: "20px" }}
          InputLabelProps={{ style: { color: "black", fontFamily: "Open Sans", fontSize: "20px" } }}
        />

        {/* Investor-specific fields */}
        {formData.inquiryType === "investor" && (
          <Box sx={{ mt: 2 }}>
            {/* Form Group 1 */}
            <Typography variant="h6" sx={{ mt: 2, fontFamily: "Open Sans", fontSize: "20px" }}>
              Are you an accredited investor?
            </Typography>
            <Typography variant="body2" sx={{ mb: 2, fontFamily: "Open Sans", fontSize: "18px" }}>
              <span className="fx-page-contact-subtext">This form ensures investors are qualified under SEC guidelines for private investments.</span>
            </Typography>
            <FormGroup>
              <FormControlLabel
                control={<Checkbox name="netWorth" onChange={handleChange} />}
                label="Net worth exceeding $1M (excluding primary residence)"
                sx={{ fontFamily: "Open Sans", fontSize: "16px" }}
              />
              <FormControlLabel
                control={<Checkbox name="annualIncome" onChange={handleChange} />}
                label="Annual income exceeding $200K (single) / $300K (married) for the last 2 years"
                sx={{ fontFamily: "Open Sans", fontSize: "16px" }}
              />
              <FormControlLabel
                control={<Checkbox name="institutionalInvestor" onChange={handleChange} />}
                label="Institutional investor (VC firm, PE firm, etc.)"
                sx={{ fontFamily: "Open Sans", fontSize: "16px" }}
              />
              <FormControlLabel
                control={<Checkbox name="familyOffice" onChange={handleChange} />}
                label="Family Office"
                sx={{ fontFamily: "Open Sans", fontSize: "16px" }}
              />
              <FormControlLabel
                control={<Checkbox name="otherInvestor" onChange={handleChange} />}
                label="Other (Please specify)"
                sx={{ fontFamily: "Open Sans", fontSize: "16px" }}
              />
              {formData.otherInvestor && (
                <TextField
                  fullWidth
                  label="Please specify"
                  name="otherInvestorDetails"
                  value={formData.otherInvestorDetails || ""}
                  onChange={handleChange}
                  margin="normal"
                  multiline
                  rows={2}
                  sx={{ backgroundColor: "white", fontFamily: "Open Sans", fontSize: "16px" }}
                />
              )}
            </FormGroup>

            {/* Form Group 2 */}
            <Typography variant="h6" sx={{ mt: 2, fontFamily: "Open Sans", fontSize: "20px" }}>
              Investment Type & Amount
            </Typography>
            <Typography variant="body2" sx={{ mb: 2, fontFamily: "Open Sans", fontSize: "18px" }}>
              <span className="fx-page-contact-subtext">How much are you looking to invest?</span>
            </Typography>
            <FormGroup>
              <FormControlLabel
                control={<Checkbox name="investmentRange1" onChange={handleChange} />}
                label="$25K - $100K"
                sx={{ fontFamily: "Open Sans", fontSize: "16px" }}
              />
              <FormControlLabel
                control={<Checkbox name="investmentRange2" onChange={handleChange} />}
                label="$100K - $500K"
                sx={{ fontFamily: "Open Sans", fontSize: "16px" }}
              />
              <FormControlLabel
                control={<Checkbox name="investmentRange3" onChange={handleChange} />}
                label="$500K - $1M"
                sx={{ fontFamily: "Open Sans", fontSize: "16px" }}
              />
              <FormControlLabel
                control={<Checkbox name="investmentRange4" onChange={handleChange} />}
                label="$1M+"
                sx={{ fontFamily: "Open Sans", fontSize: "16px" }}
              />
              <FormControlLabel
                control={<Checkbox name="equityOrNote" onChange={handleChange} />}
                label="Are you looking for an equity stake or convertible note?"
                sx={{ fontFamily: "Open Sans", fontSize: "16px" }}
              />
            </FormGroup>

            {/* Form Group 3 */}
            <Typography variant="h6" sx={{ mt: 2, fontFamily: "Open Sans", fontSize: "20px" }}>
              Investor Background
            </Typography>
            <Typography variant="body2" sx={{ mb: 2, fontFamily: "Open Sans", fontSize: "18px" }}>
              <span className="fx-page-contact-subtext">How much are you looking to invest?</span>
            </Typography>
            <FormGroup>
              <FormControlLabel
                control={<Checkbox name="individualOrEntity" onChange={handleChange} />}
                label="Are you an individual investor or investing through an entity?"
                sx={{ fontFamily: "Open Sans", fontSize: "16px" }}
              />
              <FormControlLabel
                control={<Checkbox name="investedBefore" onChange={handleChange} />}
                label="Have you invested in startups before (y/n)?"
                sx={{ fontFamily: "Open Sans", fontSize: "16px" }}
              />
              {formData.investedBefore && (
                <TextField
                  fullWidth
                  label="If yes, which industries?"
                  name="industriesInvested"
                  value={formData.industriesInvested || ""}
                  onChange={handleChange}
                  margin="normal"
                  multiline
                  rows={2}
                  sx={{ backgroundColor: "white", fontFamily: "Open Sans", fontSize: "16px" }}
                />
              )}
            </FormGroup>

            {/* Form Group 4 */}
            <Typography variant="h6" sx={{ mt: 2, fontFamily: "Open Sans", fontSize: "20px" }}>
              Risk Tolerance & Expectations
            </Typography>
            <Typography variant="body2" sx={{ mb: 2, fontFamily: "Open Sans", fontSize: "18px" }}>
              <span className="fx-page-contact-subtext">Are you comfortable with a long-term investment horizon (5+ years)?</span>
            </Typography>
            <FormGroup>
              <FormControlLabel
                control={<Checkbox name="longTermHorizon" onChange={handleChange} />}
                label="Are you comfortable with a long-term investment horizon (5+ years) (y/n)?"
                sx={{ fontFamily: "Open Sans", fontSize: "16px" }}
              />
              <TextField
                fullWidth
                label="What is your expected ROI timeline?"
                name="roiTimeline"
                value={formData.roiTimeline || ""}
                onChange={handleChange}
                margin="normal"
                multiline
                rows={2}
                sx={{ backgroundColor: "white", fontFamily: "Open Sans", fontSize: "16px" }}
              />
            </FormGroup>

            {/* Form Group 5 */}
            <Typography variant="h6" sx={{ mt: 2, fontFamily: "Open Sans", fontSize: "20px" }}>
              Regulatory Compliance
            </Typography>
            <FormGroup>
              <FormControl>
                <FormLabel sx={{ fontFamily: "Open Sans", fontSize: "16px" }}>
                  Are you a US citizen or foreign investor?
                </FormLabel>
                <RadioGroup name="citizenshipStatus" onChange={handleChange} row> {/* Added row prop */}
                  <FormControlLabel
                    value="usCitizen"
                    control={<Radio />}
                    label="US Citizen"
                    sx={{ fontFamily: "Open Sans", fontSize: "16px" }}
                  />
                  <FormControlLabel
                    value="foreignInvestor"
                    control={<Radio />}
                    label="Foreign Investor"
                    sx={{ fontFamily: "Open Sans", fontSize: "16px" }}
                  />
                </RadioGroup>
              </FormControl>
              <FormControl>
                <FormLabel sx={{ fontFamily: "Open Sans", fontSize: "16px" }}>
                  Do you understand the risks of private placements, including potential loss of capital?
                </FormLabel>
                <RadioGroup name="understandRisks" onChange={handleChange} row> {/* Added row prop */}
                  <FormControlLabel
                    value="yes"
                    control={<Radio />}
                    label="Yes"
                    sx={{ fontFamily: "Open Sans", fontSize: "16px" }}
                  />
                  <FormControlLabel
                    value="no"
                    control={<Radio />}
                    label="No"
                    sx={{ fontFamily: "Open Sans", fontSize: "16px" }}
                  />
                </RadioGroup>
              </FormControl>
              <FormControl>
                <FormLabel sx={{ fontFamily: "Open Sans", fontSize: "16px" }}>
                  Do you agree to provide proof of accreditation upon request?
                </FormLabel>
                <RadioGroup name="proofOfAccreditation" onChange={handleChange} row> {/* Added row prop */}
                  <FormControlLabel
                    value="yes"
                    control={<Radio />}
                    label="Yes"
                    sx={{ fontFamily: "Open Sans", fontSize: "16px" }}
                  />
                  <FormControlLabel
                    value="no"
                    control={<Radio />}
                    label="No"
                    sx={{ fontFamily: "Open Sans", fontSize: "16px" }}
                  />
                </RadioGroup>
              </FormControl>
            </FormGroup>
          </Box>
        )}

        <TextField
          fullWidth
          label="Comments"
          name="comments"
          value={formData.comments}
          onChange={handleChange}
          margin="normal"
          multiline
          rows={4}
          sx={{ backgroundColor: "white", fontFamily: "Open Sans", fontSize: "20px" }}
          InputLabelProps={{ style: { color: "black", fontFamily: "Open Sans", fontSize: "20px" } }}
        />
        <div className="fx-comp-sendgrid-form">
          <Button
            type="submit"
            onClick={handleSubmit}
            sx={{ mt: 2, fontFamily: "Open Sans"}}
          >
            Submit
          </Button>
        </div>
      </Box>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert onClose={handleSnackbarClose} severity="success" sx={{ width: "100%" }}>
          Form submitted successfully!
        </Alert>
      </Snackbar>
    </>
  );
};

export default CompWebSendGridForm;