import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth0 } from "@auth0/auth0-react";
import './PageAuthCallback.css';
import Box from '@mui/material/Box';
import CompPageTempMaster3Panel from '../Components/CompPageTempMaster3Panel';

const PageAuthCallback = () => {
  const { handleRedirectCallback, isLoading, error, loginWithRedirect } = useAuth0();
  const navigate = useNavigate();

  useEffect(() => {
    const completeAuth = async () => {
      try {
        console.log('Starting authentication callback process');
        const { appState } = await handleRedirectCallback();
        console.log('handleRedirectCallback completed successfully');

        // Clear the state parameter from session storage after validation
        sessionStorage.removeItem('auth_state');
        console.log('State parameter cleared from session storage');

        if (appState && appState.returnTo) {
          navigate(appState.returnTo);
          console.log(`Navigation to ${appState.returnTo} completed`);
        } else {
          navigate('/');
          console.log('Navigation to / completed');
        }
      } catch (err) {
        console.error('Error handling redirect callback', err);
        console.error('Error details:', err.message);
        sessionStorage.removeItem('auth_state'); // Clear the state parameter from session storage
        loginWithRedirect(); // Retry the login process
      }
    };

    completeAuth();
  }, [handleRedirectCallback, navigate, loginWithRedirect]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return <div>Redirecting...</div>;
};

export default PageAuthCallback;