import React, { useState, useEffect, useContext } from 'react';
import './CompDashCardGallery.css';
import Button from '@mui/material/Button';
import { getProjectsByOrgId } from '../fxAPI/fxProjectsAPI.js';
import { getUserPersonasByOrgId } from '../fxAPI/fxUserPersonasAPI.js';
import { getIntegrationsByOrgId } from '../fxAPI/fxIntegrationsAPI.js';
import { getBusinessValueByOrgId } from '../fxAPI/fxBusinessValueAPI.js';
import { getDesignSessionsByOrgId } from '../fxAPI/fxDesignSessionsAPI.js';
import { getPathsByProjectId } from '../fxAPI/fxPathsAPI.js';
import CompDashCard from './CompDashCard.jsx';
import { PanelContext, refreshContext } from '../Contexts/PanelContext';
import CompCRUDModal from './CompCRUDModal';
import CompAvatarChooserModal from './CompAvatarChooserModal.jsx';

const CompDashCardGallery = (props) => {
  const [error, setError] = useState(null);
  const [projectsByOrgId, setProjectsByOrgData] = useState([]);
  const [showMoreProjects, setShowMoreProjects] = useState(false);
  const [showMorePersonas, setShowMorePersonas] = useState(false);
  const [showMoreIntegrations, setShowMoreIntegrations] = useState(false);
  const [showMoreBusinessValue, setShowMoreBusinessValue] = useState(false);
  const [showMoreDesignSessions, setShowMoreDesignSessions] = useState(false);
  const [showMorePaths, setShowMorePaths] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [itemType, setItemType] = useState(null);
  const [actionModalTitle, setActionModalTitle] = useState(null);
  const [crudMethod, setCRUDMethod] = useState(null);
  const [modalComponentData, setModalComponentData] = useState([]);

  const openModal = (itemType, currentCRUDMethod, currentModalComponentData) => {
    setItemType(itemType);
    setCRUDMethod(currentCRUDMethod);
    setModalComponentData(currentModalComponentData);
    if(itemType === "project") {
      setActionModalTitle('Create Playbook');
    } else if(itemType === "user-persona") {
      setActionModalTitle('Create Playbook');
    }
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  // Fetch projects by org ID
  const fxAPIFetchProjectsByOrgId = async (orgId) => {
    await getProjectsByOrgId(orgId, setError, setProjectsByOrgData);
  };

  /* USER PERSONAS */
  const [userPersonasByOrgId, setUserPersonasByOrgData] = useState([]); // Initialize as an array
  /* get user personas by orgId */
  const fxAPIFetchUserPersonasByOrgId = async (orgId) => {
    await getUserPersonasByOrgId(orgId, setError, setUserPersonasByOrgData);
    console.log('all user personas by org Id');
    console.dir(userPersonasByOrgId);
  }; 

  /* INTEGRATIONS */
  const [integrationsByOrgId, setIntegrationsByOrgData] = useState([]); // Initialize as an array
  /* get integrations by orgId */
  const fxAPIFetchIntegrationsByOrgId = async (orgId) => {
    await getIntegrationsByOrgId(orgId, setError, setIntegrationsByOrgData);
    console.log('all integrations by org Id');
    console.dir(integrationsByOrgId);
  };

  /* BUSINESS VALUE */
  const [businessValueByOrgId, setBusinessValueByOrgData] = useState([]); // Initialize as an array
  /* get business value by orgId */
  const fxAPIFetchBusinessValueByOrgId = async (orgId) => {
    await getBusinessValueByOrgId(orgId, setError, setBusinessValueByOrgData);
    console.log('all business value by org Id');
    console.dir(businessValueByOrgId);
  };

  /* DESIGN SESSIONS */
  const [designsessionsByOrgId, setDesignSessionsByOrgData] = useState([]); // Initialize as an array
  /* get design sessions by orgId */
  const fxAPIFetchDesignSessionsByOrgId = async (orgId) => {
    await getDesignSessionsByOrgId(orgId, setError, setDesignSessionsByOrgData);
    console.log('all design sessions by org Id');
    console.dir(designsessionsByOrgId);
  };

  /* PATHS BY PROJECT */
  const [pathsByProjectId, setPathsByProjectData] = useState([]); // Initialize as an array
  /* get design sessions by orgId */
  const fxAPIFetchsetPathsByProjectId = async (projectId) => {
    await getPathsByProjectId(projectId, setError, setPathsByProjectData);
    console.log('all paths by project Id');
    console.dir(pathsByProjectId);
  };

  const { elements, resetElementRefresh, triggerElementRefresh } = useContext(PanelContext);

  const fetchAllData = async (orgId) => {
    await fxAPIFetchProjectsByOrgId(orgId);
    await fxAPIFetchUserPersonasByOrgId(orgId);
    await fxAPIFetchIntegrationsByOrgId(orgId);
    await fxAPIFetchBusinessValueByOrgId(orgId);
    await fxAPIFetchDesignSessionsByOrgId(orgId);
    await fxAPIFetchsetPathsByProjectId(props.relatedProject);
  };

  useEffect(() => {
    const currentOrgId = localStorage.getItem("fxLocalOrgID");
    fetchAllData(currentOrgId);
  }, []);

  useEffect(() => {
    if (elements['dashboard']?.refresh) {
      const currentOrgId = localStorage.getItem("fxLocalOrgID");
      fetchAllData(currentOrgId);
      resetElementRefresh('dashboard');
    }
  }, [elements['dashboard']?.refresh, resetElementRefresh]);

  // Handle "Show More" button click
  const handleShowMoreProjects = () => {
    setShowMoreProjects(!showMoreProjects);
  };

  const handleShowMorePersonas = () => {
    setShowMorePersonas(!showMorePersonas);
  };

  const handleShowMoreIntegrations = () => {
    setShowMoreIntegrations(!showMoreIntegrations);
  };

  const handleShowMoreBusinessValue = () => {
    setShowMoreBusinessValue(!showMoreBusinessValue);
  };

  const handleShowMoreDesignSessions = () => {
    setShowMoreDesignSessions(!showMoreDesignSessions);
  };

  const handleShowMorePaths = () => {
    setShowMorePaths(!showMorePaths);
  };

  // Define how many items to show initially (4 including the static one)
  const itemsToShowProjects = showMoreProjects ? projectsByOrgId.length : 3;
  const itemsToShowPersonas = showMorePersonas ? userPersonasByOrgId.length : 3;
  const itemsToShowIntegrations = showMoreIntegrations ? integrationsByOrgId.length : 3;
  const itemsToShowBusinessValue = showMoreBusinessValue ? businessValueByOrgId.length : 3;
  const itemsToShowDesignSessions = showMoreDesignSessions ? designsessionsByOrgId.length : 3;
  const itemsToShowPathsByProjects = showMorePaths ? pathsByProjectId.length : 3;

  /* Use Global Context to refresh Subjective Panel */
  const handleRefreshElement = () => {
    triggerElementRefresh('sideContextPanel'); // Trigger refresh for the subjectivePanel
  };

  const handleSwitchPanelContext = (object_id, type, object) => {
    localStorage.setItem("fxSidePanelContext", type);
    localStorage.setItem("fxSidePanelObjectId", object_id);
    localStorage.setItem("fxSidePanelObject", JSON.stringify(object));
    handleRefreshElement();
  };

  const handleCloseModal = () => {
    refreshContext(resetElementRefresh, triggerElementRefresh, 'dashboard');
    setIsOpen(false);
  };

  return (
    <>
    <CompCRUDModal isOpen={isOpen} onClose={handleCloseModal} modalTitle={actionModalTitle} itemType={itemType} crudMethod={crudMethod} modalComponentData={modalComponentData}></CompCRUDModal>

    <div className="fx-comp-dash-pb-gallery-container">
      {/* Static "Create" item */}
      <div className="fx-comp-dash-pb-gallery-item fx-create-item">
        {props.galleryType === "playbook" &&
          <CompDashCard cardTitle="Create New Playbook">
            <div className="fx-create-item-plus" onClick={() => openModal("project", "create", [])}>+</div>
          </CompDashCard>
        }

        {props.galleryType === "persona" &&
          <CompDashCard cardTitle="Create New User Persona">
            <div className="fx-create-item-plus" onClick={() => openModal("user-persona", "create", [])}>+</div>
          </CompDashCard>
        }

        {props.galleryType === "integration" &&
          <CompDashCard cardTitle="Create New Integration">
            <div className="fx-create-item-plus" onClick={() => openModal("integration", "create", [])}>+</div>
          </CompDashCard>
        }

        {props.galleryType === "business-value" &&
          <CompDashCard cardTitle="Create New Business Value">
            <div className="fx-create-item-plus" onClick={() => openModal("business-value", "create", [])}>+</div>
          </CompDashCard>
        }

        {props.galleryType === "design-session" &&
          <CompDashCard cardTitle="Create New Design Session">
            <div className="fx-create-item-plus" onClick={() => openModal("design-session", "create", [])}>+</div>
          </CompDashCard>
        }


        {props.galleryType === "journey-map" &&
          <CompDashCard cardTitle="Create New Journey Map">
            <div className="fx-create-item-plus" onClick={() => window.location.href = "/journey-wizard-images"}>+</div>
          </CompDashCard>
        }
      </div>

      {/* Dynamically loaded items */}
      {props.galleryType === "playbook" &&
        <>
        {projectsByOrgId.slice(0, itemsToShowProjects).map((projectItem) => (
          <CompDashCard cardTitle={projectItem.name} key={projectItem.id}>
            <Button onClick={() => handleSwitchPanelContext(projectItem.project_id, "project", projectItem)}>
                  Playbook Detail
            </Button>
            <a href={`/playbook/objective-map/${projectItem.project_id}`}>
              <Button>
                    Open
              </Button>
            </a>
            <Button onClick={() => openModal("project", "view-edit", projectItem)}>Edit</Button>
            <Button onClick={() => openModal("project", "delete", projectItem)}>Delete</Button>
          </CompDashCard>
        ))}
        </>
      }

      {/* Dynamically loaded items */}
      {props.galleryType === "persona" &&
        <>
        {userPersonasByOrgId.slice(0, itemsToShowPersonas).map((personaItem) => (
          <CompDashCard cardTitle={personaItem.name} key={personaItem.user_persona_id}>
            <Button onClick={() => handleSwitchPanelContext(personaItem.user_persona_id, "persona", personaItem)}>
                  Open Persona Detail
            </Button>
            <Button onClick={() => openModal("user-persona", "view-edit", personaItem)}>Edit</Button>
            <Button onClick={() => openModal("user-persona", "delete", personaItem)}>Delete</Button>
            <CompAvatarChooserModal userPersonaId={personaItem.user_persona_id} avatarId={personaItem.related_avatar} />
          </CompDashCard>
        ))}
        </>
      }

      {/* Dynamically loaded items */}
      {props.galleryType === "integration" &&
        <>
        {integrationsByOrgId.slice(0, itemsToShowIntegrations).map((integrationItem) => (
          <CompDashCard cardTitle={integrationItem.product} key={integrationItem.integration_id}>
            <Button onClick={() => handleSwitchPanelContext(integrationItem.integration_id, "integration", integrationItem)}>
                  Open Integration
            </Button>
            <Button onClick={() => openModal("integration", "view-edit", integrationItem)}>Edit</Button>
            <Button onClick={() => openModal("integration", "delete", integrationItem)}>Delete</Button>
          </CompDashCard>
        ))}
        </>
      }

      {/* Dynamically loaded items */}
      {props.galleryType === "business-value" &&
        <>
        {businessValueByOrgId.slice(0, itemsToShowBusinessValue).map((businessValueItem) => (
          <CompDashCard cardTitle={businessValueItem.name} key={businessValueItem.business_value_id}>
            <a href={`/business-value-detail/${businessValueItem.business_value_id}`}><Button>Open</Button></a>
            <Button onClick={() => openModal("business-value", "view-edit", businessValueItem)}>Edit</Button>
            <Button onClick={() => openModal("business-value", "delete", businessValueItem)}>Delete</Button>
          </CompDashCard>
        ))}
        </>
      }

      {/* Dynamically loaded items */}
      {props.galleryType === "design-session" &&
        <>
        {designsessionsByOrgId.slice(0, itemsToShowDesignSessions).map((designSessionItem) => (
          <CompDashCard cardTitle={designSessionItem.name} key={designSessionItem.design_session_id}>
            <Button href={`/design-session-detail/${designSessionItem.design_session_id}`}>
                  Open Design Session
            </Button>
            <Button onClick={() => openModal("design-session", "view-edit", designSessionItem)}>Edit</Button>
            <Button onClick={() => openModal("design-session", "delete", designSessionItem)}>Delete</Button>
          </CompDashCard>
        ))}
        </>
      }

      {/* Dynamically loaded items */}
      {props.galleryType === "journey-map" &&
        <>
        {pathsByProjectId.slice(0, itemsToShowPathsByProjects).map((pathItem) => (
          <CompDashCard cardTitle={pathItem.name} key={pathItem.path_id}>
            <Button href={`/playbook/journey-map-detail/${props.relatedProject}/${pathItem.path_id}`}>
                  Open Journey Map
            </Button>
            <Button onClick={() => openModal("path", "view-edit", pathItem)}>Edit</Button>
            <Button onClick={() => openModal("path", "delete", pathItem)}>Delete</Button>
          </CompDashCard>
        ))}
        </>
      }

    </div>

    {/* "Show More" button if there are more than 3 dynamic items */}
    {props.galleryType === "playbook" &&
      <>
      {projectsByOrgId.length > 3 && (
        <Button className="fx-comp-dash-pb-gallery-show-button" variant="outlined" onClick={handleShowMoreProjects}>{showMoreProjects ? "Show Less" : "Show More"}</Button>
      )}
      </>
    }

    {/* "Show More" button if there are more than 3 dynamic items */}
    {props.galleryType === "persona" &&
      <>
      {userPersonasByOrgId.length > 3 && (
        <Button className="fx-comp-dash-pb-gallery-show-button" variant="outlined" onClick={handleShowMorePersonas}>{showMorePersonas ? "Show Less" : "Show More"}</Button>
      )}
      </>
    }

    {/* "Show More" button if there are more than 3 dynamic items */}
    {props.galleryType === "integration" &&
      <>
      {integrationsByOrgId.length > 3 && (
        <Button className="fx-comp-dash-pb-gallery-show-button" variant="outlined" onClick={handleShowMoreIntegrations}>{showMoreIntegrations ? "Show Less" : "Show More"}</Button>
      )}
      </>
    }

    {/* "Show More" button if there are more than 3 dynamic items */}
    {props.galleryType === "business-value" &&
      <>
      {businessValueByOrgId.length > 3 && (
        <Button className="fx-comp-dash-pb-gallery-show-button" variant="outlined" onClick={handleShowMoreBusinessValue}>{showMoreBusinessValue ? "Show Less" : "Show More"}</Button>
      )}
      </>
    }

    {/* "Show More" button if there are more than 3 dynamic items */}
    {props.galleryType === "design-session" &&
      <>
      {designsessionsByOrgId.length > 3 && (
        <Button className="fx-comp-dash-pb-gallery-show-button" variant="outlined" onClick={handleShowMoreDesignSessions}>{showMoreDesignSessions ? "Show Less" : "Show More"}</Button>
      )}
      </>
    }

    {/* "Show More" button if there are more than 3 dynamic items */}
    {props.galleryType === "journey-map" &&
      <>
      {pathsByProjectId.length > 3 && (
        <Button className="fx-comp-dash-pb-gallery-show-button" variant="outlined" onClick={handleShowMorePaths}>{showMorePaths ? "Show Less" : "Show More"}</Button>
      )}
      </>
    }

    </>
  );
};

export default CompDashCardGallery;