import axios from 'axios';

const createFeedback = async (feedbackData, setFeedbackResponse) => {
  const {
    feedback_id,
    name,
    status,
    authoring_user,
    related_object_type,
    related_object,
    related_anchor,
    related_project,
    related_org,
    related_design_session
  } = feedbackData;

  try {
    const response = await axios.post('/fxapi/feedback/create', {
      feedback_id,
      name,
      status,
      authoring_user,
      related_object_type,
      related_object,
      related_anchor,
      related_project,
      related_org,
      related_design_session
    });
    setFeedbackResponse(response.data);
  } catch (error) {
    console.error('Error creating feedback:', error);
  }
};

const updateFeedback = async (feedbackId, feedbackData, setFeedbackResponse) => {
  const {
    feedback_id,
    name,
    description,
    status,
    authoring_user,
    related_object_type,
    related_object,
    related_anchor,
    related_project,
    related_org,
    related_design_session
  } = feedbackData;

  try {
    const response = await axios.put(`/fxapi/feedback/update/${feedbackId}`, {
      feedback_id,
      name,
      description,
      status,
      authoring_user,
      related_object_type,
      related_object,
      related_anchor,
      related_project,
      related_org,
      related_design_session
    });
    setFeedbackResponse(response.data);
  } catch (error) {
    console.error('Error updating feedback:', error);
  }
};

const getFeedbackByOrg = async (orgId, setError, setFeedbackData) => {
  setError(null);
  setFeedbackData([]);

  if (!orgId) {
    setError('Org Id parameter is required');
    return;
  }

  try {
    const response = await axios.get('/fxapi/feedback/feedback-by-org', {
      params: { orgId }
    });
    setFeedbackData(response.data);
  } catch (error) {
    console.error('Error fetching feedback by org:', error);
    setError(error.response ? error.response.data : 'Failed to fetch feedback data');
  }
};

const getFeedbackByAnchor = async (anchorId, setError) => {
  setError(null);

  if (!anchorId) {
    setError('anchorId parameter is required');
    return [];
  }

  try {
    console.log(`Fetching feedback by anchor: ${anchorId}`);
    const response = await axios.get('/fxapi/feedback/feedback-by-anchor', {
      params: { anchorId }
    });
    console.log('API response received:', response.data.items);

    const items = Array.isArray(response.data.items) ? response.data.items : [];
    console.log('Setting feedback by anchor data:', items);
    return items; // Return the fetched data
  } catch (error) {
    console.error('Error fetching feedback by anchor data:', error);
    setError(error.response ? error.response.data : 'Failed to fetch feedback by anchor data');
    return []; // Return an empty array in case of error
  }
};

const deleteFeedback = async (feedbackId, setError, setDeleteResponse) => {
  setError(null);
  setDeleteResponse(null);

  if (!feedbackId) {
    setError('Feedback ID is required');
    return;
  }

  try {
    const response = await axios.delete(`/fxapi/feedback/delete/${feedbackId}`);
    setDeleteResponse(`Successfully deleted feedback with ID: ${feedbackId}`);
  } catch (error) {
    console.error('Error deleting feedback:', error);
    setError(error.response ? error.response.data : 'Failed to delete feedback');
  }
};

export { createFeedback, updateFeedback, getFeedbackByOrg, getFeedbackByAnchor, deleteFeedback };
