import ReactGA from "react-ga4";

// Replace with your actual Measurement ID from Google Analytics
const GA_MEASUREMENT_ID = "G-EQ07L94NZD";

export const initGA = () => {
  ReactGA.initialize(GA_MEASUREMENT_ID);
};

export const logPageView = (path) => {
  ReactGA.send({ hitType: "pageview", page: path });
};