import React, { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";

const PageLoginAlt = () => {
  const { isLoading, isAuthenticated, error, user, loginWithRedirect, logout } = useAuth0();

  // 🚀 Automatically redirect to Auth0 when visiting /login
  useEffect(() => {
    if (!isAuthenticated && !isLoading) {
      loginWithRedirect();
    }
  }, [isAuthenticated, isLoading, loginWithRedirect]);

  if (isLoading) {
    return <div>Loading...</div>; // Better UX can be added here
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  if (isAuthenticated) {
    return (
      <div>
        Hello, {user.name}!
        <button onClick={() => logout({ logoutParams: { returnTo: window.location.origin } })}>
          Log out
        </button>
      </div>
    );
  }

  return null; // No need for a manual login button since we auto-redirect
};

export default PageLoginAlt;
