import React, { useEffect, useState } from 'react';
import './PageActivity.css';
import { getActivityHistoryByOrgId } from '../fxAPI/fxActivityHistoryAPI';
import CompPageTempMaster3Panel from '../Components/CompPageTempMaster3Panel';
import Button from '@mui/material/Button';
import CompActivityHistoryItem from '../Components/CompActivityHistoryItem';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

const PageActivity = (props) => {
  const [error, setError] = useState(null);
  const [activityHistoryByOrgId, setActivityHistoryByOrgData] = useState([]);
  const [selectedSources, setSelectedSources] = useState([]); // Sources toggle state
  const [selectedPegaSources, setSelectedPegaSources] = useState([]); // Activity types toggle state
  const [selectedOtherExternalSources, setSelectedOtherExternalSources] = useState([]); // Activity types toggle state
  const [selectedUserSources, setSelectedUserSources] = useState([]); // Activity types toggle state
  const [combinedFilters, setCombinedFilters] = useState([]); // Combined filters for API call

  const fxAPIFetchActivityHistoryByOrgId = async (orgId, filters) => {
    await getActivityHistoryByOrgId(orgId, filters, setError, setActivityHistoryByOrgData);
    console.log('Fetching activity history with filters:', filters);
  };

  // Combine sources and activity types into one filter array
  useEffect(() => {
    const combined = [...selectedSources, ...selectedPegaSources, ...selectedOtherExternalSources, ...selectedUserSources];
    setCombinedFilters(combined);
  }, [selectedSources, selectedPegaSources, selectedOtherExternalSources, selectedUserSources]);

  // Fetch activity history when filters change
  useEffect(() => {
    const currentOrgId = localStorage.getItem("fxLocalOrgID");
    if (currentOrgId) {
      fxAPIFetchActivityHistoryByOrgId(currentOrgId, combinedFilters);
    }
  }, [combinedFilters]);

  const handleSourceChange = (event, newSources) => {
    console.log("Updated sources:", newSources);
    setSelectedSources(newSources);
  };

  const handlePegaSourcesChange = (event, newPegaSources) => {
    console.log("Updated pega sources:", newPegaSources);
    setSelectedPegaSources(newPegaSources);
  };

  const handleOtherExternalSourcesChange = (event, newOtherExternalSources) => {
    console.log("Updated external sources:", newOtherExternalSources);
    setSelectedOtherExternalSources(newOtherExternalSources);
  };

  const handleUserSourcesChange = (event, newUserSources) => {
    console.log("Updated external sources:", newUserSources);
    setSelectedUserSources(newUserSources);
  };

  return (
    <div>
      <CompPageTempMaster3Panel headingOne="Activity">
        <div className="fx-page-activity-main-container">
        <div className='fx-page-activity-toolbar-container'>

            <div className='fx-page-activity-toolbar-toggleset'>
            {/* Source Selection */}
            <div><strong>Fluxity</strong></div>
            <ToggleButtonGroup
            value={selectedSources}
            onChange={handleSourceChange}
            aria-label="source selection"
            >
            <ToggleButton value="fluxity-status" aria-label="External">
                Status
            </ToggleButton>
            <ToggleButton value="fluxity-reporting" aria-label="Fluxity">
                Reporting
            </ToggleButton>
            <ToggleButton value="fluxity" aria-label="Fluxity">
                Other
            </ToggleButton>
            </ToggleButtonGroup>
            </div>

            <div className='fx-page-activity-toolbar-toggleset'>
            {/* Pega Sources Selection */}
            <div><strong>Pega</strong></div>
            <ToggleButtonGroup
            value={selectedPegaSources}
            onChange={handlePegaSourcesChange}
            aria-label="pega source selection"
            >
            <ToggleButton value="pega-rules" aria-label="Rules">
                Rules
            </ToggleButton>
            <ToggleButton value="pega-pulse" aria-label="Pulse">
                Pulse
            </ToggleButton>
            <ToggleButton value="pega-other" aria-label="Other">
                Other
            </ToggleButton>
            </ToggleButtonGroup>
            </div>

            <div className='fx-page-activity-toolbar-toggleset'>
            {/* User Feedback Selection */}
            <div><strong>Users</strong></div>
            <ToggleButtonGroup
            value={selectedUserSources}
            onChange={handleUserSourcesChange}
            aria-label="user source selection"
            >
            <ToggleButton value="fluxity-user-feedback" aria-label="User Feedback">
                User Feedback
            </ToggleButton>
            </ToggleButtonGroup>
            </div>

            <div className='fx-page-activity-toolbar-toggleset'>
            {/* External Sources Selection */}
            <div><strong>Other External Sources</strong></div>
            <ToggleButtonGroup
            value={selectedOtherExternalSources}
            onChange={handleOtherExternalSourcesChange}
            aria-label="pega source selection"
            >
            <ToggleButton value="external" aria-label="External">
                Other
            </ToggleButton>
            </ToggleButtonGroup>
            </div>

        </div>

        {/* Display Activity History Items */}
        {activityHistoryByOrgId.map((activityHistory) => (
          <CompActivityHistoryItem 
            key={activityHistory.activity_history_id} 
            activityHistoryId={activityHistory.activity_history_id} 
            activityUser={activityHistory.authoring_user} 
            activityHistorySource={activityHistory.source} 
            activityHistoryMessage={activityHistory.message} 
            activityHistoryDateTime={activityHistory.date_time} 
          />
        ))}

        </div>
      </CompPageTempMaster3Panel>
    </div>
  );
};

export default PageActivity;
