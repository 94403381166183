import React, { useEffect, useState, useContext } from 'react';
import './CompCRUDFormDesignSession.css';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';
import CompModalButtons from './CompCRUDModalButtons';
import EditIcon from '@mui/icons-material/Edit';
import CompCRUDModalActionBar from './CompCRUDModalActionBar';
import { createDesignSession, updateDesignSession } from '../fxAPI/fxDesignSessionsAPI.js';
import { getUsersByOrgId } from '../fxAPI/fxUsersAPI.js';
import { PanelContext, refreshContext } from '../Contexts/PanelContext';

const CompCRUDFormDesignSession = (props) => {
  const [currentEditableState, setCurrentEditableState] = useState('view');
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [leadUser, setLeadUser] = useState(localStorage.getItem("fxLocalUserID"));
  const [participants, setParticipants] = useState([]);
  const [status, setStatus] = useState('active');
  const [relatedAnchor, setRelatedAnchor] = useState('');
  const [relatedObjectType, setRelatedObjectType] = useState('');
  const [relatedObject, setRelatedObject] = useState('');
  const [designSessionResponse, setDesignSessionResponse] = useState(null);
  const [updateDesignSessionName, setUpdateDesignSessionName] = useState(props.currentComponentData ? props.currentComponentData.name : '');
  const [updateDesignSessionDescription, setUpdateDesignSessionDescription] = useState(props.currentComponentData ? props.currentComponentData.description : '');
  const [users, setUsers] = useState([]);
  const [error, setError] = useState(null);

  const { resetElementRefresh, triggerElementRefresh } = useContext(PanelContext);

  useEffect(() => {
    const fetchUsers = async () => {
      const orgId = localStorage.getItem("fxLocalOrgID");
      await getUsersByOrgId(orgId, setError, setUsers);
    };
    fetchUsers();
  }, []);

  const handleParticipantsChange = (event) => {
    const value = event.target.value;
    setParticipants(value);
  };

  const handleAddParticipant = (event) => {
    const userId = event.target.value;
    if (userId && !participants.includes(userId)) {
      setParticipants([...participants, userId]);
    }
  };

  const handleRemoveParticipant = (userId) => {
    setParticipants(participants.filter((id) => id !== userId));
  };

  const triggerViewMode = (currentViewMode) => {
    setCurrentEditableState(currentViewMode);
  };

  const handleCloseModal = () => {
    refreshContext(resetElementRefresh, triggerElementRefresh, 'dashboard');
    props.onClose();
  };

  const handleCreateDesignSession = async (event) => {
    event.preventDefault();
    const related_org = localStorage.getItem("fxLocalOrgID");
    const related_project = props.relatedProject || null;
    const related_anchor = props.relatedAnchorId || relatedAnchor;
    const related_object_type = props.relatedAnchorId ? "anchor" : relatedObjectType;
    const related_object = props.relatedAnchorId || relatedObject;

    await createDesignSession(name, description, leadUser, participants, status, related_project, related_org, related_anchor, related_object_type, related_object);
    //handleCloseModal(); // Close the modal after creating the design session
  };

  const handleUpdateDesignSessionNameChange = (e) => {
    setUpdateDesignSessionName(e.target.value);
  }

  const handleUpdateDesignSessionDescriptionChange = (e) => {
    setUpdateDesignSessionDescription(e.target.value);
  }

  const handleUpdateDesignSession = async (e) => {
    console.log('ran update design session: ' + props.currentComponentData.design_session_id);
    e.preventDefault();
    const relatedOrg = localStorage.getItem("fxLocalOrgID");
    const designSessionId = props.currentComponentData.design_session_id;
    const updateName = updateDesignSessionName;
    const updateDescription = updateDesignSessionDescription;
    const related_project = props.relatedProject;
    const related_anchor = props.relatedAnchorId || relatedAnchor;
    const related_object_type = props.relatedAnchorId ? "anchor" : relatedObjectType;
    const related_object = props.relatedAnchorId || relatedObject;

    await updateDesignSession(designSessionId, updateName, updateDescription, leadUser, participants, status, related_project, relatedOrg, related_anchor, related_object_type, related_object);
    handleCloseModal(); // Close the modal after updating the design session
  };

  return (
    <div className={props.crudMethod === "create" && props.formStyle === "darkBG" ? "fx-crud-form-darkBG" : ""}>
        {props.crudMethod === "create" &&
        <div>
            <br />
            <form onSubmit={handleCreateDesignSession}>
              <div>
                <TextField id="outlined-basic" label="Design Session Name" variant="outlined" onChange={(e) => setName(e.target.value)} />
              </div>   
              <br />
              <div className="fx-global-form-text-area-description">
              <TextField
                id="outlined-multiline-flexible"
                label="Description"
                multiline
                maxRows={4}
                onChange={(e) => setDescription(e.target.value)}
                fullWidth
                />  
              </div>
              <br />
              <div className="select-container">
                <div className='fx-material-override-select-wrap'>
                  <label htmlFor="select-users">Select Users</label><br />
                  <select onChange={handleAddParticipant}>
                    <option value="">Select a user</option>
                    {users.map((user) => (
                      <option key={user.user_id} value={user.user_id}>
                        {user.username}
                      </option>
                    ))}
                  </select>
                </div>
                <div>
                  <ul className="fx-crud-form-design-session-participant-list">
                    {participants.map((userId) => {
                      const user = users.find((user) => user.user_id === userId);
                      return (
                        <li key={userId} style={{ marginRight: '10px' }}>
                          <Checkbox checked={true} onChange={() => handleRemoveParticipant(userId)} />
                          <ListItemText primary={user ? user.username : userId} />
                        </li>
                      );
                    })}
                  </ul>
                </div>
                <div className='fx-material-override-select-wrap'>
                  <label htmlFor="design-session-status">Status</label><br />
                  <select onChange={(e) => setStatus(e.target.value)}>
                    <option value="active">Active</option>
                    <option value="inactive">Inactive</option>
                  </select>
                </div>
                {!props.relatedAnchorId && (
                  <div className='fx-material-override-select-wrap'>
                    <label htmlFor="design-session-related-anchor">Related Anchor</label><br />
                    <select onChange={(e) => setRelatedAnchor(e.target.value)}>
                      <option value="anchor1">Anchor 1</option>
                      <option value="anchor2">Anchor 2</option>
                    </select>
                  </div>
                )}
                {!props.relatedAnchorId && (
                  <div className='fx-material-override-select-wrap'>
                    <label htmlFor="design-session-related-object-type">Related Object Type</label><br />
                    <select onChange={(e) => setRelatedObjectType(e.target.value)}>
                      <option value="ui-asset">UI Asset</option>
                      <option value="component">Component</option>
                    </select>
                  </div>
                )}
                {!props.relatedAnchorId && (
                  <div className='fx-material-override-select-wrap'>
                    <label htmlFor="design-session-related-object">Related Object</label><br />
                    <select onChange={(e) => setRelatedObject(e.target.value)}>
                      <option value="object1">Object 1</option>
                      <option value="object2">Object 2</option>
                    </select>
                  </div>
                )}
              </div>
              <CompModalButtons>
                <Button className="fx-component-form-button" type="submit">Create Design Session</Button>
              </CompModalButtons>         
            </form>
        </div>
        }
        {props.crudMethod === "view-edit" &&
          <div>
            <CompCRUDModalActionBar>
            { currentEditableState === "view" &&
              <Button onClick={() => triggerViewMode("edit")}><EditIcon /></Button>
            }
            { currentEditableState === "edit" &&
              <Button variant="outlined" onClick={() => triggerViewMode("view")}>Save</Button>
            }
          </CompCRUDModalActionBar>

          { currentEditableState === "view" &&
            <div>
              <div>
                <div className='fx-global-form-read-only-label'>Design Session Name</div>
                <div>{props.currentComponentData ? props.currentComponentData.name : ''}</div>
              </div> 
              <div>
                <div className='fx-global-form-read-only-label'>Description</div>
                <div>{props.currentComponentData ? props.currentComponentData.description : ''}</div>
              </div> 
            </div>
          } 

          { currentEditableState === "edit" &&
            <div>
              <form onSubmit={handleUpdateDesignSession}>
                <div>
                  <TextField id="outlined-basic" label="Design Session Name" variant="outlined" value={updateDesignSessionName} onChange={handleUpdateDesignSessionNameChange} />
                </div>   
                <br />
                <div className="fx-global-form-text-area-description">
                <TextField
                  id="outlined-multiline-flexible"
                  label="Design Session Description"
                  value={updateDesignSessionDescription}
                  onChange={handleUpdateDesignSessionDescriptionChange}
                  multiline
                  maxRows={4}
                  fullWidth
                  />  
                </div>
                <br />
                <div>
                  <TextField id="outlined-basic" label="Lead User" variant="outlined" value={leadUser} disabled />
                </div>
                <br />
                <div className="select-container">
                  <div className='fx-material-override-select-wrap'>
                    <label htmlFor="select-users">Select Users</label><br />
                    <select onChange={handleAddParticipant}>
                      <option value="">Select a user</option>
                      {users.map((user) => (
                        <option key={user.user_id} value={user.user_id}>
                          {user.username}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div>
                    <ul>
                      {participants.map((userId) => {
                        const user = users.find((user) => user.user_id === userId);
                        return (
                          <li key={userId} className="fx-crud-form-design-session-participant">
                            <Checkbox checked={true} onChange={() => handleRemoveParticipant(userId)} />
                            <ListItemText primary={user ? user.username : userId} />
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                  <div className='fx-material-override-select-wrap'>
                    <label htmlFor="design-session-status">Status</label><br />
                    <select onChange={(e) => setStatus(e.target.value)}>
                      <option value="active">Active</option>
                      <option value="inactive">Inactive</option>
                    </select>
                  </div>
                  <div className='fx-material-override-select-wrap'>
                    <label htmlFor="design-session-related-anchor">Related Anchor</label><br />
                    <select onChange={(e) => setRelatedAnchor(e.target.value)}>
                      <option value="anchor1">Anchor 1</option>
                      <option value="anchor2">Anchor 2</option>
                    </select>
                  </div>
                  <div className='fx-material-override-select-wrap'>
                    <label htmlFor="design-session-related-object-type">Related Object Type</label><br />
                    <select onChange={(e) => setRelatedObjectType(e.target.value)}>
                      <option value="ui-asset">UI Asset</option>
                      <option value="component">Component</option>
                    </select>
                  </div>
                  <div className='fx-material-override-select-wrap'>
                    <label htmlFor="design-session-related-object">Related Object</label><br />
                    <select onChange={(e) => setRelatedObject(e.target.value)}>
                      <option value="object1">Object 1</option>
                      <option value="object2">Object 2</option>
                    </select>
                  </div>
                </div>
                <CompModalButtons>
                  <Button type='submit'>Save Design Session</Button>
                  <Button type="button" onClick={handleCloseModal}>Cancel</Button>
                </CompModalButtons>         
              </form>
            </div>
          }     

          </div>
        }
    </div>
  );
};

export default CompCRUDFormDesignSession;