import React, { useState, useEffect, useContext  } from 'react';
import './CompJourneyMap.css';
import { getPathsByProjectId } from '../fxAPI/fxPathsAPI';
import { getAnchorsPersonasPathsByPathId, getAnchorsPathsByPathId, getAnchorsPersonasByAnchorId } from '../fxAPI/fxAnchorsAPI';
import CompJourneyCard from './CompJourneyCard';
import CompSwimlaneCard from './CompSwimlaneCard';
import CompUserPersonaAvatar from './CompUserPersonaAvatar';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import CompCollapsibleContainer from './CompCollapsibleContainer';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import Alert from '@mui/material/Alert';
import CompJourneyAddMenu from './CompJourneyAddMenu';
import CompJourneyAnchorListComponents from './CompJourneyAnchorListComponents';
import CompJourneyDeviceListComponents from './CompJourneyDeviceListComponents'; // Reintroduce this line
import { Button } from '@mui/material';
import CompCRUDModal from './CompCRUDModal';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import ReactFlow, { MiniMap, Controls, Background, addEdge } from 'reactflow';
import 'reactflow/dist/style.css';
import CompNodeBreakdown from './CompNodeBreakdown'; // Import the new component
import CompDashCardGallery from './CompDashCardGallery';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import ViewCompactIcon from '@mui/icons-material/ViewCompact';
import ViewListIcon from '@mui/icons-material/ViewList';
import ImageIcon from '@mui/icons-material/Image';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import CompJourneyImageBlock from './CompJourneyImageBlock';
import { PanelContext } from '../Contexts/PanelContext';
import { v4 as uuidv4 } from 'uuid'; // Import UUID library
import LayersIcon from '@mui/icons-material/Layers';

const CompJourneyMap = (props) => {

    const [error, setError] = useState(null);
    const [isOpen, setIsOpen] = useState(false);
    const [itemType, setItemType] = useState(null);
    const [actionModalTitle, setActionModalTitle] = useState(null);
    const [crudMethod, setCRUDMethod] = useState(null);
    const [isStepCard, setIsStepCard] = useState(false);
    const [viewModes, setViewModes] = useState(['default']);
    const [journeyMode, setJourneyMode] = useState('full'); // Initialize journeyMode state

    const openModal = (itemType, currentCRUDMethod) => {
        setItemType(itemType);
        setCRUDMethod(currentCRUDMethod);
        if(itemType === "path") {
          setActionModalTitle('Create Journey');
        }
        setIsOpen(true);
      };
    
      const closeModal = () => {
        setIsOpen(false);
      };

  /* PATHS BY PROJECT ID */
    const [pathsByProjectId, setPathsByProjectData] = useState([]); // Initialize as an array
    const [currentPath, setCurrentPath] = useState(props.relatedPath);

    /* get user personas by orgId */
    const fxAPIFetchPathsByProjectId = async (projectId) => {
      await getPathsByProjectId(projectId, setError, setPathsByProjectData);
      console.log('all paths by project Id');
      console.dir(pathsByProjectId);
    };

    /* ANCHORS PERSONAS PATHS BY PATH ID */
      const [anchorsPersonasPathsByPathId, setAnchorsPersonasPathsByPathData] = useState([]); // Initialize as an array
      /* get user personas by orgId */
      const fxAPIFetchAnchorsPersonasPathsByPathId = async (pathId) => {
        await getAnchorsPersonasPathsByPathId(pathId, setError, setAnchorsPersonasPathsByPathData);
        console.log('all anchors personas paths by path Id');
        console.dir(anchorsPersonasPathsByPathId);
      };

    /* ANCHORS PERSONAS BY ANCHOR ID */
    const [anchorsPersonasByAnchorId, setAnchorsPersonasByAnchorData] = useState([]); // Initialize as an array
    /* get user personas by orgId */
    const fxAPIFetchAnchorsPersonasByAnchorId = async (anchorId) => {
      await getAnchorsPersonasByAnchorId(anchorId, setError, setAnchorsPersonasByAnchorData);
      console.log('all anchors personas by anchor Id');
      console.dir(anchorsPersonasByAnchorId);
    };

    useEffect(() => {
        const fetchData = async () => {
            // Fetch paths by project ID
            console.log('Fetching path data for projectId:', props.relatedProject);
            await fxAPIFetchPathsByProjectId(props.relatedProject);
            localStorage.setItem("fxCurrentProject", props.relatedProject);
    
            // Fetch anchor persona paths by path ID
            console.log('Fetching anchor persona path data for pathId:', currentPath);
            await fxAPIFetchAnchorsPersonasPathsByPathId(currentPath);
    
            console.log('Data fetching complete.');

            anchorsPersonasPathsByPathId.forEach((persona) => {
                console.log('Processing persona:', persona);
            });

        };
    
        fetchData();
    }, [props.relatedProject, currentPath]);

    useEffect(() => {
        console.log('caught update of anchorsPersonasPathsByPathId');
        anchorsPersonasPathsByPathId.forEach((persona) => {
            console.log('Processing persona:', persona);
            fxAPIFetchAnchorsPersonasByAnchorId(persona.anchor_id);
        });
    }, [anchorsPersonasPathsByPathId]);

    const [uniqueUserPersonas, setUniqueUserPersonas] = useState([]);

    useEffect(() => {
        console.log('Caught update of anchorsPersonasByAnchorId');

        const updatedPersonas = [...uniqueUserPersonas];

        anchorsPersonasByAnchorId.forEach((personaByAnchor) => {
            const { user_persona_id, user_persona_view_name, related_avatar } = personaByAnchor;

            console.log('Processing persona by anchor:', personaByAnchor);

            // Check if user_persona_id already exists in uniqueUserPersonas
            const exists = updatedPersonas.some(
                (persona) => persona.user_persona_id === user_persona_id
            );

            if (!exists) {
                // Add new persona to the list
                updatedPersonas.push({ user_persona_id, user_persona_view_name, related_avatar });
            }
        });

        setUniqueUserPersonas(updatedPersonas);
    }, [anchorsPersonasByAnchorId]);

    useEffect(() => {
        console.log('Unique User Personas:', uniqueUserPersonas);
    }, [uniqueUserPersonas]);

    const handlePathChange = (event) => {
        setCurrentPath(event.target.value);
    };

    const [tabValue, setTabValue] = useState(0);

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
      };
    
    const initialNodes = [
      { id: '1', position: { x: 300, y: 5 }, data: { label: 'Node 1' } },
      { id: '2', position: { x: 100, y: 100 }, data: { label: 'Node 2' } },
      { id: '3', position: { x: 400, y: 100 }, data: { label: 'Node 3' } },
    ];

    const initialEdges = [
      { id: 'e1-2', source: '1', target: '2', animated: true },
      { id: 'e1-3', source: '1', target: '3', animated: true },
    ];

    const [nodes, setNodes] = useState([]);
    const [edges, setEdges] = useState([]);

    const onConnect = (params) => {
        console.log('Connection made:', params);
        setEdges((eds) => addEdge({ ...params, style: { zIndex: 10 } }, eds));
    };

    useEffect(() => {
        const newNodes = [];
        const newEdges = [];
        let yOffset = 0;
        const padding = 20; // Padding around the nodes
        const horizontalOffset = 20; // Additional horizontal offset

        // Add new series of nodes above the existing rows
        const newRowYOffset = -70; // Adjust this value as needed for spacing
        uniqueUserPersonas.forEach((personaJourneyRow, personaIndex) => {
            anchorsPersonasPathsByPathId.forEach((anchor, anchorIndex) => {
                const newNodeId = `header-node-${anchor.anchor_id}-${personaJourneyRow.user_persona_id}`;

                newNodes.push({
                    id: newNodeId,
                    position: { x: (anchorIndex + 1) * 300 + horizontalOffset, y: newRowYOffset },
                    data: { label: `${anchor.anchor_view_name}` },
                    style: { width: 300, height: 45, border: '1px solid #cccccc', zIndex: 2 },
                    draggable: false,
                    selectable: false,
                    type: 'input' // Make it static without connecting dots
                });
            });
        });

        uniqueUserPersonas.forEach((personaJourneyRow, personaIndex) => {
            const rowHeight = 160 + padding * 2; // Node height + padding
            const rowWidth = (anchorsPersonasPathsByPathId.length + 1) * 300 + padding * 2; // Total width of the row + padding

            // Add background node for the row first
            newNodes.push({
                id: `background-${personaJourneyRow.user_persona_id}`,
                position: { x: -padding, y: yOffset - padding },
                data: { label: '' },
                style: { width: rowWidth, height: rowHeight, backgroundColor: '#f0f0f0', border: '1px solid #ccc', zIndex: 1, opacity: 0.5 },
                draggable: false,
                selectable: false,
            });

            const personaNodeId = `persona-${personaJourneyRow.user_persona_id}`;
            newNodes.push({
                id: personaNodeId,
                position: { x: horizontalOffset, y: yOffset },
                data: {
                    label: (
                        <div>
                            {personaJourneyRow.user_persona_view_name}
                            <CompUserPersonaAvatar avatarId={personaJourneyRow.related_avatar} avatarSize='lg' />
                        </div>
                    ),
                },
                style: { width: 300, height: 160, zIndex: 2 },
                sourcePosition: 'right',
                targetPosition: 'left',
                type: 'journey-node' // Tag the node as a journey node
            });

            let previousNodeId = personaNodeId;

            anchorsPersonasPathsByPathId.forEach((anchor, anchorIndex) => {
                const anchorNodeId = `anchor-${anchor.anchor_id}-${personaJourneyRow.user_persona_id}`;
                const connectionPointId = `connection-point-${props.relatedPath}-${personaJourneyRow.user_persona_id}-${anchor.anchor_id}`; // Use a combination of path, user persona, and anchor
                const isStepCard = false; // Assume false initially, will be updated by CompSwimlaneCard

                newNodes.push({
                    id: anchorNodeId,
                    position: { x: (anchorIndex + 1) * 300 + horizontalOffset, y: yOffset },
                    data: {
                        label: (
                            <CompSwimlaneCard
                                anchorType={anchor.type}
                                personaName={personaJourneyRow.user_persona_view_name}
                                anchorId={anchor.anchor_id}
                                userPersonaId={personaJourneyRow.user_persona_id}
                                anchorName={anchor.anchor_view_name}
                                onStepCardStatusChange={(status) => handleStepCardStatusChange(status, anchorNodeId)}
                            />
                        ),
                    },
                    style: { width: 300, height: 160, border: isStepCard ? '2px solid green' : '2px solid red', zIndex: 2 },
                    sourcePosition: 'right',
                    targetPosition: 'left',
                    type: 'journey-node' // Tag the node as a journey node
                });

                // Add a connection point in the middle of the node
                newNodes.push({
                    id: connectionPointId,
                    position: { x: (anchorIndex + 1) * 300 + horizontalOffset + 125, y: yOffset + 70 },
                    data: { label: '' },
                    style: { width: 10, height: 10, backgroundColor: 'white', borderRadius: '50%', zIndex: 3, border: '1px solid black', opacity: '.5' },
                    draggable: false,
                    selectable: true, // Make it selectable
                    sourcePosition: 'right',
                    targetPosition: 'left',
                    type: 'default' // Allow connections
                });

                newEdges.push({
                    id: `edge-${previousNodeId}-${anchorNodeId}`,
                    source: previousNodeId,
                    target: anchorNodeId,
                    type: 'smoothstep',
                    style: { stroke: 'black', zIndex: 10 }, // Set the edge color to black and zIndex to 10
                    sourceHandle: 'right',
                    targetHandle: 'left',
                });

                previousNodeId = anchorNodeId;
            });

            yOffset += rowHeight; // Move to the next row without additional spacing
        });

        // Add new series of nodes below the existing rows
        const footerRowYOffset = yOffset + 50; // Adjust this value as needed for spacing
        uniqueUserPersonas.forEach((personaJourneyRow, personaIndex) => {
            anchorsPersonasPathsByPathId.forEach((anchor, anchorIndex) => {
                const newNodeId = `footer-node-${anchor.anchor_id}-${personaJourneyRow.user_persona_id}`;

                newNodes.push({
                    id: newNodeId,
                    position: { x: (anchorIndex + 1) * 300 + horizontalOffset, y: footerRowYOffset },
                    data: {
                        label: (
                            <CompNodeBreakdown
                                anchorId={anchor.anchor_id}
                                userPersonaId={personaJourneyRow.user_persona_id}
                                relatedProject={props.relatedProject}
                            />
                        ),
                    },
                    style: { width: 300, minHeight: 90, border: '1px solid #FFB400', zIndex: 2, overflow: 'hidden' }, // Set minHeight and prevent overflow
                    draggable: false,
                    selectable: true, // Make it selectable
                    type: 'input' // Make it static without connecting dots
                });
            });
        });

        // Set edges after nodes to ensure they are drawn on top
        setNodes(newNodes);
        setEdges(newEdges);
    }, [uniqueUserPersonas, anchorsPersonasPathsByPathId]);

    useEffect(() => {
        const newEdges = [];

        nodes.forEach((node, index) => {
            if (node.type === 'journey-node' && index < nodes.length - 1) {
                const nextNode = nodes[index + 1];
                if (nextNode.type === 'journey-node') {
                    newEdges.push({
                        id: `edge-${node.id}-${nextNode.id}`,
                        source: node.id,
                        target: nextNode.id,
                        type: 'smoothstep',
                        style: { stroke: 'black', zIndex: 10 }, // Set the edge color to black and zIndex to 10
                        sourceHandle: 'right',
                        targetHandle: 'left',
                    });
                }
            }
        });

        setEdges((prevEdges) => [...prevEdges, ...newEdges]);
    }, [nodes]);

    const handleStepCardStatusChange = (status, nodeId) => {
        setNodes((nds) => {
            const updatedNodes = nds.map((node) => {
                if (node.id === nodeId) {
                    node.style = {
                        ...node.style,
                        border: status ? '2px solid green' : '2px solid red',
                    };
                }
                return node;
            });

            if (!status) {
                const nodeIndex = updatedNodes.findIndex((node) => node.id === nodeId);
                if (nodeIndex !== -1) {
                    const nodePosition = updatedNodes[nodeIndex].position;

                    // Check above and below for true nodes
                    updatedNodes.forEach((node) => {
                        if (
                            node.position.x === nodePosition.x &&
                            (node.position.y === nodePosition.y - 200 || node.position.y === nodePosition.y + 200) &&
                            node.style.border === '2px solid green'
                        ) {
                            node.style = {
                                ...node.style,
                                border: '2px solid orange',
                            };
                        }
                    });
                }
            }

            return updatedNodes;
        });
    };

    useEffect(() => {
        console.log('Current isStepCard state:', isStepCard);
    }, [isStepCard]);

    const handleViewModeChange = (mode) => {
        setViewModes((prevModes) => {
            if (prevModes.includes(mode)) {
                return prevModes.filter((m) => m !== mode);
            } else {
                return [...prevModes, mode];
            }
        });
    };

    const handleJourneyModeChange = (mode) => {
        setJourneyMode(mode);
    };

    const handleCreateStep = () => {
        console.log('Create step button clicked');
    };

    /* Use Global Context to refresh Subjective Panel */
        const { triggerElementRefresh } = useContext(PanelContext);
      
        const handleRefreshElement = () => {
          triggerElementRefresh('sideContextPanel'); // Trigger refresh for the subjectivePanel
        };
      
        const handleSwitchPanelContext = (object_id, type, object) => {
          //updatePanelContext("objective", objective_id, object);
          localStorage.setItem("fxSidePanelContext", type);
          localStorage.setItem("fxSidePanelObjectId", object_id);
          localStorage.setItem("fxSidePanelObject", JSON.stringify(object));
          handleRefreshElement();
        };

    const handleObjectiveDrop = (objective) => {
        console.log('Objective dropped:', objective);
        // Stubbed out function to verify it's working
    };

    return (
        <div
            onDrop={(event) => {
                event.preventDefault();
                const objectiveData = event.dataTransfer.getData('objective');
                if (objectiveData) {
                    const objective = JSON.parse(objectiveData);
                    handleObjectiveDrop(objective);
                }
            }}
            onDragOver={(event) => event.preventDefault()} // Allow drop
        >
            <div style={{ height: 800, position: 'relative' }}>
                <div style={{
                    position: 'absolute',
                    top: 10,
                    right: 10,
                    height: 40,
                    display: 'flex',
                    alignItems: 'center',
                    padding: '0 10px',
                    zIndex: 10
                }}>
                    <Button variant="outlined" onClick={() => handleSwitchPanelContext(props.relatedPath, "create-anchor", null)} style={{ marginLeft: '10px' }}>
                        Create Step
                    </Button>
                    <div className="fx-journey-map-right-toggle-toolbar"> 
                        <LayersIcon
                            onClick={() => handleSwitchPanelContext(props.relatedPath, "show-layers", null)}
                            style={{ cursor: 'pointer', color: viewModes.includes('default') ? 'blue' : 'black' }}
                        />
                        {/*
                        <ViewCompactIcon
                            onClick={() => handleViewModeChange('compact')}
                            style={{ cursor: 'pointer', color: viewModes.includes('compact') ? 'blue' : 'black' }}
                        />
                        <ViewListIcon
                            onClick={() => handleViewModeChange('detailed')}
                            style={{ cursor: 'pointer', color: viewModes.includes('detailed') ? 'blue' : 'black' }}
                        />
                        */}
                    </div>
                </div>
                <div style={{
                    position: 'absolute',
                    top: 10,
                    left: 10,
                    height: 40,
                    border: '1px solid #ccc',
                    backgroundColor: 'white',
                    display: 'flex',
                    alignItems: 'center',
                    padding: '0 10px',
                    zIndex: 10
                }}>
                    <ImageIcon
                        onClick={() => handleJourneyModeChange('image')}
                        style={{ cursor: 'pointer', color: journeyMode === 'image' ? 'blue' : 'black' }}
                    />
                    <AccountTreeIcon
                        onClick={() => handleJourneyModeChange('full')}
                        style={{ cursor: 'pointer', color: journeyMode === 'full' ? 'blue' : 'black' }}
                    />
                </div>
                {journeyMode === 'full' ? (
                    <ReactFlow nodes={nodes} edges={edges} onConnect={onConnect}>
                        <MiniMap />
                        <Controls />
                        <Background />
                    </ReactFlow>
                ) : (
                    <ReactFlow nodes={anchorsPersonasPathsByPathId.map((anchor, index) => ({
                        id: `image-node-${anchor.anchor_id}`,
                        position: { x: index * 450, y: 50 },
                        data: {
                            label: (
                                <CompJourneyImageBlock anchorViewName={anchor.anchor_view_name} anchorId={anchor.anchor_id} />
                            ),
                        },
                        style: { width: 400, height: 'auto', border: '1px solid #cccccc', overflow: 'hidden' },
                        draggable: false,
                        selectable: false,
                        sourcePosition: 'right',
                        targetPosition: 'left',
                    }))} edges={anchorsPersonasPathsByPathId.map((anchor, index) => (
                        index < anchorsPersonasPathsByPathId.length - 1 ? {
                            id: `edge-${anchor.anchor_id}-${anchorsPersonasPathsByPathId[index + 1].anchor_id}`,
                            source: `image-node-${anchor.anchor_id}`,
                            target: `image-node-${anchorsPersonasPathsByPathId[index + 1].anchor_id}`,
                            type: 'smoothstep',
                            style: { stroke: 'black', zIndex: 10 },
                        } : null
                    )).filter(edge => edge !== null)} onConnect={onConnect}>
                        {/* ReactFlow view for 'image' mode */}
                    </ReactFlow>
                )}
            </div>
        </div>
    );
};

export default CompJourneyMap;