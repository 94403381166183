import React, { useEffect, useState, useContext } from 'react';
import './CompUserPersonaAvatar.css';
import avatars from '../Data/avatars.json';


const CompUserPersonaAvatar = (props) => {

  const [selectedAvatar, setSelectedAvatar] = useState(null);
  const passedAvatar = props.avatarId || '1';
  console.log('passed avatar via props: ' + props.avatarId);
  console.log('passed avatar: ' + passedAvatar);

  useEffect(() => {
    const avatar = avatars.avatars.find(a => a.id === passedAvatar);
    setSelectedAvatar(avatar);
    console.log('selected avatar: ' + selectedAvatar);
  }, [passedAvatar]);

  if (!selectedAvatar) return <p>...</p>;

  const avatarClass = props.avatarSize === 'lg' ? 'fx-comp-user-persona-avatar-lg' : 'fx-comp-user-persona-avatar';

  return (
    <div>
      <img className={avatarClass} src={selectedAvatar.svgPath} />
    </div>
  );
};

export default CompUserPersonaAvatar;