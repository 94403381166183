import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Box from '@mui/material/Box';
import CompPageTempUtilityCenter from '../Components/CompPageTempUtilityCenter';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Grid from '@mui/material/Grid';
import axios from 'axios';
import './PageStart.css';
import { useAuth0 } from "@auth0/auth0-react";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Alert from '@mui/material/Alert';
import CompStartSetup from '../Components/CompStartSetup';
//import { authenticate } from '../Services/authService';
import CompPageTempIntroSlide from '../Components/CompPageTempIntroSlide';
import CompStartAuthenticated from '../Components/CompStartAuthenticated';
import FluxitySplashImg from '../images/fx-start-splash.png';
import CompHubspotWaitlistForm from '../Marketing/CompHubspotWaitlistForm';
import FluxityLogo from '../images/Fluxity_Logo.svg';
import StartFeatureHyperLogicImg from '../images/fx-start-image-hyperlogic.png';
import StartFeatureInterrupt1Img from '../images/fx-page-start-feature-interruptions-1.png';
import StartFeatureInterrupt2Img from '../images/fx-page-start-feature-interruptions-2.png';
import StartFeatureInterrupt3Img from '../images/fx-page-start-feature-interruptions-3.png';
import CompStartPendingApproval from '../Components/CompStartPendingOrgApproval';
import { v4 as uuidv4 } from 'uuid'; // Import UUID library for generating unique state
import CompWebFooter from '../Components/CompWebFooter';
import CompWebMobileMenu from '../Components/CompWebMobileMenu';
import CompWebHeader from '../Components/CompWebHeader';
import Typography from '@mui/material/Typography';

const PageStart = () => {

  console.log('test page start');

  const [userRole, setUserRole] = React.useState('');

  const handleUserRoleChange = (event) => {
    setUserRole(event.target.value);
  };

  const { isLoading, isAuthenticated, user, loginWithRedirect, logout } = useAuth0();

  const location = useLocation();
  const navigate = useNavigate();

    const handleClickRole = (userRole) => {
        // Process some logic here
        console.log('Processing logic...');
        localStorage.setItem("fxLocalUserRole", userRole);

        // After processing logic, navigate to the desired route
        navigate('/dashboard/role/' + userRole);
    };

/*
  if (isLoading) {
    return <div>Loading...</div>;
  }
  if (error) {
    return <div>Oops... {error.message}</div>;
  }
  */

  const [email, setEmail] = useState('');
  const [userData, setUserData] = useState(null);
  const [error, setError] = useState(null);

  const getUserByEmail = async (email) => {
    setError(null);
    setUserData(null);
  
    if (!email) {
      setError('Email parameter is required');
      return;
    }
  
    try {
      console.log(`Fetching user data for email: ${email}`);
      const response = await axios.get('/fxapi/users/user-by-email', {
        params: { email }
      });
      setUserData(response.data);
      console.log('Found user data:', response.data);
      console.log('User Name: ' + response.data.items[0].username);
      localStorage.setItem("fxLocalUserName", response.data.items[0].username);
      console.log('User ID: ' + response.data.items[0].user_id);
      localStorage.setItem("fxLocalUserID", response.data.items[0].user_id);
      const storedLocalUserName = localStorage.getItem("fxLocalUserName");
      console.log('Local Username: ' + storedLocalUserName);
      const storedLocalUserID = localStorage.getItem("fxLocalUserID");
      console.log('Local User ID: ' + storedLocalUserID);

      /* manage related org */
      localStorage.setItem("fxLocalOrgID", response.data.items[0].related_org_id);
      const storedLocalOrgId = localStorage.getItem("fxLocalOrgID");
      console.log('Local Org ID: ' + storedLocalOrgId);

      try {
        const orgId = storedLocalOrgId;
        console.log(`Fetching org data for org Id: ${storedLocalOrgId}`);
        const response = await axios.get('/fxapi/organizations/organization-by-org', {
          params: { orgId }
        });
        console.log('API response for organization received:', response.data.items);
        console.log('Parsed config to JSON for ORG:');
        console.dir(JSON.parse(response.data.items[0].config));
        const localConfig = JSON.parse(response.data.items[0].config);
        const localConfigString = JSON.stringify(localConfig);
        localStorage.setItem("fxLocalOrgConfig", localConfigString);
        console.log('Local Org Config: ');
        console.dir(localConfig);
        
      
      } catch (error) {
        console.error('Error fetching user data:', error);
        setError(error.response ? error.response.data : 'Failed to fetch user data');
      }
      /* manage default project id */
      localStorage.setItem("fxLocalDefaultProjectId", response.data.items[0].default_project_id);
      const storedLocalProjectId = localStorage.getItem("fxLocalDefaultProjectId");
      console.log('Local Default Project ID: ' + storedLocalProjectId);

      /* manage user first name */
      localStorage.setItem("fxLocalUserFirstName", response.data.items[0].first_name);
      const storedUserFirstName = localStorage.getItem("fxLocalUserFirstName");
      console.log('Local Default FirstName: ' + storedUserFirstName);

      /* set role */
      localStorage.setItem("fxLocalUserRole", response.data.items[0].role);

    } catch (error) {
      console.error('Error fetching user data:', error);
      setError(error.response ? error.response.data : 'Failed to fetch user data');
    }
  };

  const setTempSessionVars = async () => {
    localStorage.setItem("fxLocalUserID", '123456');
    const storedLocalUserID = localStorage.getItem("fxLocalUserID");
    console.log('test user id stored: ' + storedLocalUserID);
    localStorage.setItem("fxLocalOrgID", '12345');
    const storedLocalOrgId = localStorage.getItem("fxLocalOrgID");
    console.log('test local Org ID: ' + storedLocalOrgId);
  };

  //getUserByEmail();

  const handleLogin = async () => {
    try {
      const state = uuidv4(); // Generate a unique state parameter
      sessionStorage.setItem('auth_state', state); // Store state parameter in session storage
      await loginWithRedirect({ state }); // Pass state parameter to loginWithRedirect
    } catch (error) {
      console.error('Error during login:', error);
      // Optionally, you can show a message to the user
    }
  };

  const LoginButton = () => {
    return <Button variant="contained" onClick={handleLogin}>Log In or Sign Up</Button>;
  };

  const [tabValue, setTabValue] = useState(0);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  useEffect(() => {
    if (isAuthenticated && user) {
      console.log('user logged in: ');
      console.dir({ user });

      getUserByEmail(user.name);
    }
  }, [user]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const code = params.get('code');
    const state = params.get('state');
    const storedState = sessionStorage.getItem('auth_state');

    console.log('URL Parameters:', params.toString());
    console.log('State parameter:', state);
    console.log('Code parameter:', code);
    console.log('Stored state:', storedState);

    if (!state && !code) {
      console.log('No state or code in URL. Skipping redirect handling.');
      return;
    }

    if (state && state !== storedState) {
      console.error('State mismatch detected. Clearing stored state and retrying login.');
      sessionStorage.removeItem('auth_state'); // Clear the state parameter from session storage
      return; // Prevent infinite loop by not calling handleLogin here
    }

    if (state === storedState) {
      console.log('State parameter matches stored state. Clearing stored state.');
      sessionStorage.removeItem('auth_state'); // Clear the state parameter from session storage
    }

    // Handle the code parameter (e.g., exchange it for tokens)
    if (code) {
      console.log('Code parameter detected. Proceeding with token exchange.');
      // ...handle token exchange logic here...
    }
  }, [location]);

  const testVar = "test";

  const currentLocalOrgId = localStorage.getItem("fxLocalOrgID");

  function getHeadingOne() {
    if (isAuthenticated && user && currentLocalOrgId) {
      var userWelcomeMessage = "Choose Your Role";
    } else if(isAuthenticated && !currentLocalOrgId) {
      var userWelcomeMessage = "Continue Setup";
    } else {
      var userWelcomeMessage = "Please Login to Fluxity";
    }
    return userWelcomeMessage;
  }

  const headingOne = getHeadingOne();

  const scenarioLoggedOutLeftPanel = (
    <div></div>
  );
  
  const scenarioLoggedOutRightPanel = (
    <>
    <div className="fx-page-start-login-splash-wrap">
      <Typography variant="h1" className="fx-page-start-marquee-text-white">
        Not Everything Stays in the Shadows
      </Typography>
      <Typography variant="h4" className="fx-page-start-marquee-text-teal">
        Fluxity is in stealth mode, be among the first to know or invest early.
      </Typography>
      <div className="fx-page-start-marquee-buttons"><a href="/contact"><Button>Get Notified</Button></a> <a href="/for-investors"><Button>Investor</Button></a></div>
      <Typography variant="h2" className="fx-page-start-marquee-text-white-lg">
        The next big thing is closer than you think
      </Typography>
    </div>

    <div className="fx-page-start-content-block-container fx-page-start-content-block-container-gray">
      <div className="fx-page-start-content-block-container-inner">
        <h2>Organize, forecast, and execute with clarity</h2>
        <p className="fx-page-start-feature-paragraph">
        Stay ahead with HyperLogic — an AI-powered engine within Fluxity that instantly builds budgets, allocates resources, and structures timelines. Real-time strategy that turns vision into reality before the competition even reacts.
        </p>

        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <img src={StartFeatureHyperLogicImg} alt="Feature" style={{ width: '100%' }} />
          </Grid>
          <Grid item xs={12} md={8}>
          <div className='fx-page-start-flex-predict-prevent'>
          <h3>Predict & Prevent</h3>
            <p className="fx-page-start-feature-bullet">Forecast risks before they occur, keeping teams on track.</p>
            <h3>Instant Insights - Monitored</h3>
            <p className="fx-page-start-feature-bullet">Cut through complexity with a clear, dynamic roadmap.</p>
            <h3>Seamless Execution</h3>
            <p className="fx-page-start-feature-bullet">Stay ahead of the future, not buried in the past.</p>
          </div>
          </Grid>
        </Grid>

        
        <p className="fx-page-start-feature-paragraph">Solve complexities before they disrupt progress.</p>
      </div>
    </div>

    <div className="fx-page-start-content-block-container fx-page-start-section-outer-ideation-completion">
      <div className="fx-page-start-content-block-container-inner">
        <h2>From Ideation to Completion</h2>
        <p className="fx-page-start-feature-paragraph">
        One Truth for Product Creation Excellence
        </p>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4} className="fx-grid-item-xs-full">
            <Box sx={{ width: '100%' }}>
              <div className='fx-page-start-flex-item-ideation-completion'>
                <h3>Integration</h3>
                <p className="fx-page-start-feature-bullet">Fluxity ensures your tools work together seamlessly, reducing redundancy, eliminating silos, and enabling your team to focus on delivering results with maximum efficiency.</p>
              </div>
            </Box>
          </Grid>
          <Grid item xs={12} md={4} className="fx-grid-item-xs-full">
            <Box sx={{ width: '100%' }}>
              <div className='fx-page-start-flex-item-ideation-completion'>
                <h3>Balance</h3>
                <p className="fx-page-start-feature-bullet">Instantly measure performance, uncover actionable insights, and fine-tune your strategy—ensuring every decision fuels engagement and excellence.</p>
              </div>
            </Box>
          </Grid>
          <Grid item xs={12} md={4} className="fx-grid-item-xs-full">
            <Box sx={{ width: '100%' }}>
              <div className='fx-page-start-flex-item-ideation-completion'>
                <h3>Success</h3>
                <p className="fx-page-start-feature-bullet">Get real-time insights into your product, business, and user value with precision. Designed to capture the heartbeat of the total experience, it aligns satisfaction with business outcomes, driving unstoppable growth.</p>
              </div>
            </Box>
          </Grid>
        </Grid>
      </div>
    </div>


    <div className="fx-page-start-content-block-container fx-page-start-content-block-container-gray">
      <div className="fx-page-start-content-block-container-inner">
        <h2>In a World of Interruptions</h2>
        <p className="fx-page-start-feature-paragraph">
        Revolutionizing How Teams Succeed
        </p>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <div className='fx-page-start-flex-item-interrupt-section'>
              <div><img className='fx-page-start-flex-item-interrupt-section' src={StartFeatureInterrupt1Img} width="100%" height="auto" /></div>
              <h3>Exploring Every Touchpoint</h3>
              <p className="fx-page-start-feature-bullet">Detecting and correcting accessibility issues in real time. Fluxity ensures compliance with built-in accessibility, seamless ARIA support, and an inclusive design, making collaboration effortless for all users. </p>
            </div>
          </Grid>
          <Grid item xs={12} md={4}>
            <div className='fx-page-start-flex-item-interrupt-section'>
              <div><img src={StartFeatureInterrupt2Img} width="100%" height="auto" /></div>
              <h3>Empowering Roles with Data</h3>
              <p className="fx-page-start-feature-bullet">Empower every team member with Fluxity's Action Tracker, ensuring tasks are clearly assigned, progress is tracked, and roles are optimized. Instant status updates keep work flowing without interruptions.</p>
            </div>
          </Grid>
          <Grid item xs={12} md={4}>
            <div className='fx-page-start-flex-item-interrupt-section'>
              <div><img src={StartFeatureInterrupt3Img} width="100%" height="auto" /></div>
              <h3>Mapping Journeys</h3>
              <p className="fx-page-start-feature-bullet">Fluxity maps every touch-point of your project journey, ensuring no detail is missed. From start to finish, it creates a clear, dynamic roadmap to align tasks with user needs and goals. </p>
            </div>
          </Grid>
        </Grid>
      </div>
    </div>

    <div className="fx-page-start-content-block-container fx-page-start-content-block-container-outer-closing-section">
      <div className="fx-page-start-content-block-container-inner">
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <div className='fx-page-start-flex-item-closing-section'>
              <h3>Clarity</h3>
              <p className="fx-page-start-feature-bullet">Fluxity connects all relevant content and information, delivering real-time updates and eliminating ambiguity to keep your team aligned and focused.</p>
            </div>
          </Grid>
          <Grid item xs={12} md={4}>
            <div className='fx-page-start-flex-item-closing-section'>
              <h3>Collaboration</h3>
              <p className="fx-page-start-feature-bullet">Designed by UX Experts, Fluxity fosters seamless teamwork with tools that ensure uninterrupted communication, accountability, and shared awareness.</p>
            </div>
          </Grid>
          <Grid item xs={12} md={4}>
            <div className='fx-page-start-flex-item-closing-section'>
              <h3>Results</h3>
              <p className="fx-page-start-feature-bullet">Fluxity empowers teams to succeed effortlessly by streamlining workflows, aligning priorities, and delivering measurable outcomes distraction-free.</p>
            </div>
          </Grid>
        </Grid>
      </div>
    </div>

    <CompWebFooter />

    </>
  );

  

  return (
    <>
    {!isAuthenticated &&
            <div className="fx-page-start-unauthenticated-outer-container">
              <CompWebHeader />
              <div className="fx-page-start-unauthenticated-inner-container">
              {scenarioLoggedOutRightPanel}
              {/*
              <CompPageTempIntroSlide panelLeftContent={scenarioLoggedOutLeftPanel} panelRightContent={scenarioLoggedOutRightPanel} />
              */}
              </div>
            </div>
    }
    {isAuthenticated &&
      <>
      {/*
      Authenticated
      {isAuthenticated && user && (
        <div>User: {user.name || 'Anonymous User'}</div>
      )}

      <Button onClick={() => getUserByEmail(user.name)} variant="outlined">Get User By Email Test</Button>
      <Button onClick={() => setTempSessionVars()} variant="outlined">Set Temp Session</Button>
      

      <Button onClick={() => logout({ logoutParams: { returnTo: window.location.origin } })} variant="outlined" className='fx-comp-header-profile'>
                            Log out
                            </Button>
    */}

        {!currentLocalOrgId &&
          <>
            {/*<CompStartSetup />*/}
            <CompStartPendingApproval />
          </>
        }
        {currentLocalOrgId &&
          <CompStartAuthenticated />
        }

      </>
    }
{/*
    {isAuthenticated &&
    <CompPageTempUtilityCenter headingOne={headingOne} maxWidth="25">

      <Box sx={{ width: '100%' }}>


          <Box p={3} className="fx-page-auth-login-login-form-wrap">
            {!isAuthenticated &&
            <div>
              <div>
                <Alert severity="info">It looks like you're not logged in.  Login or Register.</Alert>
              </div>
              <div className="fx-global-form-button-row">
                <LoginButton />
              </div>
            </div>
            }
            {isAuthenticated &&
              <div>
                {currentLocalOrgId &&
                <Grid container spacing={2}>
                  <Grid item xs={9}>
                    
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">Choose a Role</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={userRole}
                        label="User Role"
                        onChange={handleUserRoleChange}
                      >
                        <MenuItem value="c-level">Executive</MenuItem>
                        <MenuItem value="scrum-master">Scrum Master</MenuItem>
                        <MenuItem value="business-analyst">Business Analyst</MenuItem>
                        <MenuItem value="developer">Developer</MenuItem>
                        <MenuItem value="ux-designer">UX Designer</MenuItem>
                      </Select>
                    </FormControl>
                    
                    
                  </Grid>
                  <Grid item xs={3}>
                      <Button variant="contained" onClick={() => handleClickRole(userRole)}>Start</Button>
                  </Grid>
                </Grid>
                }
              </div>
            }
            
          </Box>

      </Box>
    </CompPageTempUtilityCenter>
          
}*/}
    
    </>
  );
};

export default PageStart;