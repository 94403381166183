import React, { useState, useEffect, useContext, useLayoutEffect } from 'react';
import './CompTeamMemberList.css';
import Box from '@mui/material/Box';
import CompContentBlock from '../Components/CompContentBlock';
import CompFeatureCarousel from '../Components/CompFeatureCarousel';
import Button from '@mui/material/Button';
import axios from 'axios';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import { getUsersByOrgId } from '../fxAPI/fxUsersAPI.js';
import CompCRUDModal from '../Components/CompCRUDModal.jsx';
import CompQuickActionsPopover from '../Components/CompQuickActionsPopover.jsx';
import CompUserAssignment from '../Components/CompUserAssignment.jsx';
import CompTickerFeed from '../Components/CompTickerFeed.jsx';
import { PanelContext } from '../Contexts/PanelContext';

const CompTeamMemberList = (props) => {

    const [error, setError] = useState(null);

  const [isOpen, setIsOpen] = useState(false);
  const [itemType, setItemType] = useState(null);
  const [actionModalTitle, setActionModalTitle] = useState(null);
  const [crudMethod, setCRUDMethod] = useState(null);
  const [modalComponentData, setModalComponentData] = useState([]);
  const [relatedComponentId, setRelatedComponentId] = useState(null);
  const [relatedComponentName, setRelatedComponentName] = useState(null);
  const [relatedComponentType, setRelatedComponentType] = useState(null);

  const { elements, triggerElementRefresh, resetElementRefresh } = useContext(PanelContext);
    const elementId = 'teamList';

  const openModal = (itemType, currentComponentId, currentComponentName, currentComponentType, currentCRUDMethod, currentModalComponentData) => {
    setItemType(itemType);
    setRelatedComponentId(currentComponentId);
    setRelatedComponentName(currentComponentName);
    setRelatedComponentType(currentComponentType);
    setCRUDMethod(currentCRUDMethod);
    setModalComponentData(currentModalComponentData);
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  /* Users by Org */
  const [usersByOrgId, setUsersByOrgData] = useState([]); // Initialize as an array
  /* get user personas by orgId */
  const fxAPIFetchUsersByOrgId = async (orgId) => {
    await getUsersByOrgId(orgId, setError, setUsersByOrgData);
    console.log('all users by org Id');
    console.dir(usersByOrgId);
  };

  useEffect(() => {
    const currentOrgId = localStorage.getItem("fxLocalOrgID");
    fxAPIFetchUsersByOrgId(currentOrgId);
  }, []);

  useEffect(() => {
      const handleRefresh = async () => {
        //const currentLayout = localStorage.getItem('fxScreenLayout');
        //setCurrentScreenLayout(currentLayout);
        const currentOrgId = localStorage.getItem("fxLocalOrgID");
        fxAPIFetchUsersByOrgId(currentOrgId);
        console.log('ran team list refresh');
        resetElementRefresh(elementId);
      };
  
      if (elements[elementId]?.refresh) {
        handleRefresh();
        triggerElementRefresh(elementId);
      }
    }, [elements[elementId]?.refresh, triggerElementRefresh, resetElementRefresh]);

  return (
    <div>
        <CompCRUDModal
        isOpen={isOpen}
        onClose={closeModal}
        modalTitle={actionModalTitle}
        itemType={itemType}
        crudMethod={crudMethod}
        modalComponentData={modalComponentData}
        relatedComponentId={relatedComponentId}
        relatedComponentName={relatedComponentName}
        relatedComponentType={relatedComponentType}
      ></CompCRUDModal>
      <div>
        <h3>Team Members</h3>
        <div>
          <a href="/team/add-team-member">
            <Button variant="outlined">
              Add Team Member
            </Button>
          </a>
        </div>
      </div>
      <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
        {usersByOrgId.map((userItem) => (
          <>
            <ListItem>
              <div className='fx-page-team-item-row'>
                <div className='fx-page-team-item item-1'>
                  <div>
                    <CompUserAssignment userAssigned={userItem.user_id} />
                  </div>
                  <div className='fx-page-team-item-name-role'>
                    <div>{userItem.first_name} {userItem.last_name}</div>
                    <div><strong>Role: </strong>{userItem.role}</div>
                  </div>
                </div>
                <div className='fx-page-team-item item-2'>
                  <CompQuickActionsPopover
                    relatedObjectType="team-member"
                    relatedObjectId={userItem.user_id}
                    componentData={userItem}
                  />
                </div>
              </div>
            </ListItem>
            <Divider variant="inset" component="li" />
          </>
        ))}
      </List>
    </div>
  );
};

export default CompTeamMemberList;