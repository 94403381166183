import React, { useEffect, useState } from 'react';
import './CompCRUDFormUserPersona.css';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import CompModalButtons from './CompCRUDModalButtons';
import EditIcon from '@mui/icons-material/Edit';
import CompCRUDModalActionBar from './CompCRUDModalActionBar';
import { createUserPersona, updateUserPersona } from '../fxAPI/fxUserPersonasAPI.js';

const CompCRUDFormUserPersona = (props) => {

  const [currentEditableState, setCurrentEditableState] = useState('view');
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [userPersonaResponse, setUserPersonaResponse] = useState(null);
  const [updatePersonaName, setUpdatePersonaName] = useState(props.currentComponentData.name);
  const [updatePersonaDescription, setUpdatePersonaDescription] = useState(props.currentComponentData.description);

  console.log('current user persona data on form user persona: ');
  console.dir(props.currentComponentData);

  const triggerViewMode = (currentViewMode) => {
    setCurrentEditableState(currentViewMode);
  };

  const handleCloseModal = () => {
    props.onClose();
  };

  const handleCreateUserPersona = async (event) => {
    event.preventDefault();
    const related_org = localStorage.getItem("fxLocalOrgID");
    const relatedAvatar = '11111';
    await createUserPersona(name, description, relatedAvatar, related_org, setUserPersonaResponse);
    handleCloseModal(); // Close the modal after creating the user persona
  };

  const handleUpdatePersonaNameChange = (e) => {
    setUpdatePersonaName(e.target.value);
  }

  const handleUpdatePersonaDescriptionChange = (e) => {
    setUpdatePersonaDescription(e.target.value);
  }

  const handleUpdateUserPersona = async (e) => {
    console.log('ran update persona: ' + props.currentComponentData.objective_id);
    e.preventDefault();
    const relatedOrg = localStorage.getItem("fxLocalOrgID");
  
    //const projectId = props.currentComponentData.project_id; // Replace with the actual project ID
    const userPersonaId = props.currentComponentData.user_persona_id;
    const name = updatePersonaName;
    const description = updatePersonaDescription;
    const related_org = relatedOrg;
  
    await updateUserPersona(userPersonaId, name, description, null, related_org);
    handleCloseModal(); // Close the modal after updating the user persona
  };

  return (
    <div>
        {props.crudMethod === "create" &&
        <div>
            <br />
            <form onSubmit={handleCreateUserPersona}>
              <div>
                <TextField id="outlined-basic" label="User Persona Name (fictional)" variant="outlined" onChange={(e) => setName(e.target.value)} />
              </div>   
              <br />
              <div className="fx-global-form-text-area-description">
              <TextField
                id="outlined-multiline-flexible"
                label="Persona Description"
                multiline
                maxRows={4}
                onChange={(e) => setDescription(e.target.value)}
                fullWidth
                />  
              </div>
              <CompModalButtons>
                <Button type="submit">Create User Persona</Button>
              </CompModalButtons>         
            </form>
        </div>
        }
        {props.crudMethod === "view-edit" &&
          <div>
            <CompCRUDModalActionBar>
            { currentEditableState === "view" &&
              <Button onClick={() => triggerViewMode("edit")}><EditIcon /></Button>
            }
            { currentEditableState === "edit" &&
              <Button variant="outlined" onClick={() => triggerViewMode("view")}>Save</Button>
            }
          </CompCRUDModalActionBar>

          { currentEditableState === "view" &&
            <div>
              <div>
                <div className='fx-global-form-read-only-label'>User Persona Name</div>
                <div>{props.currentComponentData.name}</div>
              </div> 
              <div>
                <div className='fx-global-form-read-only-label'>User Persona Description</div>
                <div>{props.currentComponentData.description}</div>
              </div> 
            </div>
          } 

          { currentEditableState === "edit" &&
            <div>
              <form onSubmit={handleUpdateUserPersona}>
                <div>
                  <TextField id="outlined-basic" label="User Persona Name" variant="outlined" value={updatePersonaName} onChange={handleUpdatePersonaNameChange} />
                </div>   
                <br />
                <div className="fx-global-form-text-area-description">
                <TextField
                  id="outlined-multiline-flexible"
                  label="User Persona Description"
                  value={updatePersonaDescription}
                  onChange={handleUpdatePersonaDescriptionChange}
                  multiline
                  maxRows={4}
                  fullWidth
                  />  
                </div>
                <CompModalButtons>
                  <Button type='submit'>Save User Persona</Button>
                </CompModalButtons>         
              </form>
            </div>
          }     

          </div>
        }
    </div>
  );
};

export default CompCRUDFormUserPersona;