import React from 'react';
import './CompPageTempWebArticle.css';
import CompWebHeader from './CompWebHeader';
import CompWebFooter from './CompWebFooter';

const CompPageTempWebArticle = (props) => {
    return (
        <div>
            <CompWebHeader />
            {/* Component content goes here */}
            {props.splashImage}
            <h1>{props.heading}</h1>
            {props.children}
            <CompWebFooter />
        </div>
    );
};

export default CompPageTempWebArticle;
