import React, { useEffect, useState, useContext } from 'react';
import './CompCRUDFormBusinessValue.css';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import CompModalButtons from './CompCRUDModalButtons';
import EditIcon from '@mui/icons-material/Edit';
import CompCRUDModalActionBar from './CompCRUDModalActionBar';
import { createBusinessValue, updateBusinessValue } from '../fxAPI/fxBusinessValueAPI';
import { getProjectsByOrgId } from '../fxAPI/fxProjectsAPI.js';
import { PanelContext, refreshContext } from '../Contexts/PanelContext';
import { refreshMainLayoutContext } from './CompPageTempMaster3Panel';

const CompCRUDFormBusinessValue = (props) => {

  const [error, setError] = useState(null);

  const [currentEditableState, setCurrentEditableState] = useState('view');
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [businessValueResponse, setBusinessValueResponse] = useState(null);
  const [updateBusinessValueName, setUpdateBusinessValueName] = useState(props.currentComponentData.name);
  const [updateBusinessValueDescription, setUpdateBusinessValueDescription] = useState(props.currentComponentData.description);
  const [category, setCategory] = useState('');
  const [value, setValue] = useState('');
  const [relatedProject, setRelatedProject] = useState('');
  const [status, setStatus] = useState('');

  const { resetElementRefresh, triggerElementRefresh } = useContext(PanelContext);

  /* PROJECTS */
    const [projectsByOrgId, setProjectsByOrgData] = useState([]); // Initialize as an array
    /* get user personas by orgId */
    const fxAPIFetchProjectsByOrgId = async (orgId) => {
      await getProjectsByOrgId(orgId, setError, setProjectsByOrgData);
      console.log('all projects by org Id');
      console.dir(projectsByOrgId);
    };

  useEffect(() => {
    const related_org = localStorage.getItem("fxLocalOrgID");
    fxAPIFetchProjectsByOrgId(related_org);
  }, []);

  console.log('current business value data on form BusinessValue: ');
  console.dir(props.currentComponentData);

  const triggerViewMode = (currentViewMode) => {
    setCurrentEditableState(currentViewMode);
  };

  const handleCloseModal = () => {
    refreshContext(resetElementRefresh, triggerElementRefresh, 'mainLayout');
    refreshContext(resetElementRefresh, triggerElementRefresh, 'dashboard');
    props.onClose();
  };

  const handleCreateBusinessValue = async (event) => {
    event.preventDefault();
    const related_org = localStorage.getItem("fxLocalOrgID");
    await createBusinessValue(name, description, category, value, related_org, relatedProject, status, null);
    handleCloseModal(); // Close the modal after creating the business value
  };

  const handleUpdateBusinessValueNameChange = (e) => {
    setUpdateBusinessValueName(e.target.value);
  }

  const handleUpdateBusinessValueDescriptionChange = (e) => {
    setUpdateBusinessValueDescription(e.target.value);
  }

  const handleUpdateBusinessValue = async (e) => {
    console.log('ran update business value: ' + props.currentComponentData.business_value_id);
    e.preventDefault();
    const relatedOrg = localStorage.getItem("fxLocalOrgID");
  
    const businessValueId = props.currentComponentData.business_value_id;
    const updateName = updateBusinessValueName;
    const updateDescription = updateBusinessValueDescription;
    const related_org = relatedOrg;
    const related_project = props.relatedProject;
    const category = null;
    const value = null;
    const status = null;

    await updateBusinessValue(businessValueId, updateName, updateDescription, category, value, related_org, related_project, status);
    handleCloseModal(); // Close the modal after updating the business value
  };

  return (
    <div>
        {props.crudMethod === "create" &&
        <div>
            <br />
            <form onSubmit={handleCreateBusinessValue}>
              <div>
                <TextField id="outlined-basic" label="Business Value Name" variant="outlined" onChange={(e) => setName(e.target.value)} />
              </div>   
              <br />
              <div>
                <TextField id="outlined-basic" label="Category" variant="outlined" onChange={(e) => setCategory(e.target.value)} />
              </div>
              <br />
              <div>
                <TextField id="outlined-basic" label="Value" variant="outlined" onChange={(e) => setValue(e.target.value)} />
              </div>
              <br />
              <div>
                <label htmlFor="related-project">Related Project</label>
                <select
                  id="related-project"
                  value={relatedProject}
                  onChange={(e) => setRelatedProject(e.target.value)}
                  style={{ width: '100%', padding: '10px', borderRadius: '4px', borderColor: '#ccc' }}
                >
                  <option value="" disabled>Select Related Project</option>
                  {projectsByOrgId.map((project) => (
                    <option key={project.project_id} value={project.project_id}>{project.name}</option>
                  ))}
                </select>
              </div>
              <br />
              <div>
                <label htmlFor="status">Status</label>
                <select
                  id="status"
                  value={status}
                  onChange={(e) => setStatus(e.target.value)}
                  style={{ width: '100%', padding: '10px', borderRadius: '4px', borderColor: '#ccc' }}
                >
                  <option value="" disabled>Select Status</option>
                  <option value="Active">Active</option>
                  <option value="Inactive">Inactive</option>
                </select>
              </div>
              <br />
              <div className="fx-global-form-text-area-description">
              <TextField
                id="outlined-multiline-flexible"
                label="Business Value Description"
                multiline
                maxRows={4}
                onChange={(e) => setDescription(e.target.value)}
                fullWidth
                />  
              </div>
              <CompModalButtons>
                <Button type="submit">Create Business Value</Button>
                <Button type="button" onClick={handleCloseModal}>Cancel</Button>
              </CompModalButtons>         
            </form>
        </div>
        }
        {props.crudMethod === "view-edit" &&
          <div>
            <CompCRUDModalActionBar>
            { currentEditableState === "view" &&
              <Button onClick={() => triggerViewMode("edit")}><EditIcon /></Button>
            }
            { currentEditableState === "edit" &&
              <Button variant="outlined" onClick={() => triggerViewMode("view")}>Save</Button>
            }
          </CompCRUDModalActionBar>

          { currentEditableState === "view" &&
            <div>
              <div>
                <div className='fx-global-form-read-only-label'>Business Value Name</div>
                <div>{props.currentComponentData.name}</div>
              </div> 
              <div>
                <div className='fx-global-form-read-only-label'>Category</div>
                <div>{props.currentComponentData.category}</div>
              </div>
              <div>
                <div className='fx-global-form-read-only-label'>Value</div>
                <div>{props.currentComponentData.value}</div>
              </div>
              <div>
                <div className='fx-global-form-read-only-label'>Related Project</div>
                <div>{props.currentComponentData.related_project}</div>
              </div>
              <div>
                <div className='fx-global-form-read-only-label'>Status</div>
                <div>{props.currentComponentData.status}</div>
              </div>
              <div>
                <div className='fx-global-form-read-only-label'>Business Value Description</div>
                <div>{props.currentComponentData.description}</div>
              </div> 
            </div>
          } 

          { currentEditableState === "edit" &&
            <div>
              <form onSubmit={handleUpdateBusinessValue}>
                <div>
                  <TextField id="outlined-basic" label="Business Value Name" variant="outlined" value={updateBusinessValueName} onChange={handleUpdateBusinessValueNameChange} />
                </div>   
                <br />
                <div>
                  <TextField id="outlined-basic" label="Category" variant="outlined" value={category} onChange={(e) => setCategory(e.target.value)} />
                </div>
                <br />
                <div>
                  <TextField id="outlined-basic" label="Value" variant="outlined" value={value} onChange={(e) => setValue(e.target.value)} />
                </div>
                <br />
                <div>
                  <label htmlFor="related-project">Related Project</label>
                  <select
                    id="related-project"
                    value={relatedProject}
                    onChange={(e) => setRelatedProject(e.target.value)}
                    style={{ width: '100%', padding: '10px', borderRadius: '4px', borderColor: '#ccc' }}
                  >
                    <option value="" disabled>Select Related Project</option>
                    {projectsByOrgId.map((project) => (
                      <option key={project.id} value={project.id}>{project.name}</option>
                    ))}
                  </select>
                </div>
                <br />
                <div>
                  <label htmlFor="status">Status</label>
                  <select
                    id="status"
                    value={status}
                    onChange={(e) => setStatus(e.target.value)}
                    style={{ width: '100%', padding: '10px', borderRadius: '4px', borderColor: '#ccc' }}
                  >
                    <option value="" disabled>Select Status</option>
                    <option value="Active">Active</option>
                    <option value="Inactive">Inactive</option>
                  </select>
                </div>
                <br />
                <div className="fx-global-form-text-area-description">
                <TextField
                  id="outlined-multiline-flexible"
                  label="Business Value Description"
                  value={updateBusinessValueDescription}
                  onChange={handleUpdateBusinessValueDescriptionChange}
                  multiline
                  maxRows={4}
                  fullWidth
                  />  
                </div>
                <CompModalButtons>
                  <Button type='submit'>Save Business Value</Button>
                  <Button type="button" onClick={handleCloseModal}>Cancel</Button>
                </CompModalButtons>         
              </form>
            </div>
          }     

          </div>
        }
    </div>
  );
};

export default CompCRUDFormBusinessValue;