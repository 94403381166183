import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import './PageJourneyWizardImages.css';
import Box from '@mui/material/Box';
import CompPageTempMaster3Panel from '../Components/CompPageTempMaster3Panel';
import CompContentBlock from '../Components/CompContentBlock';
import CompFeatureCarousel from '../Components/CompFeatureCarousel';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField'; // Import TextField
import Tabs from '@mui/material/Tabs'; // Import Tabs
import Tab from '@mui/material/Tab'; // Import Tab
import Select from '@mui/material/Select'; // Import Select
import MenuItem from '@mui/material/MenuItem'; // Import MenuItem
import axios from 'axios';
import CompPageTempWizard from '../Components/CompPageTempWizard';
import { createPath } from '../fxAPI/fxPathsAPI.js';
import CompUIAssetBulkProcessor from '../Components/CompUIAssetBulkProcessor';
import { getProjectsByOrgId, createProject } from '../fxAPI/fxProjectsAPI.js'; // Import getProjectsByOrgId and createProject
import Typography from '@mui/material/Typography'; // Import Typography
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Checkbox from '@mui/material/Checkbox';
import { getUserPersonasByOrgId, createUserPersona } from '../fxAPI/fxUserPersonasAPI.js'; // Import getUserPersonasByOrgId and createUserPersona
import CompCollapsibleContainer from '../Components/CompCollapsibleContainer'; // Import CompCollapsibleContainer

const steps = ['Playbook and Journey Name', 'Create or Modify User Personas', 'Choose Images for Screens'];

const PageJourneyWizardImages = (props) => {

  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());
  const [personaOption, setPersonaOption] = useState('current'); // State for persona option
  const [userPersonas, setUserPersonas] = useState([]); // State for user personas
  const [selectedPersonas, setSelectedPersonas] = useState([]); // State for selected personas
  const [newPersonaName, setNewPersonaName] = useState(''); // State for new persona name
  const [newPersonaDescription, setNewPersonaDescription] = useState(''); // State for new persona description
  const [isPersonaReadOnly, setIsPersonaReadOnly] = useState(false); // State to toggle read-only mode for personas
  const [newPersonas, setNewPersonas] = useState([{ name: '', description: '' }]); // State for new personas list

  const isStepOptional = (step) => {
    return step === 1;
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = async () => {
    if (activeStep === 0) {
      if ((useNewPlaybook && !newPlaybook) || (!useNewPlaybook && !playbook) || !journeyName) {
        alert("Please provide both playbook name and journey name.");
        return;
      }
      await handleBuildJourney(); // Call handleBuildJourney to set journeyID
    }

    if (activeStep === 1) {
      if (personaOption === 'unknown') {
        const unknownUser = { user_persona_id: generateUUID(), name: 'Unknown User' };
        setUserPersonas([unknownUser]);
      }
    }

    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    if (activeStep > 1) {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const [error, setError] = useState(null);
  const [journeyName, setJourneyName] = useState(''); // State for journey name
  const [tabValue, setTabValue] = useState(0); // State for selected tab
  const [journeyID, setJourneyID] = useState(null); // State for journey ID
  const [playbook, setPlaybook] = useState(''); // State for playbook
  const [newPlaybook, setNewPlaybook] = useState(''); // State for new playbook
  const [useNewPlaybook, setUseNewPlaybook] = useState(false); // State to toggle between existing and new playbook
  const [projects, setProjects] = useState([]); // State for projects
  const [selectedProjectId, setSelectedProjectId] = useState(''); // State for selected project ID
  const [projectResponse, setProjectResponse] = useState(null); // State for project response
  const [isReadOnly, setIsReadOnly] = useState(false); // State to toggle read-only mode

  const handleJourneyNameChange = (event) => {
    setJourneyName(event.target.value);
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handlePlaybookChange = (event) => {
    setSelectedProjectId(event.target.value);
    setPlaybook(event.target.value);
  };

  const handleNewPlaybookChange = (event) => {
    setNewPlaybook(event.target.value);
  };

  const handleTogglePlaybook = () => {
    setUseNewPlaybook(!useNewPlaybook);
  };

  const handlePersonaOptionChange = (event) => {
    setPersonaOption(event.target.value);
  };

  const handlePersonaCheckboxChange = (event) => {
    const { value, checked, name } = event.target;
    setSelectedPersonas((prevSelected) => {
      const updatedSelection = checked
        ? [...prevSelected, { user_persona_id: value, name }]
        : prevSelected.filter((persona) => persona.user_persona_id !== value);
      console.log('Updated selected personas:', updatedSelection);
      return updatedSelection;
    });
  };

  const handleNewPersonaNameChange = (event) => {
    setNewPersonaName(event.target.value);
  };

  const handleNewPersonaDescriptionChange = (event) => {
    setNewPersonaDescription(event.target.value);
  };

  const handleSaveNewPersona = async () => {
    try {
      const relatedOrg = localStorage.getItem("fxLocalOrgID");
      const relatedAvatar = ''; // Add logic to set related avatar if needed
      const response = await createUserPersona(newPersonaName, newPersonaDescription, relatedAvatar, relatedOrg, (data) => {
        setUserPersonas((prevPersonas) => [...prevPersonas, data]);
      });
      setIsPersonaReadOnly(true); // Set read-only mode
    } catch (error) {
      console.error('Error creating new user persona:', error);
    }
  };

  const handleNewPersonaChange = (index, field, value) => {
    const updatedPersonas = [...newPersonas];
    updatedPersonas[index][field] = value;
    setNewPersonas(updatedPersonas);
  };

  const handleAddMorePersona = () => {
    setNewPersonas([...newPersonas, { name: '', description: '' }]);
  };

  const handleSaveNewPersonas = async () => {
    try {
      const relatedOrg = localStorage.getItem("fxLocalOrgID");
      const relatedAvatar = ''; // Add logic to set related avatar if needed

      const savedPersonas = [];
      for (const persona of newPersonas) {
        const userPersonaId = generateUUID();
        const response = await axios.post('/fxapi/user-personas/create', {
          user_persona_id: userPersonaId,
          name: persona.name,
          description: "no description",
          related_avatar: relatedAvatar,
          related_org: relatedOrg
        });
        savedPersonas.push(response.data);
      }

      console.log('Saved new personas:', savedPersonas); // Log saved personas
      setIsPersonaReadOnly(true); // Set read-only mode
      setNewPersonas([{ name: '', description: '' }]); // Reset new personas list
      setUserPersonas((prevPersonas) => [...prevPersonas, ...savedPersonas]); // Update the state with new personas
    } catch (error) {
      console.error('Error creating new user personas:', error);
      // Continue with the refresh even if there is an error
      setUserPersonas((prevPersonas) => [...prevPersonas, ...newPersonas.map(persona => ({
        user_persona_id: generateUUID(),
        name: persona.name,
        description: "no description",
        related_avatar: '',
        related_org: localStorage.getItem("fxLocalOrgID")
      }))]);
    }
  };

  const fetchProjects = async () => {
    const orgId = localStorage.getItem("fxLocalOrgID");
    await getProjectsByOrgId(orgId, setError, setProjects);
  };

  const fetchUserPersonas = async () => {
    const orgId = localStorage.getItem("fxLocalOrgID");
    await getUserPersonasByOrgId(orgId, setError, setUserPersonas);
  };

  useEffect(() => {
    fetchProjects();
    setUserPersonas([]); // Clear userPersonas state on initial load
  }, []);

  useEffect(() => {
    if (personaOption === 'current') {
      fetchUserPersonas();
    }
  }, [personaOption]);

  useEffect(() => {
    if (activeStep === 1) {
      fetchUserPersonas(); // Fetch user personas when step 2 is active
    }
  }, [activeStep]);

  const generateUUID = () => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      const r = (Math.random() * 16) | 0, v = c === 'x' ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  };

  const handleBuildJourney = async () => {
    try {
      const relatedOrg = localStorage.getItem("fxLocalOrgID");
      let projectId = selectedProjectId;

      if (useNewPlaybook && newPlaybook) {
        projectId = generateUUID();
        await createProject(newPlaybook, 'Description here', relatedOrg, setProjectResponse, projectId);
      }

      const pathId = generateUUID(); // Generate pathId
      const response = await createPath(pathId, journeyName, null, null, null, null, relatedOrg, projectId);
      setJourneyID(pathId);
      setIsReadOnly(true); // Set read-only mode
    } catch (error) {
      console.error('Error creating journey path:', error);
    }
  };

  const journeyWizardStepOneContent = () => {
    return (
      <div>
        <h2>Playbook and Journey Name</h2>
        {isReadOnly ? (
          <Typography variant="h6">{useNewPlaybook ? newPlaybook : projects.find(project => project.project_id === playbook)?.name}</Typography>
        ) : (
          <>
            <Button variant="outlined" onClick={handleTogglePlaybook} style={{ backgroundColor: 'white' }}>
              {useNewPlaybook ? 'Select Existing Playbook' : 'Create New Playbook'}
            </Button>
            {useNewPlaybook ? (
              <TextField
                label="New Playbook Name"
                variant="outlined"
                fullWidth
                value={newPlaybook}
                onChange={handleNewPlaybookChange}
                margin="normal"
              />
            ) : (
              <Select
                value={playbook}
                onChange={handlePlaybookChange}
                displayEmpty
                fullWidth
                margin="normal"
              >
                <MenuItem value="">
                  <em>Select Playbook</em>
                </MenuItem>
                {projects.map((project) => (
                  <MenuItem key={project.project_id} value={project.project_id}>
                    {project.name}
                  </MenuItem>
                ))}
              </Select>
            )}
          </>
        )}

        <div>
          <h2>Name Your Journey</h2>
          {isReadOnly ? (
            <Typography variant="h6">{journeyName}</Typography>
          ) : (
            <TextField
              label="Journey Name"
              variant="outlined"
              fullWidth
              value={journeyName}
              onChange={handleJourneyNameChange}
              margin="normal"
              InputLabelProps={{
                style: { color: 'transparent' }, // Make the label transparent
                shrink: false, // Prevent the label from floating
              }}
            />
          )}
        </div>
      </div>
    );
  }

  const journeyWizardStepTwoContent = () => {
    return (
      <div>
        <h2>Create or Modify User Personas</h2>
        {userPersonas.length === 0 ? (
          <div>
            <Typography variant="h6">No User Personas</Typography>
            {isPersonaReadOnly ? (
              <div>
                {userPersonas.map((persona) => (
                  <Typography key={persona.user_persona_id} variant="h6">{persona.name}</Typography>
                ))}
              </div>
            ) : (
              <div>
                {newPersonas.map((persona, index) => (
                  <div key={index}>
                    <TextField
                      label="New Persona Name"
                      variant="outlined"
                      fullWidth
                      value={persona.name}
                      onChange={(e) => handleNewPersonaChange(index, 'name', e.target.value)}
                      margin="normal"
                      InputLabelProps={{
                        style: { color: 'white' }, // Set label color to white
                        shrink: true, // Keep the label fixed above
                      }}
                    />
                  </div>
                ))}
                <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px' }}>
                  <Button variant="outlined" color="primary" onClick={handleAddMorePersona} style={{ backgroundColor: 'white' }}>
                    Add More
                  </Button>
                  <Button variant="outlined" color="primary" onClick={handleSaveNewPersonas} style={{ backgroundColor: 'white' }}>
                    Save
                  </Button>
                </div>
              </div>
            )}
          </div>
        ) : (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <h3>Current User Personas</h3>
            {userPersonas.map((persona) => (
              <Typography key={persona.user_persona_id} variant="h6">{persona.name}</Typography>
            ))}
            {!isPersonaReadOnly && (
              <div>
                {newPersonas.map((persona, index) => (
                  <div key={index}>
                    <TextField
                      label="New Persona Name"
                      variant="outlined"
                      fullWidth
                      value={persona.name}
                      onChange={(e) => handleNewPersonaChange(index, 'name', e.target.value)}
                      margin="normal"
                      InputLabelProps={{
                        style: { color: 'white' }, // Set label color to white
                        shrink: true, // Keep the label fixed above
                      }}
                    />
                  </div>
                ))}
                <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px' }}>
                  <Button variant="outlined" color="primary" onClick={handleAddMorePersona} style={{ backgroundColor: 'white' }}>
                    Add More
                  </Button>
                  <Button variant="outlined" color="primary" onClick={handleSaveNewPersonas} style={{ backgroundColor: 'white' }}>
                    Save
                  </Button>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    );
  }

  const journeyWizardStepThreeContent = () => {
    return (
      <div>
        {journeyID && (
          <div>
            <h2>Choose Images for Screens</h2>
              <div>
                {/* Content for Build from Images */}
                <h3>Build from Images</h3>
                <p>Upload images to build your journey map.</p>
                <div>debug journey ID: {journeyID}</div>
                <CompUIAssetBulkProcessor context="journey-wizard" journeyID={journeyID} relatedProject={newPlaybook || playbook} />
                {/* Add more content as needed */}
              </div>
          </div>
        )}
      </div>
    );
  }

  return (
    <CompPageTempWizard headingOne="Journey Map Wizard">
      <Box sx={{ width: '100%' }}>
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((label, index) => {
          const stepProps = {};
          const labelProps = { style: { color: 'white' } }; // Set label color to white
          if (isStepOptional(index)) {
            labelProps.optional = (
              <Typography variant="caption" style={{ color: 'white' }}>Optional</Typography>
            );
          }
          if (isStepSkipped(index)) {
            stepProps.completed = false;
          }
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps} style={{ color: 'white' }}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      {activeStep === steps.length ? (
        <React.Fragment>
          <Typography sx={{ mt: 2, mb: 1 }}>
            All steps completed - you&apos;re finished
          </Typography>
          <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
            <Box sx={{ flex: '1 1 auto' }} />
            <Button onClick={handleReset}>Reset</Button>
          </Box>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <Typography sx={{ mt: 2, mb: 1 }}>Step {activeStep + 1}</Typography>
          <div>
            {activeStep === 0 && journeyWizardStepOneContent()}
            {activeStep === 1 && journeyWizardStepTwoContent()}
            {activeStep === 2 && journeyWizardStepThreeContent()}
          </div>
          <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
            <Button
              color="inherit"
              disabled={activeStep <= 1}
              onClick={handleBack}
              sx={{ mr: 1 }}
            >
              Back
            </Button>
            <Box sx={{ flex: '1 1 auto' }} />
            {isStepOptional(activeStep) && (
              <Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
                Skip
              </Button>
            )}
            <Button onClick={handleNext}>
              {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
            </Button>
          </Box>
        </React.Fragment>
      )}
    </Box>
      

    </CompPageTempWizard>
  );
}

export default PageJourneyWizardImages;