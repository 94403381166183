import React, { useEffect, useState, useContext } from 'react';
import { useParams } from "react-router-dom";
import Button from '@mui/material/Button';
import './PageInviteLanding.css';
import { useAuth0 } from "@auth0/auth0-react";
import CompPageTempIntroSlide from '../Components/CompPageTempIntroSlide';


const PageInviteLanding = (props) => {

    const LoginButton = () => {
        const { loginWithRedirect } = useAuth0();
        return <Button variant="contained" onClick={() => loginWithRedirect()}>Create Account</Button>;
      };
  
    const panelLeftPanel = (
        <>
          <div className="fx-comp-startsetup-step-container-inner">
            <div className="fx-comp-start-setup-step-left-panel-body">
                Your organization has started your account.  Create a login with the same email to get started.  
            </div>
          </div>
        </>
      );

      const panelRightPanel = (
        <>
          <div className="fx-comp-startsetup-step-container-inner">
            <div className="fx-comp-startsetup-pending-org-message-wrap">
                <div>Create login credentials now.</div>
                <div><LoginButton /></div>
            </div>
          </div>
        </>
      );

  return (
    <CompPageTempIntroSlide headingOne="You've Been Invited to Join an Organization" panelLeftContent={panelLeftPanel} panelRightContent={panelRightPanel} />
  );
};

export default PageInviteLanding;