import React, { useState, useEffect } from 'react';
import './CompCollapsibleContainer.css';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


const CompCollapsibleContainer = (props) => {
  const [isContentLoaded, setIsContentLoaded] = useState(!props.deferLoading);

  useEffect(() => {
    if (props.expanded && props.deferLoading) {
      setIsContentLoaded(true);
    }
  }, [props.expanded, props.deferLoading]);

  const accordionStyle = props.containerStyle === 'dark' ? {
    backgroundColor: 'var(--uxc-color-blue-dark)',
    color: '#fff',
  } : {};

  const expandIconStyle = props.containerStyle === 'dark' ? { color: '#fff' } : {};

  return (
    <div>
      <Accordion 
        style={accordionStyle} 
        expanded={props.expanded} 
        onChange={(event, isExpanded) => {
          if (event.target.closest('.MuiAccordionSummary-expandIconWrapper') && typeof props.onChange === 'function') {
            props.onChange(event, isExpanded);
          }
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon style={expandIconStyle} />}
          aria-controls="panel1-content"
          id="panel1-header"
          sx={{
            flexDirection: props.arrowPosition === 'right' ? 'row-reverse' : 'row',
            '& .MuiAccordionSummary-expandIconWrapper': {
              order: props.arrowPosition === 'right' ? 1 : 0,
              marginRight: props.arrowPosition === 'right' ? 0 : 'auto',
              marginLeft: props.arrowPosition === 'right' ? 'auto' : 0,
            },
          }}
        >
          <div className='fx-comp-collapsible-container-heading-row'>
            <div className='fx-comp-collapsible-container-heading-row-item-heading'>{props.containerHeading}</div>
            <div className='fx-comp-collapsible-container-heading-row-item-actions'>
              {props.itemCount > 0 && 
                <div className='fx-comp-collapsible-container-item-count-wrap'>{props.itemCount}</div>
              }
              {props.actionSet &&
                <div className='fx-comp-collapsible-container-action-set'>{props.actionSet}</div>
              }
            </div>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          {isContentLoaded ? props.children : null}
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default CompCollapsibleContainer;