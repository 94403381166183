import React from 'react';
import './CompWebHeader.css';
import FluxityLogo from '../images/Fluxity_Logo.svg';
import Button from '@mui/material/Button';
import CompWebMobileMenu from './CompWebMobileMenu';
import { useAuth0 } from "@auth0/auth0-react";
import { v4 as uuidv4 } from 'uuid'; // Import UUID library for generating unique state

const CompWebHeader = () => {

  const { isLoading, isAuthenticated, user, loginWithRedirect, logout } = useAuth0();

  const handleLogin = async () => {
    try {
      const state = uuidv4(); // Generate a unique state parameter
      sessionStorage.setItem('auth_state', state); // Store state parameter in session storage
      await loginWithRedirect({ state }); // Pass state parameter to loginWithRedirect
    } catch (error) {
      console.error('Error during login:', error);
      // Optionally, you can show a message to the user
    }
  };

  const LoginButton = () => {
    return <Button variant="contained" onClick={handleLogin}>Log In or Sign Up</Button>;
  };
  
  return (
    <div className="fx-page-start-unauthenticated-header-container">
                <div className="fx-page-start-unauthenticated-header-logo-item">
                  <img width="auto" height="70px" alt="logo" src={FluxityLogo} />
                </div>
                <div className="fx-page-start-unauthenticated-header-login-item">
                  <LoginButton />
                  <CompWebMobileMenu />
                </div>
              </div>
  );
};

export default CompWebHeader;