import React, { useState, useEffect } from 'react';
import './CompComponentDashboard.css';
import { Button } from '@mui/material';
import { getComponentsByOrgAndCategory } from '../fxAPI/fxComponentsAPI';
import CompCRUDModal from './CompCRUDModal';
import CompCollapsibleContainer from './CompCollapsibleContainer';
import CompComponentDashboardItem from './CompComponentDashboardItem';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import CompUIAssetBulkProcessor from './CompUIAssetBulkProcessor';
import CompComponentUseMatrix from './CompComponentUseMatrix';

const CompComponentDashboard = () => {
  const storedLocalOrgConfig = localStorage.getItem('fxLocalOrgConfig');
  const parsedOrgConfig = storedLocalOrgConfig ? JSON.parse(storedLocalOrgConfig) : null;
  console.log('parsed categories: ');
  console.dir(parsedOrgConfig.componentCategories);

  const [componentsByCategory, setComponentsByCategory] = useState({});
  const [error, setError] = useState({});
  const [isOpen, setIsOpen] = useState(false);

  const [tabValue, setTabValue] = useState(0);
      
      const handleTabChange = (event, newValue) => {
          setTabValue(newValue);
      };

  // Fetch Components for All Categories
  const fetchComponents = async (orgId, categories) => {
    if (categories && categories.length > 0) {
      categories.forEach((category) => {
        getComponentsByOrgAndCategory(
          orgId,
          category.id,
          (errorMsg) =>
            setError((prev) => ({
              ...prev,
              [category.id]: errorMsg,
            })),
          (componentsData) => {
            const filteredComponents = componentsData.filter(
              (component) => component.config_cat === category.id
            );
            setComponentsByCategory((prev) => ({
              ...prev,
              [category.id]: filteredComponents,
            }));
          }
        );
      });
    }
  };

  useEffect(() => {
    const currentOrgId = localStorage.getItem('fxLocalOrgID');
    if (currentOrgId && parsedOrgConfig?.componentCategories?.length > 0) {
      fetchComponents(currentOrgId, parsedOrgConfig.componentCategories);
    }
  }, [JSON.stringify(parsedOrgConfig?.componentCategories)]); // Serialize dependency

  const openModal = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);

  const componentCategories = parsedOrgConfig?.componentCategories || [];

  return (
    <div>
      <CompCRUDModal
        isOpen={isOpen}
        onClose={closeModal}
        modalTitle="Create Modal"
        itemType="component"
        crudMethod="create"
        modalComponentData=""
      />
      <div className="fx-comp-component-dashboard-tools">
        <Button variant="outlined" onClick={openModal}>Create Component</Button>
      </div>
      <Tabs value={tabValue} onChange={handleTabChange}>
            <Tab label="Component List" />
            <Tab label="Component Images" />
            <Tab label="Component Use Matrix" />
            {/*<Tab label="Accessibility Tests" />*/}      
      </Tabs>
      {tabValue === 0 && (
      <div>
        <h2>Components By Category</h2>
          {/* Dynamic Category Rendering */}
          {componentCategories.map((category) => {
            // Calculate the number of components for this category
            const componentsCount = componentsByCategory[category.id]?.length || 0;

            return (
              <div key={category.id}>
                <CompCollapsibleContainer
                  containerHeading={category.name} // Add the count to the heading
                  itemCount={componentsCount}
                >
                  <h3>About this Category: </h3>
                  <div>{category.description}</div>
                    {error?.[category.id] ? (
                      <li>Error: {error[category.id]}</li>
                    ) : componentsByCategory[category.id] ? (
                      componentsByCategory[category.id].map((component) => (
                        <>
                        {/* 
                        <div key={component.component_id}>
                          <strong>{component.name}</strong> (v{component.version})
                          <p>Component Description: {component.description}</p>
                        </div>
                        */}
                        <CompComponentDashboardItem componentId={component.component_id} componentImage={component.related_img} componentName={component.name} componentDescription={component.description} componentVersion={component.version} />
                        </>
                      ))
                    ) : (
                      <div>Loading components...</div>
                    )}
                </CompCollapsibleContainer>
              </div>
            );
          })}
      </div>
      )}
      {tabValue === 1 && (
        <div>
          <CompUIAssetBulkProcessor context="component" />
        </div>
      )}
      {tabValue === 2 && (
        <div>
          <CompComponentUseMatrix orgId="11111" projectId="cc8cf449-9b54-4b50-9ec4-87f49e5a4a23" />
        </div>
      )}
    </div>
  );
};

export default CompComponentDashboard;
