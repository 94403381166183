import axios from 'axios';

const getObjectivesByOrgId = async (orgId, setError, setObjectivesData) => {
    setError(null);
    setObjectivesData([]); // Reset objectives data to an empty array

    if (!orgId) {
      setError('OrgId parameter is required');
      return;
    }

    try {
      console.log(`Fetching objective data for orgId: ${orgId}`);
      const response = await axios.get('/fxapi/objectives/objectives-by-org', {
        params: { orgId }
      });
      console.log('API response received:', response.data.items);

      const items = Array.isArray(response.data.items) ? response.data.items : [];
      console.log('Setting objectives data:', items);
      setObjectivesData(items); // Correctly update the state with received data
      console.log('Updated objective data:', items);
    } catch (error) {
      console.error('Error fetching objective data:', error);
      setError(error.response ? error.response.data : 'Failed to fetch objectives data');
    }
};

const getObjectivesByBusinessValueId = async (businessValueId, setError, setObjectivesByBusinessValueData) => {
  setError(null);
  setObjectivesByBusinessValueData([]); // Reset objectives data to an empty array

  if (!businessValueId) {
    setError('businessValueId parameter is required');
    return;
  }

  try {
    console.log(`Fetching objective data for businessValueId: ${businessValueId}`);
    const response = await axios.get('/fxapi/objectives/objectives-by-business-value', {
      params: { businessValueId }
    });
    console.log('API response received:', response.data.items);

    const items = Array.isArray(response.data.items) ? response.data.items : [];
    console.log('Setting objectives by business value data:', items);
    setObjectivesByBusinessValueData(items); // Correctly update the state with received data
    console.log('Updated objectives by business value data:', items);
  } catch (error) {
    console.error('Error fetching objectives by business value data:', error);
    setError(error.response ? error.response.data : 'Failed to fetch objectives by business value data');
  }
};

const getObjectivesByProjectIdAndAnchorId = async (projectId, anchorId, setError, setObjectivesByProjectAndAnchorData) => {
  setError(null);
  setObjectivesByProjectAndAnchorData([]); // Reset objectives data to an empty array

  if (!projectId) {
    setError('ProjectId parameter is required');
    return;
  }

  try {
    console.log(`Fetching objective data for projectId: ${projectId} and anchorId: ${anchorId}`);
    const response = await axios.get('/fxapi/objectives/objectives-by-project-and-anchor', {
      params: { projectId, anchorId }
    });
    console.log('API response received:', response.data.items);

    const items = Array.isArray(response.data.items) ? response.data.items : [];
    console.log('Setting objectives by anchor and project data:', items);
    setObjectivesByProjectAndAnchorData(items); // Correctly update the state with received data
    console.log('Updated objective data:', items);
  } catch (error) {
    console.error('Error fetching objectives by anchor and project data:', error);
    setError(error.response ? error.response.data : 'Failed to fetch objectives by anchor and project data');
  }
};

const getViewObjectivesProjectsByOrgId = async (orgId, setError, setViewObjectivesProjectsByOrgData) => {
  setError(null);
  setViewObjectivesProjectsByOrgData([]); // Reset objectives data to an empty array

  if (!orgId) {
    setError('OrgId parameter is required');
    return;
  }

  try {
    console.log(`Fetching objective data for orgId: ${orgId}`);
    const response = await axios.get('/fxapi/objectives/view-objectives-projects-by-org', {
      params: { orgId }
    });
    console.log('API response received:', response.data.items);

    const items = Array.isArray(response.data.items) ? response.data.items : [];
    console.log('Setting view objectives projects by org id data:', items);
    setViewObjectivesProjectsByOrgData(items); // Correctly update the state with received data
    console.log('Updated view objectives projects by org id data:', items);
  } catch (error) {
    console.error('Error fetching view objectives projects by org id data:', error);
    setError(error.response ? error.response.data : 'Failed to fetch objectives data');
  }
};


const getViewObjectivesProjectsByProjectId = async (projectId, setError, setViewObjectivesProjectsByProjectData) => {
  setError(null);
  setViewObjectivesProjectsByProjectData([]); // Reset objectives data to an empty array

  if (!projectId) {
    setError('projectId parameter is required');
    return;
  }

  try {
    console.log(`Fetching objective data for projectId: ${projectId}`);
    const response = await axios.get('/fxapi/objectives/view-objectives-projects-by-project', {
      params: { projectId }
    });
    console.log('API response received:', response.data.items);

    const items = Array.isArray(response.data.items) ? response.data.items : [];
    console.log('Setting view objectives projects by project id data:', items);
    setViewObjectivesProjectsByProjectData(items); // Correctly update the state with received data
    console.log('Updated view objectives projects by project id data:', items);
  } catch (error) {
    console.error('Error fetching view objectives projects by project id data:', error);
    setError(error.response ? error.response.data : 'Failed to fetch objectives data');
  }
};

const createObjective = async (objectiveNewId, objectiveDataName, objectiveDataDescription, objectiveDataRelatedOrg, objectiveDataRelatedProject, objectiveDataProgress, objectiveRelatedAnchor, objectiveRelatedMilestone, objectiveRelatedBusinessValue, objectiveDateDue) => {
  const setError = (message) => {
    console.error(message);
  };

  const setSuccessMessage = (message) => {
    console.log(message);
  };

  setError(null);
  setSuccessMessage(null);

  const objectiveId = objectiveNewId;
  const name = objectiveDataName;
  const description = objectiveDataDescription;
  const relatedOrg = objectiveDataRelatedOrg;
  const relatedProject = objectiveDataRelatedProject;
  const progress = objectiveDataProgress;
  const relatedAnchor = objectiveRelatedAnchor;
  const relatedMilestone = objectiveRelatedMilestone;
  const relatedBusinessValue = objectiveRelatedBusinessValue;
  const dateDue = objectiveDateDue;

  if (!name || !relatedOrg) {
    setError('Objective data with valid name and related organization is required');
    return;
  }

  try {
    console.log(`Creating new objective with name: ${name} and related organization: ${relatedOrg}`);

    const response = await axios.post('/fxapi/objectives/create', {
      objectiveId,
      name,
      description,
      related_org: relatedOrg, // Correct variable name
      related_project: relatedProject, // Correct variable name
      progress,
      related_anchor: relatedAnchor,
      related_milestone: relatedMilestone,
      related_business_value: relatedBusinessValue,
      dateDue
    });

    console.log('API response received:', response.data);
    setSuccessMessage('Objective created successfully');
  } catch (error) {
    console.error('Error creating objective:', error);
    setError(error.response ? error.response.data : 'Failed to create objective');
  }
};

const getObjectivesByProjectId = async (projectId, setError, setObjectivesByProjectData) => {
  setError(null);
  setObjectivesByProjectData([]); // Reset Objectives data to an empty array

  if (!projectId) {
    setError('ProjectId parameter is required');
    return;
  }

  try {
    console.log(`Fetching objective data for projectId: ${projectId}`);
    const response = await axios.get('/fxapi/objectives/objectives-by-project', {
      params: { projectId }
    });
    console.log('API response received:', response.data.items);

    const items = Array.isArray(response.data.items) ? response.data.items : [];
    console.log('Setting objectives data:', items);
    setObjectivesByProjectData(items); // Correctly update the state with received data
    console.log('Updated objective data:', items);
  } catch (error) {
    console.error('Error fetching objective data:', error);
    setError(error.response ? error.response.data : 'Failed to fetch objectives data');
  }
};

const updateObjective  = async (objectiveId, name, description, related_org, related_anchor, related_milestone, related_business_value, progress, date_due) => {

  try {
    const response = await axios.put(`/fxapi/objectives/update/${objectiveId}`, {
      objective_id: objectiveId,
      name: name,
      description: description,
      related_org: related_org,
      related_anchor: related_anchor,
      related_milestone: related_milestone,
      related_business_value: related_business_value,
      progress: progress,
      date_due
    });

    console.log('Response:', response.data);
  } catch (error) {
    console.error('Error:', error.response ? error.response.data : error.message);
  }
};

const deleteObjective = async (objectiveId, setError, setDeleteResponse) => {
  setError(null);
  setDeleteResponse(null); // Reset delete response

  if (!objectiveId) {
    setError('Objective ID parameter is required');
    return;
  }

  try {
    console.log(`Deleting objective with ID: ${objectiveId}`);
    const response = await axios.delete(`/fxapi/objectives/delete/${objectiveId}`);
    console.log('API response received:', response.data);

    // Convert response data to plain text
    const successMessage = `Successfully deleted objective with ID: ${objectiveId}`;
    setDeleteResponse(successMessage); // Update the state with plain text
    console.log('Updated delete response:', successMessage);
  } catch (error) {
    console.error('Error deleting objective:', error);

    // Convert error to plain text
    const errorMessage = error.response ? error.response.data : 'Failed to delete objective';
    setError(typeof errorMessage === 'string' ? errorMessage : JSON.stringify(errorMessage));
  }
};

const getObjectivesByMilestoneId = async (milestoneId, setError, setObjectivesByMilestoneData) => {
  setError(null);
  setObjectivesByMilestoneData([]); // Reset objectives data to an empty array

  if (!milestoneId) {
    setError('MilestoneId parameter is required');
    return;
  }

  try {
    console.log(`Fetching objective data for milestoneId: ${milestoneId}`);
    const response = await axios.get('/fxapi/objectives/objectives-by-milestone', {
      params: { milestoneId }
    });
    console.log('API response received:', response.data.items);

    const items = Array.isArray(response.data.items) ? response.data.items : [];
    console.log('Setting objectives by milestone data:', items);
    setObjectivesByMilestoneData(items); // Correctly update the state with received data
    console.log('Updated objectives by milestone data:', items);
  } catch (error) {
    console.error('Error fetching objectives by milestone data:', error);
    setError(error.response ? error.response.data : 'Failed to fetch objectives by milestone data');
  }
};

// ----------------------

// Helper function to generate a UUID
function generateUUID() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    const r = (Math.random() * 16) | 0, v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

export { 
  getObjectivesByOrgId, 
  getViewObjectivesProjectsByOrgId, 
  getObjectivesByBusinessValueId, 
  createObjective, 
  getObjectivesByProjectId, 
  getObjectivesByProjectIdAndAnchorId, 
  getViewObjectivesProjectsByProjectId, 
  deleteObjective, 
  updateObjective,
  getObjectivesByMilestoneId // Add the new function to the export list
};