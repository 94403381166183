import React, { useState, useEffect } from 'react';
import { Radio, RadioGroup, FormControlLabel, FormControl, FormLabel, TextField, Button, Select, MenuItem } from '@mui/material';
import { createAnchor, getAnchorsByProjectId } from '../fxAPI/fxAnchorsAPI';
import { createObjectLink } from '../fxAPI/fxObjectLinksAPI';

function generateUUID() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      const r = (Math.random() * 16) | 0, v = c === 'x' ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
}

const CompCRUDFormAnchorJourney = (props) => {
    console.log('current project on journey map: ' + localStorage.getItem("fxCurrentProject"));
    const [anchorOption, setAnchorOption] = useState('new');
    const [anchorName, setAnchorName] = useState('');
    const [existingAnchors, setExistingAnchors] = useState([]);
    const [selectedAnchor, setSelectedAnchor] = useState('');
    const currentOrgId = localStorage.getItem("fxLocalOrgID");
    const project = localStorage.getItem("fxCurrentProject");

    useEffect(() => {
        const fetchAnchors = async () => {
            const anchors = await getAnchorsByProjectId(project, (error) => console.error(error), setExistingAnchors);
            setExistingAnchors(anchors);
        };
        fetchAnchors();
    }, [project]);

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (anchorOption === 'new') {
            const newAnchorId = generateUUID();
            await createAnchor(newAnchorId, anchorName, project, currentOrgId, null, null, null, async (response) => {
                console.log('Anchor created:', response);
            });
            await createObjectLink(newAnchorId, 'anchor', props.relatedPath, 'path', null, project, currentOrgId);
        } else {
            await createObjectLink(selectedAnchor, 'anchor', props.relatedPath, 'path', null, project, currentOrgId);
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <FormControl component="fieldset">
                <FormLabel component="legend">Step Option</FormLabel>
                <RadioGroup
                    aria-label="anchor-option"
                    name="anchorOption"
                    value={anchorOption}
                    onChange={(e) => setAnchorOption(e.target.value)}
                >
                    <FormControlLabel value="new" control={<Radio />} label="Create New Step" />
                    <FormControlLabel value="existing" control={<Radio />} label="Use Existing Step" />
                </RadioGroup>
            </FormControl>

            {anchorOption === 'new' ? (
                <div>
                    <TextField
                        label="Anchor Name"
                        value={anchorName}
                        onChange={(e) => setAnchorName(e.target.value)}
                        required
                    />
                </div>
            ) : (
                <FormControl fullWidth>
                    <Select
                        displayEmpty
                        value={selectedAnchor}
                        onChange={(e) => setSelectedAnchor(e.target.value)}
                        required
                    >
                        <MenuItem value="" disabled>Select Existing Step</MenuItem>
                        {existingAnchors.map(anchor => (
                            <MenuItem key={anchor.anchor_id} value={anchor.anchor_id}>{anchor.name}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
            )}

            <Button type="submit" variant="contained" color="primary">
                Add Step
            </Button>
        </form>
    );
};

export default CompCRUDFormAnchorJourney;
