import React, { useEffect, useState, useContext } from 'react';
import { useParams } from "react-router-dom";
import './CompCRUDFormObjective.css';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import CompModalButtons from './CompCRUDModalButtons';
import EditIcon from '@mui/icons-material/Edit';
import CompCRUDModalActionBar from './CompCRUDModalActionBar';
import { createObjective, updateObjective } from '../fxAPI/fxObjectivesAPI.js';
import { getAnchorsByProjectId } from '../fxAPI/fxAnchorsAPI.js';
import { getMilestonesByOrgId } from '../fxAPI/fxMilestonesAPI.js';
import { createSubjective } from '../fxAPI/fxSubjectivesAPI.js';
import Slider from '@mui/material/Slider';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import subjectives from '../Data/ObjectiveTemplates/accessibility-basic.json';
import objectiveTemplates from '../Data/ObjectiveTemplates/template-options.json'; // Import the template options
import accessibilityTasks from '../Data/ObjectiveTemplates/accessibility-basic.json'; // Import tasks
import uiReviewTasks from '../Data/ObjectiveTemplates/ui-review.json'; // Import tasks
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateField } from "@mui/x-date-pickers/DateField";
import { PanelContext } from '../Contexts/PanelContext';


const CompCRUDFormObjective = (props) => {

  function generateUUID() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      const r = (Math.random() * 16) | 0, v = c === 'x' ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  }

  

  const [useTemplate, setUseTemplate] = useState(false); // Toggle state
  const [selectedTemplate, setSelectedTemplate] = useState('');

  const handleTemplateChange = (event) => {
    setSelectedTemplate(event.target.value);
    //triggerTasks(event.target.value); // Trigger task creation
  };

  // Function to trigger tasks based on the selected template
  const triggerTasks = (templateKey, newObjectiveId) => {
    console.log('selected template: ' + selectedTemplate);
    console.log('template key: ' + templateKey);
    let tasks = [];

    switch (templateKey) {
      case 'accessibility-basic':
        tasks = accessibilityTasks.subjectives;
        break;
      case 'ui-review':
        tasks = uiReviewTasks.subjectives;
        break;
      default:
        tasks = [];
    }

    tasks.forEach((task) => createTask(task, newObjectiveId)); // Call createTask function for each task
  };

  // Mock function to simulate task creation
  const createTask = (task, newObjectiveId) => {
    const currentOrgId = localStorage.getItem("fxLocalOrgID");
    console.log(`Creating task: ${task.name}`);
    console.log(`Description: ${task.description}`);
    console.log(`Tags: ${task.tags.join(', ')}`);
    const relatedProject = params.projectId;

    createSubjective(task.name, task.description, currentOrgId, newObjectiveId, relatedProject);
  };

  function getObjectiveViewName() {
    return props.currentComponentData?.objective_view_name || props.currentComponentData?.objective_name || props.currentComponentData?.name || 'Default Value';
  }

  const currentObjectiveName = getObjectiveViewName();

  function getObjectiveViewDescription() {
    return props.currentComponentData?.objective_view_description || props.currentComponentData?.objective_name || props.currentComponentData?.description || 'Default Value';
  }

  const currentObjectiveDescription = getObjectiveViewDescription();

  const currentObjectiveDueDate = props.currentComponentData?.due_date || null;


  const [error, setError] = useState(null);

  const params = useParams();

  const [currentEditableState, setCurrentEditableState] = useState('view');
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [updateObjectiveName, setUpdateObjectiveName] = useState(currentObjectiveName);
  const [updateObjectiveDescription, setUpdateObjectiveDescription] = useState(currentObjectiveDescription);
  const [updateDueDate, setUpdateDueDate] = useState(currentObjectiveDueDate);
  const [updateProgress, setUpdateProgress] = useState(null);
  const [updateMilestone, setUpdateMilestone] = useState(null);


  /* ANCHORS */
  const [assignedAnchor, setAssignedAnchor] = useState(props.relatedAnchorId);
  const [anchorsByProject, setAnchorsByProjectData] = useState([]);
  const fxAPIFetchAnchorsByProjectId = async (projectId) => {
    await getAnchorsByProjectId(projectId, setError, setAnchorsByProjectData);
  };

  /* Milestones */
  const [newMilestone, setNewMilestone] = useState(null);
  const [milestonesByOrg, setMilestonesByOrgData] = useState([]);
  const fxAPIFetchMilestonesByOrgId = async (orgId) => {
    await getMilestonesByOrgId(orgId, setError, setMilestonesByOrgData);
  };

  useEffect(() => {
    fxAPIFetchAnchorsByProjectId(params.compId);
    const orgId = localStorage.getItem("fxLocalOrgID");
    fxAPIFetchMilestonesByOrgId(orgId);
  }, []);


  console.log('current objective data on form objective: ');
  console.dir(props.currentComponentData);

  const triggerViewMode = (currentViewMode) => {
    setCurrentEditableState(currentViewMode);
    if(currentViewMode === "edit") {
      console.log('triggered edit objective');
    }
  };

  const handleChangeAssignedAnchor = (e) => {
    const relatedOrg = localStorage.getItem("fxLocalOrgID");
    setAssignedAnchor(e.target.value);
    console.log('set assigned anchor to: ' + e.target.value + ' related org: ' + relatedOrg);
  }

  const handleChangeMilestone = (e) => {
    const relatedOrg = localStorage.getItem("fxLocalOrgID");
    setNewMilestone(e.target.value);
    console.log('set milestone to: ' + e.target.value + ' related org: ' + relatedOrg);
  }

  const handleUpdateObjectiveNameChange = (e) => {
    setUpdateObjectiveName(e.target.value);
  }

  const handleUpdateObjectiveDescriptionChange = (e) => {
    setUpdateObjectiveDescription(e.target.value);
  }

  const handleUpdateDueDateChange = (e) => {
    setUpdateDueDate(e.target.value);
  }

  const handleChangeUpdateMilestone = (e) => {
    const relatedOrg = localStorage.getItem("fxLocalOrgID");
    setUpdateMilestone(e.target.value);
    console.log('set milestone to: ' + e.target.value + ' related org: ' + relatedOrg);
  }

  const handleSliderChange = (event, newValue) => {
    //setSliderValue(newValue);
    setUpdateProgress(newValue);
    console.log('Slider value changed to:', newValue);
  };

  const checkSelectedTemplate = (e) => {
    e.preventDefault();
    console.log('selected template: ' + selectedTemplate);
  }

  const defaultCloseModal = () => {
    console.warn("onClose prop is not provided");
  };

  const handleCloseModal = () => {
    props.onClose();
  };

  const handleCreateObjective = async (event) => {
    event.preventDefault();
    const newRelatedProject = params.projectId;
    const relatedOrg = localStorage.getItem("fxLocalOrgID");
    const relatedAnchor = props.relatedAnchorId || null;
    const progress = '0';
    const objectiveId = generateUUID();
    const relatedBusinessValue = null;
    const relatedMilestone = newMilestone;

    console.log('creating objective with milestone value of: ' + newMilestone);

    await createObjective(objectiveId, name, description, relatedOrg, newRelatedProject, progress, relatedAnchor, relatedMilestone, relatedBusinessValue, null);
  
    if(selectedTemplate) {
      triggerTasks(selectedTemplate, objectiveId); 
    }

    handleRefreshElement();
    handleCloseModal(); // Close the modal after creating the objective
  };

  const handleUpdateObjective = async (e) => {
    console.log('ran update objective: ' + props.currentComponentData.objective_id);
    e.preventDefault();
    const currentOrgId = localStorage.getItem("fxLocalOrgID");
  
    //const projectId = props.currentComponentData.project_id; // Replace with the actual project ID
    const objectiveId = props.currentComponentData.objective_id;
    const name = updateObjectiveName;
    const description = updateObjectiveDescription;
    const related_org = currentOrgId;
    const progress = updateProgress;
    const date_due = null;
    const related_anchor = null;
    const related_milestone = updateMilestone;
    const related_business_value = null;
  
    await updateObjective(objectiveId, name, description, related_org, null, progress, related_anchor, related_milestone, related_business_value, date_due);
    handleRefreshElement(); // Refresh the element after updating the objective
    handleCloseModal(); // Close the modal after updating the objective
  };

  /* Use Global Context to refresh Subjective Panel */
  const { triggerElementRefresh } = useContext(PanelContext);

  const handleRefreshElement = () => {
    console.log("Triggering refresh for objectiveAnchorCard");
    triggerElementRefresh("objectiveAnchorCard");
    triggerElementRefresh('nodeBreakdown');
  };

  return (
    <div className={props.crudMethod === "create" && props.formStyle === "darkBG" ? "fx-crud-form-darkBG" : ""}>
        {props.crudMethod === "create" &&
        <div>
            <br />
            <form onSubmit={handleCreateObjective}>
              <div>
                <TextField
                  id="outlined-basic"
                  label="Objective Name"
                  variant="outlined"
                  onChange={(e) => setName(e.target.value)}
                />
              </div>   
              <br />
              <div className="fx-global-form-text-area-description">
              <TextField
                id="outlined-multiline-flexible"
                label="Objective Description"
                multiline
                maxRows={4}
                onChange={(e) => setDescription(e.target.value)}
                fullWidth
                />  
              </div>
              <br />
              <div>
                  <label for="new-milestone">Assigned Milestone</label><br />
                  <select id="new-milestone" name="new-milestone" onChange={handleChangeMilestone}>
                    <option>Select Milestone...</option>
                    {milestonesByOrg.map((milestone) => (
                      <option key={milestone.milestone_id} value={milestone.milestone_id}>{milestone.name}</option>
                    ))}
                  </select>
              </div>
              <br />
              {/* -- start add from template */}
              {/*
              <div>
                <label>Use an Objective Template?</label>
                <label>
                  <input
                    type="radio"
                    name="useTemplate"
                    value="yes"
                    onChange={() => setUseTemplate(true)}
                  />
                  Yes
                </label>
                <label>
                  <input
                    type="radio"
                    name="useTemplate"
                    value="no"
                    onChange={() => {
                      setUseTemplate(false);
                      setSelectedTemplate('');
                    }}
                  />
                  No
                </label>

                {useTemplate && (
                  <div>
                    <label>Select a Template</label><br />
                    <select id="select-template-select" value={selectedTemplate} onChange={handleTemplateChange}>
                      <option value="">-- Select Template --</option>
                      {objectiveTemplates.templateOptions.map((template) => (
                        <option key={template.key} value={template.key}>
                          {template.name}
                        </option>
                      ))}
                    </select>
                  </div>
                )}
              </div>
              */}
              <br />
              {/*<div>
                <button onClick={() => checkSelectedTemplate()}>Check selected template</button>
              </div>*/}
              {/* -- end add from template */}
              <CompModalButtons>
                <Button className="fx-objective-form-button" variant="outlined" type="submit">Create Objective</Button>
                {/*<Button variant="outlined" type="button" onClick={handleCloseModal}>Cancel</Button>*/}
              </CompModalButtons>         
            </form>
        </div>
        }
        {props.crudMethod === "view-edit" &&
          <div>
            <CompCRUDModalActionBar>
            { currentEditableState === "view" &&
              <Button onClick={() => triggerViewMode("edit")}><EditIcon /></Button>
            }
            { currentEditableState === "edit" &&
              <Button variant="outlined" onClick={() => triggerViewMode("view")}>Save</Button>
            }
          </CompCRUDModalActionBar>

          { currentEditableState === "view" &&
            <div>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <h3>Details</h3>
                  <div>
                    <div className='fx-global-form-read-only-label'>Objective Name</div>
                    <div>{currentObjectiveName}</div>
                  </div> 
                  <div>
                    <div className='fx-global-form-read-only-label'>Objective Description</div>
                    <div>{currentObjectiveDescription}</div>
                  </div> 
                  <div>
                    <div className='fx-global-form-read-only-label'>Progress</div>
                    <div className='fx-objective-detail-slider-wrap'><Slider defaultValue={props.currentComponentData.progress} aria-label="Default" valueLabelDisplay="auto" /></div>
                  </div>
                  <div>
                    <div className='fx-global-form-read-only-label'>Related Project</div>
                    <div>{props.currentComponentData.project_view_name}</div>
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <h3>Dates</h3>
                  {props.currentComponentData ? (
                    <>
                      <div>
                        <div className="fx-global-form-read-only-label">Date Created</div>
                        <div>
                          {props.currentComponentData.date_time_created
                            ? new Date(props.currentComponentData.date_time_created).toLocaleString()
                            : 'N/A'}
                        </div>
                      </div>
                      <div>
                        <div className="fx-global-form-read-only-label">Date Updated</div>
                        <div>
                          {props.currentComponentData.date_time_updated
                            ? new Date(props.currentComponentData.date_time_updated).toLocaleString()
                            : 'N/A'}
                        </div>
                      </div>
                      <div>
                        <div className="fx-global-form-read-only-label">Date Due</div>
                        <div>
                          {props.currentComponentData.date_due
                            ? new Date(props.currentComponentData.date_due).toLocaleString()
                            : 'N/A'}
                        </div>
                      </div>
                    </>
                  ) : (
                    <div>No data available</div>
                  )}
                </Grid>
              </Grid>
              {/*<div>Test: {props.currentComponentData.objective_id}</div>*/}
              
              
            </div>
          } 

          { currentEditableState === "edit" &&
            <div>
              <form onSubmit={handleUpdateObjective} className="fx-global-crud-edit-form">
                <div>
                  <TextField id="outlined-basic" label="Objective Name" variant="outlined" value={updateObjectiveName} onChange={handleUpdateObjectiveNameChange} />
                </div>   
                <br />
                <div className="fx-global-form-text-area-description">
                <TextField
                  id="outlined-multiline-flexible"
                  label="Objective Description"
                  value={updateObjectiveDescription}
                  onChange={handleUpdateObjectiveDescriptionChange}
                  multiline
                  maxRows={4}
                  fullWidth
                  />  
                </div>

                <div>
                  <label for="new-milestone">Assigned Milestone</label>
                  <select id="new-milestone" name="new-milestone" onChange={handleChangeUpdateMilestone}>
                    <option>Select Milestone...</option>
                    {milestonesByOrg.map((milestone) => (
                      <option key={milestone.milestone_id} value={milestone.milestone_id}>{milestone.name}</option>
                    ))}
                  </select>
              </div>

                <div>
                  <div className='fx-global-form-read-only-label'>Progress</div>
                  <div className='fx-objective-detail-slider-wrap'><Slider onChangeCommitted={(event, newValue) => handleSliderChange(event, newValue)} defaultValue={props.currentComponentData.progress} aria-label="Default" valueLabelDisplay="auto" /></div>
                </div>
                <div>
                  {/*
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateField
          label="Select Date"
          value={formattedDate ? dayjs(formattedDate) : null} // Convert to Day.js object for the DateField
          onChange={handleDateChange}
          format="MM/DD/YYYY" // Display in MM/DD/YYYY format
        />
        <div>
          <strong>Formatted Date:</strong> {formattedDate || "N/A"}
          </div>
                </LocalizationProvider>
                */}
                </div>
                <CompModalButtons>
                  <Button type="submit">Save Objective</Button>
                  <Button type="button" onClick={handleCloseModal}>Cancel</Button>
                </CompModalButtons>         
              </form>
            </div>
          }     

          </div>
        }
    </div>
  );
};

CompCRUDFormObjective.defaultProps = {
  onClose: () => {
    console.warn("onClose prop is not provided");
  },
};

export default CompCRUDFormObjective;