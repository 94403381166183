import React from 'react';
import './CompStartPendingOrgApproval.css';
import CompPageTempIntroSlide from './CompPageTempIntroSlide';



const CompStartPendingApproval = (props) => {

    const panelLeftPanel = (
        <>
          <div className="fx-comp-startsetup-step-container-inner">
            <div className="fx-comp-start-setup-step-left-panel-body">
                Your organization has not approved your account yet.  
            </div>
          </div>
        </>
      );

      const panelRightPanel = (
        <>
          <div className="fx-comp-startsetup-step-container-inner">
            <div className="fx-comp-startsetup-pending-org-message-wrap">
                Please reach out to your administrative team to have them create your account.  <strong>Upon approval,</strong> you will be able to access your team space on your next login.
            </div>
          </div>
        </>
      );
      

  return (
    <div>
      <CompPageTempIntroSlide headingOne="Account Pending" panelLeftContent={panelLeftPanel} panelRightContent={panelRightPanel} />
    </div>
  );
};

export default CompStartPendingApproval;