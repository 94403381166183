import React, { useEffect, useState } from 'react';
import './CompSideNavMaster.css';
import CompNavItem from './CompNavItem.jsx';
import AssessmentIcon from '@mui/icons-material/Assessment';
import SettingsIcon from '@mui/icons-material/Settings';
import axios from 'axios';
import { getProjectsByOrgId } from '../fxAPI/fxProjectsAPI';
import { getObjectivesByOrgId } from '../fxAPI/fxObjectivesAPI';
import { getUserPersonasByOrgId } from '../fxAPI/fxUserPersonasAPI.js';
import { getUIAssetsByOrgId } from '../fxAPI/fxUIAssetsAPI.js';
import { getComStatusByOrgId } from '../fxAPI/fxStatusAPI.js';
import { getReportsByOrgId } from '../fxAPI/fxReportsAPI.js';
import Button from '@mui/material/Button';
import CompCollapsibleContainer from './CompCollapsibleContainer.jsx';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import CompSideNavObjectiveList from './CompSideNavObjectiveList.jsx';
import CompHyperlogicDropzone from './CompHyperlogicDropzone.jsx';

const CompSideNavMaster = (props) => {
  const [error, setError] = useState(null);

  const [tabValue, setTabValue] = useState(0);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
    localStorage.setItem('activeTab', newValue);
  };

  useEffect(() => {
    const savedTabValue = localStorage.getItem('activeTab');
    if (savedTabValue !== null) {
      setTabValue(parseInt(savedTabValue, 10));
    }
  }, []);

  // Hide some aspects for production users temporarily
  const localEnv = localStorage.getItem('fxEnv');
  console.log('local environment on header: ' + localEnv);

  const [projects, setProjectsData] = useState([]); // Initialize as an array
  const [refinedProjects, setRefinedProjects] = useState([]);

  /* get projects by orgId */
  const fxAPIFetchProjectsByOrgId = async (orgId) => {
    await getProjectsByOrgId(orgId, setError, setProjectsData);
    console.log('all projects by org Id');
    console.dir(projects);
  };

  const [objectives, setObjectivesData] = useState([]); // Initialize as an array
  /* get objectives by orgId */
  const fxAPIFetchObjectivesByOrgId = async (orgId) => {
    await getObjectivesByOrgId(orgId, setError, setObjectivesData);
    console.log('all objectives by org Id');
    console.dir(objectives);
  };

  const [userPersonas, setUserPersonasData] = useState([]); // Initialize as an array
  /* get user personas by orgId */
  const fxAPIFetchUserPersonasByOrgId = async (orgId) => {
    await getUserPersonasByOrgId(orgId, setError, setUserPersonasData);
  };

  const [uiAssets, setUIAssetsData] = useState([]); // Initialize as an array
  /* get ui assets by orgId */
  const fxAPIFetchUIAssetsByOrgId = async (orgId) => {
    await getUIAssetsByOrgId(orgId, setError, setUIAssetsData);
  };

  const [comStatus, setComStatusData] = useState([]); // Initialize as an array
  /* get status by orgId */
  const fxAPIFetchComStatusByOrgId = async (orgId) => {
    await getComStatusByOrgId(orgId, setError, setComStatusData);
  };

  const [reports, setReportsData] = useState([]); // Initialize as an array
  /* get Reports by orgId */
  const fxAPIFetchReportsByOrgId = async (orgId) => {
    await getReportsByOrgId(orgId, setError, setReportsData);
  };

  useEffect(() => {
    const currentOrgId = localStorage.getItem("fxLocalOrgID");
    const projectId = new URLSearchParams(window.location.search).get('projectId');
    if (projectId) {
      localStorage.setItem("fxLocalProjectContext", projectId);
      console.log(`fxLocalProjectContext set to: ${projectId}`);
    }
    if (currentOrgId) {
      console.log(`Current OrgId from local storage: ${currentOrgId}`);
      fxAPIFetchProjectsByOrgId(currentOrgId);
      fxAPIFetchObjectivesByOrgId(currentOrgId);
      fxAPIFetchUserPersonasByOrgId(currentOrgId);
      fxAPIFetchUIAssetsByOrgId(currentOrgId);
      fxAPIFetchComStatusByOrgId(currentOrgId);
      fxAPIFetchReportsByOrgId(currentOrgId);
    } else {
      setError('No OrgId found in local storage');
    }
  }, []);

  useEffect(() => {
    console.log('Projects state updated:', projects);
    
  }, [projects]);

  // Sum States
  const [projectsTotal, setProjectsTotal] = useState(null);
  const [objectivesTotal, setObjectivesTotal] = useState(null);
  const [userPersonasTotal, setUserPersonasTotal] = useState(null);
  const [comStatusTotal, setComStatusTotal] = useState(null);
  const [uiAssetsTotal, setUIAssetsTotal] = useState(null);

  useEffect(() => {
    // Counting Projects
    let projectsCount = 0;
    projects.forEach((project) => {
      projectsCount += 1;
    });
    setProjectsTotal(projectsCount);

    // Counting Objectives
    let objectivesCount = 0;
    objectives.forEach((objective) => {
      objectivesCount += 1;
    });
    setObjectivesTotal(objectivesCount);

    // Counting User Personas
    let userPersonasCount = 0;
    userPersonas.forEach((userPersona) => {
      userPersonasCount += 1;
    });
    setUserPersonasTotal(userPersonasCount);

    // Counting Status
    let statusCount = 0;
    comStatus.forEach((status) => {
      statusCount += 1;
    });
    setComStatusTotal(statusCount);

    // Counting UI Assets
    let uiAssetsCount = 0;
    uiAssets.forEach((uiAsset) => {
      uiAssetsCount += 1;
    });
    setUIAssetsTotal(uiAssetsCount);

  }, [projects, objectives, userPersonas, comStatus, uiAssets]);

  console.log('Objectives sum: ' + objectivesTotal);

  const localUserRole = localStorage.getItem("fxLocalUserRole");

  const currentRoute = window.location.pathname;
  const localProjectContext = localStorage.getItem("fxLocalProjectContext");

  const userRole = localStorage.getItem("fxLocalUserRole");
  const userRoleURL = '/dashboard/role/' + userRole;

  const handleBackButtonClick = () => {
    localStorage.removeItem("fxLocalProjectContext"); // Clear the project context
    localStorage.removeItem('activeTab'); // Clear the active tab
    console.log('fxLocalProjectContext and activeTab cleared');
    window.location.href = userRoleURL;
  };

  const handleDesignSessionsClick = (event) => {
    event.preventDefault();
    window.location.href = '/design-sessions';
  };

  return (
    <div>
      {/*{props.isCollapsed && */}
      {localUserRole === 'end-user' &&
        <>
        <h2>User Tools</h2>
        <div className="fx-comp-side-nav-master-link-primary-group-item">
          <a href="/end-user-tools">
            <div className="fx-comp-side-nav-master-link-primary-group-item-inner">User Home</div>
          </a>
        </div> 
        </>     
      }
      {currentRoute.startsWith('/playbook/') || localProjectContext ? (
        <div className='fx-comp-side-nav-master-playbook-context-container'>
          <div className='fx-comp-side-nav-master-playbook-back-container'>
            <div className='fx-comp-side-nav-master-playbook-back-item-button' onClick={handleBackButtonClick}>
              <ChevronLeftIcon />Fluxity
            </div>
            <div className='fx-comp-side-nav-master-playbook-back-item-notifications'>
              <NotificationsNoneIcon />
            </div>
          </div>
          <div className='fx-comp-side-nav-master-playbook-header-container'>
            <div className='fx-comp-side-nav-master-playbook-header-item-name'><h2>Playbook Name</h2></div>
            <div className='fx-comp-side-nav-master-playbook-header-item-status'></div>
          </div>
          <Tabs value={tabValue} onChange={handleTabChange}>
            <Tab label="Objectives" />
            <Tab label="Design" />
            <Tab label="Development" />
          </Tabs>
          {tabValue === 0 && (
            <CompSideNavObjectiveList />
          )}
          {tabValue === 1 && (
            <div>
              <CompCollapsibleContainer containerHeading="User Journeys" containerStyle="dark" arrowPosition="right"> 
                <a href={`/playbook/journey-map-gallery/${localProjectContext}`}>
                  <div className="fx-comp-side-nav-master-link-single-dark">Journey Maps</div>
                </a>
              </CompCollapsibleContainer>
              <CompCollapsibleContainer containerHeading="Design Iterations" containerStyle="dark" arrowPosition="right"> 
                <a href="/design-sessions">
                  <div className="fx-comp-side-nav-master-link-single-dark">Design Sessions</div>
                </a>
              </CompCollapsibleContainer>
              <CompCollapsibleContainer containerHeading="UI Assets" containerStyle="dark" arrowPosition="right">
                <a href="/ui-assets">
                  <div className="fx-comp-side-nav-master-link-single-dark">UI Assets</div>
                </a>
              </CompCollapsibleContainer>
            </div>
          )}
          {tabValue === 2 && (
            <>
              <CompCollapsibleContainer containerHeading="Planning" containerStyle="dark" arrowPosition="right"> 
              <a href={`/playbook/milestones/${localProjectContext}`}>
                  <div className="fx-comp-side-nav-master-link-single-dark">Milestones</div>
                </a>
                <a href={`/playbook/objective-map/${localProjectContext}`}>
                  <div className="fx-comp-side-nav-master-link-single-dark">Objective Map</div>
                </a>
                <a href={`/playbook/objective-stack/${localProjectContext}`}>
                  <div className="fx-comp-side-nav-master-link-single-dark">Objective Stack</div>
                </a>
              </CompCollapsibleContainer>
              <CompCollapsibleContainer containerHeading="Architecture" containerStyle="dark" arrowPosition="right"> 
              <div>
                <a href="/components">
                    <div className="fx-comp-side-nav-master-link-single-dark">Components</div>
                  </a>
              </div>
              </CompCollapsibleContainer>
              <CompCollapsibleContainer containerHeading="Compliance" containerStyle="dark" arrowPosition="right"> 
              <div>
              <a href="/accessibility">
                  <div className="fx-comp-side-nav-master-link-single-dark">Accessibility</div>
                </a>
              </div>
              </CompCollapsibleContainer>
            </>
          )}
        </div>
      ) : (
        <>
          {localUserRole !== 'end-user' && 
           (!currentRoute.startsWith('/playbook/') && !currentRoute.startsWith('/hyperlogic') || localStorage.getItem("fxLocalProjectContext")) &&
          <>
            <>
              <h2>Business Value</h2>
              <div className="fx-comp-side-nav-master-link-primary-group-item">
                <a href="/business-value">
                  <div className="fx-comp-side-nav-master-link-primary-group-item-inner">Business Value</div>
                </a>
              </div>
              <h2>Playbooks</h2>
              {projects.map((project) => (
                      <div className="fx-comp-side-nav-master-link-primary-group-item">
                        <div className="fx-comp-side-nav-master-link-primary-group-item-inner">
                          <a href={`/playbook/objective-stack/${project.project_id}`}>{project.name}</a>
                        </div>
                        {/*
                        <CompCollapsibleContainer containerHeading={project.name}>
                          <div>
                            <a href={`/playbook/objective-map/${project.project_id}`}>Playbook Overview</a>
                          </div>
                          <div>
                            <a href={`/playbook/objective-map/${project.project_id}`}>Objective Map</a>
                          </div>
                          <div>
                            <a href={`/playbook/objective-stack/${project.project_id}`}>Objective Stack</a>
                          </div>
                          <div>
                            <a href={`/playbook/journey-map/${project.project_id}`}>Journey Map</a>
                          </div>
                          <div>
                            <a href="/ui-assets/">UI Assets</a>
                          </div>
                        </CompCollapsibleContainer>
                        */}
                        {/*
                        <a href={`/dashboard/projects/${project.project_id}`} key={project.project_id}>
                          <div className="fx-comp-side-nav-master-link-primary-group-item-inner">{project.name}</div>
                        </a>
                        */}
                      </div>
                      
              ))}
              <h2>Research</h2>
              <div className="fx-comp-side-nav-master-link-primary-group-item">
                <a href="/hyperlogic">
                  <div className="fx-comp-side-nav-master-link-primary-group-item-inner">Hyperlogic</div>
                </a>
              </div>


              
              
            </>

            

          </>
          }

{currentRoute.startsWith('/hyperlogic') && (
              <div>
                {console.log('Rendering hyperlogic items because currentRoute starts with /hyperlogic')}
                <h3>Hyperlogic Items</h3>
                <div className='fx-comp-side-nav-master-playbook-back-item-button' onClick={handleBackButtonClick}>
                  <ChevronLeftIcon />Fluxity
                </div>
                <CompCollapsibleContainer containerHeading="Planning" containerStyle="dark" arrowPosition="right">
                  <CompHyperlogicDropzone containerHeading="Planning" relatedResearchType="Planning" />
                </CompCollapsibleContainer>
                <CompCollapsibleContainer containerHeading="Activities" containerStyle="dark" arrowPosition="right">
                  <CompHyperlogicDropzone containerHeading="Activities" relatedResearchType="Activities" />
                </CompCollapsibleContainer>
                <CompCollapsibleContainer containerHeading="Resources" containerStyle="dark" arrowPosition="right">
                  <CompHyperlogicDropzone containerHeading="Resources" relatedResearchType="Resources" />
                </CompCollapsibleContainer>
                <CompCollapsibleContainer containerHeading="Forecasting" containerStyle="dark" arrowPosition="right">
                  <CompHyperlogicDropzone containerHeading="Forecasting" relatedResearchType="Forecasting" />
                </CompCollapsibleContainer>
                <CompCollapsibleContainer containerHeading="Execution" containerStyle="dark" arrowPosition="right">
                  <CompHyperlogicDropzone containerHeading="Execution" relatedResearchType="Execution" />
                </CompCollapsibleContainer>
              </div>
            )}

          
        </>
      )}
    </div>
  );
};

export default CompSideNavMaster;