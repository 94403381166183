import React, { useEffect, useState, useContext } from 'react';
import './CompCRUDFormPath.css';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import CompModalButtons from './CompCRUDModalButtons';
import EditIcon from '@mui/icons-material/Edit';
import CompCRUDModalActionBar from './CompCRUDModalActionBar';
import { createMilestone, updateMilestone, getMilestonesByProjectId, deleteMilestone } from '../fxAPI/fxMilestonesAPI.js';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useParams } from "react-router-dom";
import { PanelContext } from '../Contexts/PanelContext';
import Alert from '@mui/material/Alert';

const CompCRUDFormMilestone = (props) => {

  const params = useParams();
  const [error, setError] = useState(null);

  const [currentEditableState, setCurrentEditableState] = useState('view');
  const [name, setName] = useState(null);
  const [description, setDescription] = useState(null);
  const [pathResponse, setPathResponse] = useState(null);
  const [rawStartDate, setRawStartDate] = useState(""); // State for YYYY-MM-DD format
  const [isoStartDate, setIsoStartDate] = useState(""); // State for MM/DD/YYYY
  const [rawEndDate, setRawEndDate] = useState(""); // State for YYYY-MM-DD format
  const [isoEndDate, setIsoEndDate] = useState(""); // State for MM/DD/YYYY
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [updateMilestoneName, setUpdateMilestoneName] = useState(
    props.currentComponentData?.name || "Default Name"
  );
  const [updateMilestoneDescription, setUpdateMilestoneDescription] = useState(
    props.currentComponentData?.description || "Default Description"
  );
  const [milestones, setMilestones] = useState([]);
  const [latestMilestoneEndDate, setLatestMilestoneEndDate] = useState(null);

  const { triggerElementRefresh } = useContext(PanelContext);

  console.log('current milestone data on form: ');
  console.dir(props.currentComponentData);

  useEffect(() => {
    const fetchMilestones = async () => {
      await getMilestonesByProjectId(params.projectId, setError, setMilestones);
    };

    fetchMilestones();
  }, [params.projectId]);

  useEffect(() => {
    if (milestones.length > 0) {
      const sortedMilestones = milestones.sort((a, b) => new Date(a.date_end) - new Date(b.date_end));
      setLatestMilestoneEndDate(sortedMilestones[sortedMilestones.length - 1].date_end);
      setRawStartDate(sortedMilestones[sortedMilestones.length - 1].date_end); // Set the raw start date
      setIsoStartDate(new Date(sortedMilestones[sortedMilestones.length - 1].date_end).toISOString()); // Set the ISO start date
    }
  }, [milestones]);

  const triggerViewMode = (currentViewMode) => {
    setCurrentEditableState(currentViewMode);
  };

  const handleStartDateChange = (event) => {
    const rawStartDateValue = event.target.value; // Raw date in YYYY-MM-DD format
    setRawStartDate(rawStartDateValue); // Save the raw date for input value

    // Parse the raw date as a local date (not UTC)
    const [year, month, day] = rawStartDateValue.split("-");
    const localStartDate = new Date(year, month - 1, day);

    // Format as ISO string for Oracle database
    const isoFormattedStartDate = localStartDate.toISOString(); // Converts to ISO 8601
    setIsoStartDate(isoFormattedStartDate);

    console.log("Raw Start Date (YYYY-MM-DD):", rawStartDateValue);
    console.log("ISO Start Formatted Date for DB:", isoFormattedStartDate);
  };

  const handleEndDateChange = (event) => {
    const rawEndDateValue = event.target.value; // Raw date in YYYY-MM-DD format
    setRawEndDate(rawEndDateValue); // Save the raw date for input value

    // Parse the raw date as a local date (not UTC)
    const [year, month, day] = rawEndDateValue.split("-");
    const localEndDate = new Date(year, month - 1, day);

    // Format as ISO string for Oracle database
    const isoFormattedEndDate = localEndDate.toISOString(); // Converts to ISO 8601
    setIsoEndDate(isoFormattedEndDate);

    console.log("Raw End Date (YYYY-MM-DD):", rawEndDateValue);
    console.log("ISO End Formatted Date for DB:", isoFormattedEndDate);
  };

  const handleCloseModal = () => {
    props.onClose();
  };

  const handleCreateMilestone = async (event) => {
    event.preventDefault();
    const currentTimestamp = new Date().toISOString();
    const related_org = localStorage.getItem("fxLocalOrgID");
    const date_start = isoStartDate;
    const date_end = isoEndDate;
    const related_project = params.projectId;
    const status = "not-started";

    await createMilestone(name, description, date_start, date_end, related_project, related_org, status, setPathResponse);
    triggerElementRefresh('milestoneList'); // Trigger refresh for CompMilestoneList
    handleCloseModal(); // Close the modal after creating the milestone
  };

  const handleUpdateMilestoneNameChange = (e) => {
    setUpdateMilestoneName(e.target.value);
  }

  const handleUpdateMilestoneDescriptionChange = (e) => {
    setUpdateMilestoneDescription(e.target.value);
  }

  const handleUpdateMilestone = async (e) => {
    console.log('ran update milestone: ' + props.currentComponentData.milestone_id);
    e.preventDefault();
    const relatedOrg = localStorage.getItem("fxLocalOrgID");
  
    //const projectId = props.currentComponentData.project_id; // Replace with the actual project ID
    const milestoneId = props.currentComponentData.milestone_id;
    const updateName = updateMilestoneName;
    const updateDescription = updateMilestoneDescription;
    const related_org = relatedOrg;
    const updateDateStart = null;
    const updateDateEnd = null;
    const related_project = props.relatedProject;
    const status = null;

    await updateMilestone(milestoneId, updateName, updateDescription, updateDateStart, updateDateEnd, related_project, related_org, status);
    handleCloseModal(); // Close the modal after updating the milestone
  };

  const handleDeleteMilestone = async () => {    
    const milestoneId = localStorage.getItem("fxSidePanelObjectId");
    await deleteMilestone(milestoneId, setError, setPathResponse);
    triggerElementRefresh('milestoneList'); // Trigger refresh for CompMilestoneList
    handleCloseModal(); // Close the modal after deleting the milestone
  };

  const inputStyle = { backgroundColor: 'white' };
  const buttonStyle = { backgroundColor: 'white' };

  return (
    <div>
      {props.crudMethod === "create" &&
        <div>
          <br />
          <form onSubmit={handleCreateMilestone}>
            <div>
              <TextField 
                id="outlined-basic" 
                label="Milestone Name" 
                variant="outlined" 
                onChange={(e) => setName(e.target.value)} 
                style={inputStyle} 
              />
            </div>   
            <br />
            <div className="fx-global-form-text-area-description">
            <TextField
              id="outlined-multiline-flexible"
              label="Milestone Description"
              multiline
              maxRows={4}
              onChange={(e) => setDescription(e.target.value)}
              fullWidth
              style={inputStyle}
              />  
            </div>
            <br />
            <div>
                  <label htmlFor="start-date">Start Date:</label>
                  {latestMilestoneEndDate ? (
                    <TextField
                      id="start-date"
                      value={latestMilestoneEndDate}
                      style={inputStyle}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  ) : (
                    <input
                      id="start-date"
                      type="date"
                      value={rawStartDate}
                      onChange={handleStartDateChange}
                      style={inputStyle}
                    />
                  )}
            </div>
            <br />
            <div>
                  <label htmlFor="end-date">End Date:</label>
                  <input
                    id="end-date"
                    type="date"
                    value={rawEndDate}
                    onChange={handleEndDateChange}
                    style={inputStyle}
                  />
            </div>
            <CompModalButtons>
              <Button type="submit" variant="outlined" style={buttonStyle}>Create Milestone</Button>
              <Button type="button" onClick={handleCloseModal} variant="outlined" style={buttonStyle}>Cancel</Button>
            </CompModalButtons>         
          </form>
        </div>
      }
      {props.crudMethod === "view-edit" &&
        <div>
          <CompCRUDModalActionBar>
          { currentEditableState === "view" &&
            <Button onClick={() => triggerViewMode("edit")}><EditIcon /></Button>
          }
          { currentEditableState === "edit" &&
            <Button variant="outlined" onClick={() => triggerViewMode("view")} style={buttonStyle}>Save</Button>
          }
        </CompCRUDModalActionBar>

        { currentEditableState === "view" &&
          <div>
            <div>
              <div className='fx-global-form-read-only-label'>Milestone Name</div>
              <div>{props.currentComponentData.name}</div>
            </div> 
            <div>
              <div className='fx-global-form-read-only-label'>Milestone Description</div>
              <div>{props.currentComponentData.description}</div>
            </div> 
          </div>
        } 

        { currentEditableState === "edit" &&
          <div>
            <form onSubmit={handleUpdateMilestone}>
              <div>
                <TextField 
                  id="outlined-basic" 
                  label="Milestone Name" 
                  variant="outlined" 
                  value={updateMilestoneName} 
                  onChange={handleUpdateMilestoneNameChange} 
                  style={inputStyle} 
                />
              </div>   
              <br />
              <div className="fx-global-form-text-area-description">
              <TextField
                id="outlined-multiline-flexible"
                label="Milestone Description"
                value={updateMilestoneDescription}
                onChange={handleUpdateMilestoneDescriptionChange}
                multiline
                maxRows={4}
                fullWidth
                style={inputStyle}
                />  
              </div>
              <br />
            <div>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker label="Start Date" />
              </LocalizationProvider>
            </div>
            <br />
            <div>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker label="End Date" />
              </LocalizationProvider>
            </div>
              <CompModalButtons>
                <Button type='submit' variant="outlined" style={buttonStyle}>Save Milestone</Button>
                <Button type="button" onClick={handleCloseModal} variant="outlined" style={buttonStyle}>Cancel</Button>
              </CompModalButtons>         
            </form>
          </div>
        }   

        </div>
      }
      {props.crudMethod === "delete" &&
          <div>
            <Alert severity="warning">
              Are you sure you want to delete this milestone?
              <CompModalButtons>
                <Button variant="outlined" onClick={handleDeleteMilestone} style={buttonStyle}>Delete Milestone</Button>
              </CompModalButtons>
            </Alert>
          </div>
        }
    </div>
  );
};

export default CompCRUDFormMilestone;