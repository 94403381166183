import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import './CompModalAnchorLinkComponents.css';
import { updateAnchor } from '../fxAPI/fxAnchorsAPI.js';
import { getComponentsByOrgId } from '../fxAPI/fxComponentsAPI.js';
import { createObjectLink } from '../fxAPI/fxObjectLinksAPI.js';
import CompComponentDashboardItem from './CompComponentDashboardItem.jsx';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
      padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
      padding: theme.spacing(1),
    },
  }));

const CompModalAnchorLinkComponents = (props) => {

  const [error, setError] = useState(null);

const [open, setOpen] = React.useState(false);

/* Components */
  const [componentsByOrgId, setComponentsByOrgData] = useState([]); // Initialize as an array
  /* get Components by orgId */
  const fxAPIFetchComponentsByOrgId = async (orgId) => {
    await getComponentsByOrgId(orgId, setError, setComponentsByOrgData);
    console.log('all components by Org Id');
    console.dir(componentsByOrgId);
  };

  useEffect(() => {
        const currentOrg = localStorage.getItem("fxLocalOrgID");
        fxAPIFetchComponentsByOrgId(currentOrg);
    }, []);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleCreateLink = async (linkAnchorId, linkComponentId) => {
      const related_org = localStorage.getItem("fxLocalOrgID");
      const initial_object_id = linkAnchorId;
      const initial_object_type = 'anchor';
      const linking_object_id = linkComponentId;
      const linking_object_type = 'component';
      const relationship_type = '';
      const related_project = props.relatedProjectId;
  
      await createObjectLink(initial_object_id, initial_object_type, linking_object_id, linking_object_type, relationship_type, related_project, related_org);
    };

  return (
    <React.Fragment>
      <div onClick={handleClickOpen}>Associate Components</div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          Associate Components with this Step
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={(theme) => ({
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme.palette.grey[500],
          })}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <div className='fx-comp-anchor-comp-assign-items-container'>
          {componentsByOrgId.map((componentItem) => (
            <>
            <div>
            <CompComponentDashboardItem componentId={componentItem.component_id} componentImage={componentItem.related_img} componentName={componentItem.name} componentDescription={componentItem.description} componentVersion={componentItem.version} />
            </div>
            <div><Button variant='outlined' onClick={() => handleCreateLink(props.relatedAnchorId, componentItem.component_id)}>Associate Component</Button></div>
            </>
            ))}
          </div>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Save changes
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </React.Fragment>
  );
};

export default CompModalAnchorLinkComponents;