import React, { useState, useContext } from 'react';
import './CompCRUDFormDataElement.css';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import CompModalButtons from './CompCRUDModalButtons';
import CompCRUDModalActionBar from './CompCRUDModalActionBar';
import { createDataElement, updateDataElement, deleteDataElement } from '../fxAPI/fxDataElementsAPI';
import { PanelContext, refreshContext } from '../Contexts/PanelContext';
import { useParams } from "react-router-dom";
import { createObjectLink } from '../fxAPI/fxObjectLinksAPI';

const CompCRUDFormDataElement = (props) => {
  const [currentEditableState, setCurrentEditableState] = useState('view');
  const [dataElementId, setDataElementId] = useState('');
  const [propertyKey, setPropertyKey] = useState('');
  const [propertyFullName, setPropertyFullName] = useState('');
  const [relatedDataSource, setRelatedDataSource] = useState('');
  const [relatedDataClass, setRelatedDataClass] = useState('');
  const [relatedProject, setRelatedProject] = useState('');
  const [relatedOrg, setRelatedOrg] = useState('');
  const [fieldType, setFieldType] = useState('');
  const [dataElementResponse, setDataElementResponse] = useState(null);
  const params = useParams();

  function generateUUID() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      const r = (Math.random() * 16) | 0, v = c === 'x' ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  }

  const { resetElementRefresh, triggerElementRefresh } = useContext(PanelContext);

  const triggerViewMode = (currentViewMode) => {
    setCurrentEditableState(currentViewMode);
  };

  const handleCloseModal = () => {
    refreshContext(resetElementRefresh, triggerElementRefresh, 'dashboard');
    props.onClose();
  };

  const handleCreateDataElement = async (event) => {
    event.preventDefault();
    const newId = generateUUID();
    const relatedProject = params.projectId;
    const relatedOrg = localStorage.getItem("fxLocalOrgID");
    const relatedAnchorId = props.relatedAnchorId;
    try {
      createDataElement(newId, propertyKey, propertyFullName, relatedDataSource, relatedDataClass, relatedProject, relatedOrg, fieldType, setDataElementResponse);
      console.log('Attempting object link creation');
      console.log('Related Anchor ID:', relatedAnchorId);
      console.log('New Data Element ID:', newId);
      console.log('Related Project:', relatedProject);
      console.log('Related Org:', relatedOrg);
      await createObjectLink(
        relatedAnchorId,
        "anchor",
        newId,
        "data-element",
        null,
        relatedProject,
        relatedOrg
      );
    } catch (error) {
      console.error("Error creating data element:", error);
      if (error.response && error.response.status === 500) {
        // Handle internal server error
        console.error("Internal server error occurred.");
      }
    }
    //handleCloseModal();
  };

  const handleUpdateDataElement = async (event) => {
    event.preventDefault();
    await updateDataElement(dataElementId, propertyKey, propertyFullName, relatedDataSource, relatedDataClass, relatedProject, relatedOrg, fieldType);
    handleCloseModal();
  };

  const handleDeleteDataElement = async () => {
    await deleteDataElement(dataElementId, console.error, setDataElementResponse);
    handleCloseModal();
  };

  return (
    <div className={props.crudMethod === "create" && props.formStyle === "darkBG" ? "fx-crud-form-darkBG" : ""}>
      {props.crudMethod === "create" &&
        <div>
          <br />
          <form onSubmit={handleCreateDataElement}>
            <div>
              <TextField id="outlined-basic" label="Property Key" variant="outlined" onChange={(e) => setPropertyKey(e.target.value)} />
            </div>
            <br />
            <div>
              <TextField id="outlined-basic" label="Property Full Name" variant="outlined" onChange={(e) => setPropertyFullName(e.target.value)} />
            </div>
            <br />
            <div>
              <TextField id="outlined-basic" label="Related Data Source" variant="outlined" onChange={(e) => setRelatedDataSource(e.target.value)} />
            </div>
            <br />
            <div>
              <TextField id="outlined-basic" label="Related Data Class" variant="outlined" onChange={(e) => setRelatedDataClass(e.target.value)} />
            </div>
            <br />
            <div>
              <Select
                labelId="field-type-label"
                id="field-type"
                value={fieldType}
                onChange={(e) => setFieldType(e.target.value)}
                displayEmpty
              >
                <MenuItem value="" disabled>Select Field Type</MenuItem>
                <MenuItem value="text">Text</MenuItem>
                <MenuItem value="text-area">Text Area</MenuItem>
                <MenuItem value="dropdown">Dropdown</MenuItem>
                <MenuItem value="radio-buttons">Radio Buttons</MenuItem>
                <MenuItem value="date">Date</MenuItem>
              </Select>
            </div>
            <br />
            <CompModalButtons>
              <Button className="fx-data-element-form-button" type="submit">Create Data Element</Button>
            </CompModalButtons>
          </form>
        </div>
      }
      {props.crudMethod === "view-edit" &&
        <div>
          <CompCRUDModalActionBar>
            {currentEditableState === "view" &&
              <Button onClick={() => triggerViewMode("edit")}>Edit</Button>
            }
            {currentEditableState === "edit" &&
              <Button variant="outlined" onClick={() => triggerViewMode("view")}>Save</Button>
            }
          </CompCRUDModalActionBar>

          {currentEditableState === "view" &&
            <div>
              <div>
                <div className='fx-global-form-read-only-label'>Data Element ID</div>
                <div>{props.currentComponentData.dataElementId}</div>
              </div>
              <div>
                <div className='fx-global-form-read-only-label'>Property Key</div>
                <div>{props.currentComponentData.propertyKey}</div>
              </div>
              <div>
                <div className='fx-global-form-read-only-label'>Property Full Name</div>
                <div>{props.currentComponentData.propertyFullName}</div>
              </div>
              <div>
                <div className='fx-global-form-read-only-label'>Related Data Source</div>
                <div>{props.currentComponentData.relatedDataSource}</div>
              </div>
              <div>
                <div className='fx-global-form-read-only-label'>Related Data Class</div>
                <div>{props.currentComponentData.relatedDataClass}</div>
              </div>
              <div>
                <div className='fx-global-form-read-only-label'>Related Project</div>
                <div>{props.currentComponentData.relatedProject}</div>
              </div>
              <div>
                <div className='fx-global-form-read-only-label'>Related Org</div>
                <div>{props.currentComponentData.relatedOrg}</div>
              </div>
              <div>
                <div className='fx-global-form-read-only-label'>Field Type</div>
                <div>{props.currentComponentData.fieldType}</div>
              </div>
            </div>
          }

          {currentEditableState === "edit" &&
            <div>
              <form onSubmit={handleUpdateDataElement}>
                <div>
                  <TextField id="outlined-basic" label="Data Element ID" variant="outlined" value={dataElementId} onChange={(e) => setDataElementId(e.target.value)} />
                </div>
                <br />
                <div>
                  <TextField id="outlined-basic" label="Property Key" variant="outlined" value={propertyKey} onChange={(e) => setPropertyKey(e.target.value)} />
                </div>
                <br />
                <div>
                  <TextField id="outlined-basic" label="Property Full Name" variant="outlined" value={propertyFullName} onChange={(e) => setPropertyFullName(e.target.value)} />
                </div>
                <br />
                <div>
                  <TextField id="outlined-basic" label="Related Data Source" variant="outlined" value={relatedDataSource} onChange={(e) => setRelatedDataSource(e.target.value)} />
                </div>
                <br />
                <div>
                  <TextField id="outlined-basic" label="Related Data Class" variant="outlined" value={relatedDataClass} onChange={(e) => setRelatedDataClass(e.target.value)} />
                </div>
                <br />
                <div>
                  <TextField id="outlined-basic" label="Related Project" variant="outlined" value={relatedProject} onChange={(e) => setRelatedProject(e.target.value)} />
                </div>
                <br />
                <div>
                  <TextField id="outlined-basic" label="Related Org" variant="outlined" value={relatedOrg} onChange={(e) => setRelatedOrg(e.target.value)} />
                </div>
                <br />
                <div>
                  <TextField id="outlined-basic" label="Field Type" variant="outlined" value={fieldType} onChange={(e) => setFieldType(e.target.value)} />
                </div>
                <br />
                <CompModalButtons>
                  <Button type='submit'>Save Data Element</Button>
                  <Button type="button" onClick={handleCloseModal}>Cancel</Button>
                </CompModalButtons>
              </form>
            </div>
          }
        </div>
      }
    </div>
  );
};

export default CompCRUDFormDataElement;
