import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import './CompAccessibilityReportDetail.css';
import accessibilityGuidelines from '../Data/accessibilityGuidelines.json';
import { getReportsDataByReportId } from '../fxAPI/fxReportsDataAPI';
import avatars from '../Data/avatars.json';

const CompAccessibilityReportDetail = (props) => {
  const params = useParams();

  const [error, setError] = useState(null);

   /* REPORTS BY ORG */
   const [reportsDataData, setReportsDataData] = useState([]); // Initialize as an array
   /* get reports data */
   const fxAPIFetchReportsDataByReportId = async (reportId) => {
     await getReportsDataByReportId(reportId, setError, setReportsDataData);
     console.log('all reports data by Report Id');
     console.dir(reportsDataData);
   };
 
   useEffect(() => {
    if (!params.reportId) {
      console.warn('No reportId available in params');
      return;
    }
  
    console.log('Fetching data for reportId:', params.reportId);
    
    // Fetch only if reportsDataData is empty (prevents unnecessary re-fetch)
    if (reportsDataData.length === 0) {
      fxAPIFetchReportsDataByReportId(params.reportId);
    }
  }, [params.reportId]);

  const uniqueAnchors = [...new Set(reportsDataData.map(report => report.related_anchor))];
  const guidelineRefs = [...new Set(reportsDataData.flatMap(report => report.data.map(item => item.guidelineRef)))];

  return (
    <div>
      <div>
        ... Accessibility Report Detail for ... {params.reportId}
      </div>
      <div>
        <h2>Accessibility Guidelines</h2>
        <ul>
          {accessibilityGuidelines && accessibilityGuidelines.accessibilityGuidelines && accessibilityGuidelines.accessibilityGuidelines.guideline_groups ? (
            accessibilityGuidelines.accessibilityGuidelines.guideline_groups.map((group, index) => (
              <li key={index}>
                <h3>{group.name}</h3>
                <ul>
                  {Object.keys(group.levels).map(level => (
                    <li key={level}>
                      <h4>Level {level}</h4>
                      <ul>
                        {group.levels[level].map((guideline, idx) => (
                          <li key={idx}>{guideline.guideline} ({guideline.id})</li>
                        ))}
                      </ul>
                    </li>
                  ))}
                </ul>
              </li>
            ))
          ) : (
            <li>No guidelines available</li>
          )}
        </ul>
      </div>
      <div>
        <h2>Report Data</h2>
        {reportsDataData.length > 0 ? (
          <div className="report-matrix">
            <div className="matrix-header">
              <div className="matrix-cell"></div>
              {uniqueAnchors.map((anchor, index) => (
                <div key={index} className="matrix-cell">{anchor}</div>
              ))}
            </div>
            {guidelineRefs.map((guidelineRef, index) => (
              <div key={index} className="matrix-row">
                <div className="matrix-cell">{guidelineRef}</div>
                {uniqueAnchors.map((anchor, idx) => {
                  const report = reportsDataData.find(report => report.related_anchor === anchor);
                  const item = report ? report.data.find(item => item.guidelineRef === guidelineRef) : null;
                  return (
                    <div key={idx} className={`matrix-cell ${item ? item.status.toLowerCase() : ''}`}>
                      {item ? item.status : 'N/A'}
                    </div>
                  );
                })}
              </div>
            ))}
          </div>
        ) : (
          <p>No report data available</p>
        )}
      </div>
    </div>
  );
};

export default CompAccessibilityReportDetail;