import React, { useState, useEffect, useContext } from 'react';
import './CompSideContextPanel.css';
import { PanelContext } from '../Contexts/PanelContext';
import Button from '@mui/material/Button';
import CompSubjectivePanel from './CompSubjectivePanel';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import avatars from '../Data/avatars.json';
import CompUserAssignment from './CompUserAssignment';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import CompCRUDFormMilestone from './CompCRUDFormMilestone';
import CompCRUDFormAnchorJourney from './CompCRUDFormAnchorJourney';
import CompCRUDFormObjective from './CompCRUDFormObjective';
import CompCRUDFormComponent from './CompCRUDFormComponent';
import CompCRUDFormDataElement from './CompCRUDFormDataElement';
import CompCRUDFormUserFeedback from './CompCRUDFormUserFeedback';
import CompCRUDFormDesignSession from './CompCRUDFormDesignSession';
import CompCRUDFormResearchElement from './CompCRUDFormResearchElement';
import CompCRUDFormSubjective from './CompCRUDFormSubjective';

const CompSideContextPanel = (props) => {


  const [tabValue, setTabValue] = useState(0);

    const { elements, triggerElementRefresh, resetElementRefresh } = useContext(PanelContext);
  const elementId = 'sideContextPanel';

    //localStorage.setItem("fxSidePanelContext", "project");
  //localStorage.setItem("fxSidePanelObject", "test object");

  const currentSidePanelContext = localStorage.getItem("fxSidePanelContext");
  const currentSidePanelObjectId = localStorage.getItem("fxSidePanelObjectId");
  const currentSidePanelObject = (() => {
    const storedObject = localStorage.getItem("fxSidePanelObject");
    if (storedObject) {
      try {
        return JSON.parse(storedObject);
      } catch (e) {
        console.error("Error parsing JSON from localStorage", e);
        return null;
      }
    }
    return null;
  })();

   // Handle refresh for the specific element
   const handleRefresh = async () => {
    console.log('Refreshing context panel data');
    resetElementRefresh(elementId); // Reset the refresh flag after completion
  };

  const unsetPanel = async () => {
    localStorage.removeItem("fxSidePanelContext");
    localStorage.removeItem("fxSidePanelObjectId");
    localStorage.removeItem("fxSidePanelObject");
    handleRefresh('sideContextPanel');
  };

  // Effect to handle refresh logic when the flag changes
  useEffect(() => {
    if (elements[elementId]?.refresh) {
      handleRefresh(); // Trigger the refresh only when needed
      triggerElementRefresh(elementId); // Re-set refresh flag to prevent re-triggering
      console.log('displaying object on side panel 1: ');
      console.dir(currentSidePanelObject);
    }
  }, [elements[elementId]?.refresh, triggerElementRefresh, resetElementRefresh]);

  const avatar = avatars.avatars.find(a => {
    const relatedAvatarId = currentSidePanelObject?.related_avatar || null;
    return a.id === relatedAvatarId;
  });

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const tabStyles = {
    '& .MuiTab-root': {
      color: 'white',
    },
    '& .Mui-selected': {
      color: '#FFB400',
    },
  };

  return (
    <div>
        {localStorage.getItem("fxSidePanelContext") && (
                <>
                <div><Button onClick={unsetPanel}>Dismiss</Button></div>
                {/* 
                <h2>Item Focus</h2>
                <div>Test Current Panel: {currentSidePanelContext}</div>
                <div>Test Current Object: {currentSidePanelObjectId}</div>
                */}
                {currentSidePanelContext === "objective" && 
                <>
                  <h2>Objective Focus</h2>
                  <div>
                    <div className="fx-comp-side-context-panel-read-only-label">Name</div>
                    <div className="fx-comp-side-context-panel-read-only-item">{currentSidePanelObject.name || currentSidePanelObject.objective_view_name || null}</div>
                    <hr />
                  </div>
                  <div>
                    <div className="fx-comp-side-context-panel-read-only-label">Description</div>
                    <div className="fx-comp-side-context-panel-read-only-item">{currentSidePanelObject.description || currentSidePanelObject.objective_view_description || null}</div>
                    <hr />
                  </div>
                  <div>
                    <div>
                      <div>
                        <div className="fx-comp-side-context-panel-read-only-label">Date Created</div>
                        <CalendarTodayIcon /> {currentSidePanelObject.date_time_created || null}
                      </div>
                      <div>
                        <div className="fx-comp-side-context-panel-read-only-label">Date Due</div>
                        <CalendarTodayIcon /> {currentSidePanelObject.date_due || null}
                      </div>
                    </div>
                    <hr />
                  </div>
                  <Tabs value={tabValue} onChange={handleTabChange} sx={tabStyles}>
              <Tab label="Subjectives" />
              <Tab label="Status" />              
            </Tabs>
            {tabValue === 0 && (
                  <CompSubjectivePanel 
                    //refreshParent={props.refreshParent} 
                    panelName="Subjectives" 
                    relatedObjective={currentSidePanelObject.objective_id}
                    relatedProject={currentSidePanelObject.related_project}
                    deferLoading={true} // Set deferLoading to true
                    //relatedObjective={item.objective_id} 
                    //relatedProject={props.relatedProject} 
                    //refresh={refresh}  // Use refresh to trigger panel update
                    //updatePanelContext={(contextType, objectId, object) => props.updatePanelContext(contextType, objectId, object)}
                  />
            )}
            {tabValue === 1 && (
              <div>Status here...</div>
            )}
                  </>
                }
                {currentSidePanelContext === "subjective" &&
                  <>
                    <h2>Subjective Focus</h2>
                    <div>
                      <div className="fx-comp-side-context-panel-read-only-label">Name</div>
                      <div className="fx-comp-side-context-panel-read-only-item">{currentSidePanelObject.name || currentSidePanelObject.subjective_view_name || null}</div>
                      <hr />
                    </div>
                    <div>
                      <div className="fx-comp-side-context-panel-read-only-label">Description</div>
                      <div className="fx-comp-side-context-panel-read-only-item">{currentSidePanelObject.description || currentSidePanelObject.subjective_view_description || null}</div>
                      <hr />
                    </div>
                    <div>
                      <div>
                        <div className="fx-comp-side-context-panel-read-only-label">Assigned To</div>
                        <CompUserAssignment userAssigned={currentSidePanelObject.user_assigned} />
                      </div>
                      <hr />
                    </div>
                  </>
                }
                {currentSidePanelContext === "project" &&
                  <>
                    <h2>Playbook Focus</h2>
                    <div>
                      <div className="fx-comp-side-context-panel-read-only-label">Name</div>
                      <div className="fx-comp-side-context-panel-read-only-item">{currentSidePanelObject.name || null}</div>
                      <hr />
                    </div>
                      <div>
                      <div className="fx-comp-side-context-panel-read-only-label">Description</div>
                      <div className="fx-comp-side-context-panel-read-only-item">{currentSidePanelObject.description || null}</div>
                      <hr />
                    </div>
                  </>
                }

                {currentSidePanelContext === "persona" &&
                  <>
                    <h2>Persona Focus</h2>
                    <div>
                      {avatar ? (
                        <img 
                          src={avatar.svgPath} 
                          alt={avatar.name} 
                          width="50"
                        />
                      ) : (
                        <div>No avatar available</div>
                      )}
                    </div>
                    <div>
                      <div className="fx-comp-side-context-panel-read-only-label">Name</div>
                      <div className="fx-comp-side-context-panel-read-only-item">{currentSidePanelObject.name || null}</div>
                      <hr />
                    </div>
                    <div>
                      <div className="fx-comp-side-context-panel-read-only-label">Description</div>
                      <div className="fx-comp-side-context-panel-read-only-item">{currentSidePanelObject.description || null}</div>
                      <hr />
                    </div>
                  </>
                }

                {currentSidePanelContext === "business-value" &&
                  <>
                    <h2>Business Value Focus</h2>
                    <div>
                      <div className="fx-comp-side-context-panel-read-only-label">Name</div>
                      <div className="fx-comp-side-context-panel-read-only-item">{currentSidePanelObject.name || null}</div>
                      <hr />
                    </div>
                    <div>
                      <div className="fx-comp-side-context-panel-read-only-label">Description</div>
                      <div className="fx-comp-side-context-panel-read-only-item">{currentSidePanelObject.description || null}</div>
                      <hr />
                    </div>
                  </>
                }

                {currentSidePanelContext === "anchor" &&
                  <>
                    <h2>Journey Step Focus</h2>
                    <div>
                      <div className="fx-comp-side-context-panel-read-only-label">Name</div>
                      <div className="fx-comp-side-context-panel-read-only-item">{currentSidePanelObject.name || null}</div>
                      <hr />
                    </div>
                    <div>
                      <div className="fx-comp-side-context-panel-read-only-label">Description</div>
                      <div className="fx-comp-side-context-panel-read-only-item">{currentSidePanelObject.description || null}</div>
                      <hr />
                    </div>
                  </>
                }

                {currentSidePanelContext === "user-feedback" &&
                  <>
                    <h2>User Feedback Focus</h2>
                    <div>
                      <div className="fx-comp-side-context-panel-read-only-label">Name</div>
                      <div className="fx-comp-side-context-panel-read-only-item">{currentSidePanelObject.name || null}</div>
                      <hr />
                    </div>
                    {/*
                    <div>
                      <div className="fx-comp-side-context-panel-read-only-label">Description</div>
                      <div className="fx-comp-side-context-panel-read-only-item">(Step Description)</div>
                      <hr />
                    </div>
                    */}
                  </>
                }

                {currentSidePanelContext === "data-element" &&
                  <>
                    <h2>Data Element Focus</h2>
                    <div>
                      <div className="fx-comp-side-context-panel-read-only-label">Property Key</div>
                      <div className="fx-comp-side-context-panel-read-only-item">{currentSidePanelObject.property_key || null}</div>
                      <hr />
                    </div>
                    {/*
                    <div>
                      <div className="fx-comp-side-context-panel-read-only-label">Description</div>
                      <div className="fx-comp-side-context-panel-read-only-item">(Step Description)</div>
                      <hr />
                    </div>
                    */}
                  </>
                }

                {currentSidePanelContext === "design-session" &&
                  <>
                    <h2>Design Session Focus</h2>
                    <div>
                      <div className="fx-comp-side-context-panel-read-only-label">Design Session</div>
                      <div className="fx-comp-side-context-panel-read-only-item">{currentSidePanelObject.name || null}</div>
                      <hr />
                    </div>
                    {/*
                    <div>
                      <div className="fx-comp-side-context-panel-read-only-label">Description</div>
                      <div className="fx-comp-side-context-panel-read-only-item">(Step Description)</div>
                      <hr />
                    </div>
                    */}
                  </>
                }

                {currentSidePanelContext === "research-element" &&
                  <>
                    <h2>Research Element Focus</h2>
                    <div>
                      <div className="fx-comp-side-context-panel-read-only-label">Name</div>
                      <div className="fx-comp-side-context-panel-read-only-item">{currentSidePanelObject.name || null}</div>
                      <hr />
                    </div>
                    <div>
                      <div className="fx-comp-side-context-panel-read-only-label">Description</div>
                      <div className="fx-comp-side-context-panel-read-only-item">{currentSidePanelObject.description || null}</div>
                      <hr />
                    </div>
                    {/*
                    <div>
                      <div className="fx-comp-side-context-panel-read-only-label">Description</div>
                      <div className="fx-comp-side-context-panel-read-only-item">(Step Description)</div>
                      <hr />
                    </div>
                    */}
                  </>
                }

                {currentSidePanelContext === "component" &&
                  <>
                    <h2>Component Focus</h2>
                    <CompCRUDFormComponent crudMethod="view-edit" formStyle="darkBG" currentComponentData={currentSidePanelObject} />
                  </>
                }

                {currentSidePanelContext === "create-objective" &&
                  <>
                    <h2>Create Objective</h2>
                    <div>
                      <CompCRUDFormObjective crudMethod="create" formStyle="darkBG" relatedAnchorId={currentSidePanelObjectId} />
                    </div>
                  </>
                }

                {currentSidePanelContext === "create-subjective" &&
                  <>
                    <h2>Create Subjective</h2>
                    <div>
                      <CompCRUDFormSubjective crudMethod="create" formStyle="darkBG" relatedAnchorId={currentSidePanelObjectId} />
                    </div>
                  </>
                }

                {currentSidePanelContext === "create-component" &&
                  <>
                    <h2>Create Component</h2>
                    <div>
                      <CompCRUDFormComponent crudMethod="create" formStyle="darkBG" relatedAnchorId={currentSidePanelObjectId} />
                    </div>
                  </>
                }

                {currentSidePanelContext === "create-data" &&
                  <>
                    <h2>Create Data</h2>
                    <div>
                      <CompCRUDFormDataElement crudMethod="create" formStyle="darkBG" relatedAnchorId={currentSidePanelObjectId} />
                    </div>
                  </>
                }


                {currentSidePanelContext === "create-user-feedback" &&
                  <>
                    <h2>Create User Feedback</h2>
                    <div>
                    <CompCRUDFormUserFeedback crudMethod="create" formStyle="darkBG" relatedAnchorId={currentSidePanelObjectId} relatedObjectId={currentSidePanelObjectId} />
                    </div>
                  </>
                }

                {currentSidePanelContext === "create-design-session" &&
                  <>
                    <h2>Create Design Session</h2>
                    <div>
                    <CompCRUDFormDesignSession crudMethod="create" formStyle="darkBG" relatedAnchorId={currentSidePanelObjectId} />
                    </div>
                  </>
                }

                {currentSidePanelContext === "create-research-element" &&
                  <>
                    <h2>Create Research Element</h2>
                    <div>
                      <CompCRUDFormResearchElement crudMethod="create" formStyle="darkBG" relatedAnchorId={currentSidePanelObjectId} />
                    </div>
                  </>
                }

                {currentSidePanelContext === "create-accessibility" &&
                  <>
                    <h2>Create Accessibility</h2>
                    <div>
                      <CompCRUDFormSubjective crudMethod="create" formStyle="darkBG" relatedAnchorId={currentSidePanelObjectId} tags={["accessibility"]} />
                    </div>
                  </>
                }

                {currentSidePanelContext === "create-milestone" &&
                  <>
                    <h2>Create Milestone</h2>
                    <div>
                      <CompCRUDFormMilestone crudMethod="create" />
                    </div>
                  </>
                }

                {currentSidePanelContext === "delete-milestone" &&
                  <>
                    <h2>Delete Milestone</h2>
                    <div>
                      <CompCRUDFormMilestone crudMethod="delete" />
                    </div>
                  </>
                }

                {currentSidePanelContext === "create-anchor" &&
                  <>
                    <h2>Create Step</h2>
                    <div>
                      ... creating step for path: {currentSidePanelObjectId}
                      <CompCRUDFormAnchorJourney relatedPath={currentSidePanelObjectId} />
                    </div>
                  </>
                }

                {currentSidePanelContext === "show-layers" &&
                  <>
                    <h2>Layers</h2>
                    <div>layer 1</div>
                    <div>layer 2</div>
                  </>
                }


                </>
                
            )}
        
    </div>
  );
};

export default CompSideContextPanel;