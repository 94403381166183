import React, { useState, useContext } from 'react';
import './CompCRUDFormResearchElement.css';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import CompModalButtons from './CompCRUDModalButtons';
import CompCRUDModalActionBar from './CompCRUDModalActionBar';
import { createResearchElement, updateResearchElement, deleteResearchElement } from '../fxAPI/fxResearchElementsAPI';
import { PanelContext, refreshContext } from '../Contexts/PanelContext';
import { createObjectLink } from '../fxAPI/fxObjectLinksAPI.js';
import { useParams } from "react-router-dom";

const CompCRUDFormResearchElement = (props) => {
  const [currentEditableState, setCurrentEditableState] = useState('view');
  const [researchElementId, setResearchElementId] = useState('');
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [relatedFiles, setRelatedFiles] = useState('');
  const [relatedLinks, setRelatedLinks] = useState('');
  const [dateTimeCreated, setDateTimeCreated] = useState('');
  const [dateTimeUpdated, setDateTimeUpdated] = useState('');
  const [relatedResearchType, setRelatedResearchType] = useState('');
  const [relatedResearchFocus, setRelatedResearchFocus] = useState('');
  const [relatedOrg, setRelatedOrg] = useState('');
  const [researchElementResponse, setResearchElementResponse] = useState(null);
  const params = useParams();

  function generateUUID() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      const r = (Math.random() * 16) | 0, v = c === 'x' ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  }

  const { resetElementRefresh, triggerElementRefresh } = useContext(PanelContext);

  const triggerViewMode = (currentViewMode) => {
    setCurrentEditableState(currentViewMode);
  };

  const handleCloseModal = () => {
    refreshContext(resetElementRefresh, triggerElementRefresh, 'dashboard');
    props.onClose();
  };

  const handleCreateResearchElement = async (event) => {
    event.preventDefault();
    const newId = generateUUID();
    const relatedOrg = localStorage.getItem("fxLocalOrgID");
    const relatedAnchorId = props.relatedAnchorId;
    try {
      await createResearchElement(newId, name, description, null, null, null, null, relatedResearchType, relatedResearchFocus, relatedOrg, setResearchElementResponse);
      await createObjectLink(
        relatedAnchorId,
        "anchor",
        newId,
        "research-element",
        null,
        params.projectId,
        relatedOrg
      );
    } catch (error) {
      console.error("Error creating research element:", error);
    }
    //handleCloseModal();
  };

  const handleUpdateResearchElement = async (event) => {
    event.preventDefault();
    await updateResearchElement(researchElementId, name, description, relatedFiles, relatedLinks, dateTimeCreated, dateTimeUpdated, relatedResearchType, relatedResearchFocus, relatedOrg);
    handleCloseModal();
  };

  const handleDeleteResearchElement = async () => {
    await deleteResearchElement(researchElementId, console.error, setResearchElementResponse);
    handleCloseModal();
  };

  return (
    <div className={props.crudMethod === "create" && props.formStyle === "darkBG" ? "fx-crud-form-darkBG" : ""}>
      {props.crudMethod === "create" &&
        <div>
          <br />
          <form onSubmit={handleCreateResearchElement}>
            <div>
              <TextField id="outlined-basic" label="Name" variant="outlined" onChange={(e) => setName(e.target.value)} />
            </div>
            <br />
            <div>
              <TextField
                id="outlined-multiline-static"
                label="Description"
                multiline
                rows={4}
                variant="outlined"
                onChange={(e) => setDescription(e.target.value)}
              />
            </div>
            <br />
            <div>
              <Select
                labelId="related-research-type-label"
                id="related-research-type"
                value={relatedResearchType}
                onChange={(e) => setRelatedResearchType(e.target.value)}
                displayEmpty
              >
                <MenuItem value="" disabled>Select Related Research Type</MenuItem>
                <MenuItem value="observations">Observations</MenuItem>
                <MenuItem value="opportunities">Opportunities</MenuItem>
                <MenuItem value="challenges">Challenges</MenuItem>
                <MenuItem value="personas">Personas</MenuItem>
                <MenuItem value="competitive-analysis">Competitive Analysis</MenuItem>
                <MenuItem value="user-stories">User Stories</MenuItem>
              </Select>
            </div>
            <br />
            <div>
              <Select
                labelId="related-research-focus-label"
                id="related-research-focus"
                value={relatedResearchFocus}
                onChange={(e) => setRelatedResearchFocus(e.target.value)}
                displayEmpty
              >
                <MenuItem value="" disabled>Select Related Research Focus</MenuItem>
                <MenuItem value="planning">Planning</MenuItem>
                <MenuItem value="activities">Activities</MenuItem>
                <MenuItem value="resources">Resources</MenuItem>
                <MenuItem value="execution">Execution</MenuItem>
              </Select>
            </div>
            <br />
            <CompModalButtons>
              <Button className="fx-component-form-button" type="submit">Create Research Element</Button>
            </CompModalButtons>
          </form>
        </div>
      }
      {props.crudMethod === "view-edit" &&
        <div>
          <CompCRUDModalActionBar>
            {currentEditableState === "view" &&
              <Button onClick={() => triggerViewMode("edit")}>Edit</Button>
            }
            {currentEditableState === "edit" &&
              <Button variant="outlined" onClick={() => triggerViewMode("view")}>Save</Button>
            }
          </CompCRUDModalActionBar>

          {currentEditableState === "view" &&
            <div>
              <div>
                <div className='fx-global-form-read-only-label'>Research Element ID</div>
                <div>{props.currentComponentData.researchElementId}</div>
              </div>
              <div>
                <div className='fx-global-form-read-only-label'>Name</div>
                <div>{props.currentComponentData.name}</div>
              </div>
              <div>
                <div className='fx-global-form-read-only-label'>Description</div>
                <div>{props.currentComponentData.description}</div>
              </div>
              <div>
                <div className='fx-global-form-read-only-label'>Related Files</div>
                <div>{props.currentComponentData.relatedFiles}</div>
              </div>
              <div>
                <div className='fx-global-form-read-only-label'>Related Links</div>
                <div>{props.currentComponentData.relatedLinks}</div>
              </div>
              <div>
                <div className='fx-global-form-read-only-label'>Date Time Created</div>
                <div>{props.currentComponentData.dateTimeCreated}</div>
              </div>
              <div>
                <div className='fx-global-form-read-only-label'>Date Time Updated</div>
                <div>{props.currentComponentData.dateTimeUpdated}</div>
              </div>
              <div>
                <div className='fx-global-form-read-only-label'>Related Research Type</div>
                <div>{props.currentComponentData.relatedResearchType}</div>
              </div>
              <div>
                <div className='fx-global-form-read-only-label'>Related Research Focus</div>
                <div>{props.currentComponentData.relatedResearchFocus}</div>
              </div>
              <div>
                <div className='fx-global-form-read-only-label'>Related Org</div>
                <div>{props.currentComponentData.relatedOrg}</div>
              </div>
            </div>
          }

          {currentEditableState === "edit" &&
            <div>
              <form onSubmit={handleUpdateResearchElement}>
                <div>
                  <TextField id="outlined-basic" label="Research Element ID" variant="outlined" value={researchElementId} onChange={(e) => setResearchElementId(e.target.value)} />
                </div>
                <br />
                <div>
                  <TextField id="outlined-basic" label="Name" variant="outlined" value={name} onChange={(e) => setName(e.target.value)} />
                </div>
                <br />
                <div>
                  <TextField
                    id="outlined-multiline-static"
                    label="Description"
                    multiline
                    rows={4}
                    variant="outlined"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                  />
                </div>
                <br />
                <div>
                  <TextField id="outlined-basic" label="Related Files" variant="outlined" value={relatedFiles} onChange={(e) => setRelatedFiles(e.target.value)} />
                </div>
                <br />
                <div>
                  <TextField id="outlined-basic" label="Related Links" variant="outlined" value={relatedLinks} onChange={(e) => setRelatedLinks(e.target.value)} />
                </div>
                <br />
                <div>
                  <TextField id="outlined-basic" label="Date Time Created" variant="outlined" value={dateTimeCreated} onChange={(e) => setDateTimeCreated(e.target.value)} />
                </div>
                <br />
                <div>
                  <TextField id="outlined-basic" label="Date Time Updated" variant="outlined" value={dateTimeUpdated} onChange={(e) => setDateTimeUpdated(e.target.value)} />
                </div>
                <br />
                <div>
                  <Select
                    labelId="related-research-type-label"
                    id="related-research-type"
                    value={relatedResearchType}
                    onChange={(e) => setRelatedResearchType(e.target.value)}
                    displayEmpty
                  >
                    <MenuItem value="" disabled>Select Related Research Type</MenuItem>
                    <MenuItem value="observations">Observations</MenuItem>
                    <MenuItem value="opportunities">Opportunities</MenuItem>
                    <MenuItem value="challenges">Challenges</MenuItem>
                    <MenuItem value="personas">Personas</MenuItem>
                    <MenuItem value="competitive-analysis">Competitive Analysis</MenuItem>
                    <MenuItem value="user-stories">User Stories</MenuItem>
                  </Select>
                </div>
                <br />
                <div>
                  <Select
                    labelId="related-research-focus-label"
                    id="related-research-focus"
                    value={relatedResearchFocus}
                    onChange={(e) => setRelatedResearchFocus(e.target.value)}
                    displayEmpty
                  >
                    <MenuItem value="" disabled>Select Related Research Focus</MenuItem>
                    <MenuItem value="planning">Planning</MenuItem>
                    <MenuItem value="activities">Activities</MenuItem>
                    <MenuItem value="resources">Resources</MenuItem>
                    <MenuItem value="execution">Execution</MenuItem>
                  </Select>
                </div>
                <br />
                <CompModalButtons>
                  <Button type='submit'>Save Research Element</Button>
                  <Button type="button" onClick={handleCloseModal}>Cancel</Button>
                </CompModalButtons>
              </form>
            </div>
          }
        </div>
      }
    </div>
  );
};

export default CompCRUDFormResearchElement;
