import axios from 'axios';

const createDataElement = async (data_element_id, property_key, property_full_name, related_data_source, related_data_class, related_project, related_org, field_type, setDataElementResponse) => {
  try {
    if (!data_element_id || !property_key) {
      console.warn('Missing required parameters: data_element_id or property_key.');
      return;
    }

    console.log(`Creating data element with ID: ${data_element_id}, key: ${property_key}`);

    const response = await axios.post('/fxapi/data-elements/create', {
      data_element_id,
      property_key,
      property_full_name,
      related_data_source,
      related_data_class,
      related_project,
      related_org,
      field_type
    });

    console.log('API response received:', response.data);

    setDataElementResponse(response.data);
  } catch (error) {
    console.error('Error creating data element:', error);
  }
};

const getDataElementsByOrg = async (orgId, setError, setDataElementsByOrgData) => {
  setError(null);
  setDataElementsByOrgData([]);

  if (!orgId) {
    setError('orgId parameter is required');
    return [];
  }

  try {
    console.log(`Fetching data elements for orgId: ${orgId}`);
    const response = await axios.get('/fxapi/data-elements/data-elements-by-org', {
      params: { orgId }
    });
    console.log('API response received:', response.data);

    const items = Array.isArray(response.data) ? response.data : [];
    setDataElementsByOrgData(items);
    return items;
  } catch (error) {
    console.error('Error fetching data elements by org:', error);
    setError(error.response ? error.response.data : 'Failed to fetch data elements by org');
    return [];
  }
};

export const getDataElementsByAnchor = async (anchorId, setError) => {
  try {
    const response = await axios.get('/fxapi/data-elements/data-elements-by-anchor', {
      params: { anchorId }
    });
    console.log('API response received:', response.data);

    return response.data;
  } catch (error) {
    console.error('Error fetching data elements:', error);
    if (setError) {
      setError(error.response ? error.response.data : 'Failed to fetch data elements');
    }
    return [];
  }
};

const updateDataElement = async (data_element_id, property_key, property_full_name, related_data_source, related_data_class, related_project, related_org, field_type) => {
  try {
    if (!data_element_id) {
      console.warn('Missing required parameter: data_element_id.');
      return;
    }

    console.log(`Updating data element with ID: ${data_element_id}`);

    const response = await axios.put(`/fxapi/data-elements/update/${data_element_id}`, {
      property_key,
      property_full_name,
      related_data_source,
      related_data_class,
      related_project,
      related_org,
      field_type
    });

    console.log('API response received:', response.data);
  } catch (error) {
    console.error('Error updating data element:', error);
  }
};

const deleteDataElement = async (dataElementId, setError, setDeleteResponse) => {
  setError(null);
  setDeleteResponse(null);

  if (!dataElementId) {
    setError('dataElementId parameter is required');
    return;
  }

  try {
    console.log(`Deleting data element with ID: ${dataElementId}`);
    const response = await axios.delete(`/fxapi/data-elements/delete/${dataElementId}`);
    console.log('API response received:', response.data);

    const successMessage = `Successfully deleted data element with ID: ${dataElementId}`;
    setDeleteResponse(successMessage);
    console.log('Updated delete response:', successMessage);
  } catch (error) {
    console.error('Error deleting data element:', error);

    const errorMessage = error.response ? error.response.data : 'Failed to delete data element';
    setError(typeof errorMessage === 'string' ? errorMessage : JSON.stringify(errorMessage));
  }
};

export { createDataElement, getDataElementsByOrg, updateDataElement, deleteDataElement };
