import React, { useRef, useState, useEffect, useCallback } from 'react';
import './CompUIAssetAnchorModal.css';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import EditIcon from '@mui/icons-material/Edit';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import ListSubheader from '@mui/material/ListSubheader';
import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/Info';
import PersonalVideoIcon from '@mui/icons-material/PersonalVideo';
import { getUIAssetsByAnchorId } from '../fxAPI/fxUIAssetsAPI.js';
import Tooltip from "@mui/material/Tooltip";

const CompUIAssetAnchorModal = (props) => {

  const [error, setError] = useState(null);

    const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [currentLGImg, setCurrentLGImg] = useState(null);
  const [currentLGImgTitle, setCurrentLGImgTitle] = useState(null);

  const handleChangeLGImg = (img, title) => {
    setCurrentLGImg(img);
    setCurrentLGImgTitle(title);
  };


  /* UI Assets by anchor id */
  const [uiAssetsByAnchorId, setUIAssetsByAnchorData] = useState([]); // Initialize as an array
  /* get UI Assets by projectId */
  const fxAPIFetchUIAssetsByAnchorId = async (anchorId) => {
    await getUIAssetsByAnchorId(anchorId, setError, setUIAssetsByAnchorData);
    console.log('all ui assets by anchor Id');
    console.dir(uiAssetsByAnchorId);
  };

  useEffect(() => {
    const currentOrgId = localStorage.getItem("fxLocalOrgID");
    fxAPIFetchUIAssetsByAnchorId(props.anchorId);
  }, [props.anchorId]);

  return (
    <div>
      <Tooltip title="UI Asset for this Step">
        <div onClick={handleClickOpen}><PersonalVideoIcon /></div>
      </Tooltip>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="lg"
      >
        <DialogTitle id="alert-dialog-title">
            UI Assets for Anchor ID: {props.anchorId}
        </DialogTitle>
        <DialogContent>
          {/* 
          <div>Test Image Association: </div>
        {uiAssetsByAnchorId.map((uiAsset, index) => (
          <div key={uiAsset.ui_asset_id}>{uiAsset.ui_asset_id}
          </div>
        ))}
        */}
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <div style={styles.container}>
              {uiAssetsByAnchorId.map((image) => (
                <>
                  <div>
                    <img
                      key={image.ui_asset_id}
                      src={image.img_full_url}
                      alt={image.name}
                      style={styles.image}
                    />
                    <Button onClick={() => handleChangeLGImg(image.img_full_url, image.name)}>View</Button>
                  </div>
                </>
              ))}
              </div>
          </Grid>
          <Grid item xs={8}>
            <div>{currentLGImgTitle}</div>
            <img src={currentLGImg} width="100%" height="auto" />
          </Grid>
      </Grid>

            
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    maxHeight: '600px',
    overflowY: 'scroll',
    width: '200px'
  },
  image: {
    width: '200px',
    height: '200px',
    objectFit: 'cover',
    marginBottom: '8px',
  },
};

export default CompUIAssetAnchorModal;