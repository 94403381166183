import { useState, useEffect, useContext } from "react";
import './CompGlobalTaskBar.css';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { getProjectsByOrgId } from '../fxAPI/fxProjectsAPI.js';
import { getViewObjectivesProjectsByOrgId } from '../fxAPI/fxObjectivesAPI.js';
import { getSubjectivesObjectivesByOrgId } from '../fxAPI/fxSubjectivesAPI.js';
import CompChatDrawer from "./CompChatDrawer.jsx";
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';
import FormatAlignCenterIcon from '@mui/icons-material/FormatAlignCenter';
import FormatAlignRightIcon from '@mui/icons-material/FormatAlignRight';
import FormatAlignJustifyIcon from '@mui/icons-material/FormatAlignJustify';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import WidthFullIcon from '@mui/icons-material/WidthFull';
import ViewColumnIcon from '@mui/icons-material/ViewColumn';
import { PanelContext } from '../Contexts/PanelContext';


const CompGlobalTaskBar = (props) => {

  const [error, setError] = useState(null);

  const localEnv = localStorage.getItem('fxEnv');

  /* PROJECTS */
  const [projectsByOrgId, setProjectsByOrgData] = useState([]); // Initialize as an array
  const [totalProjects, setTotalProjects] = useState(0); // State for total projects

  /* get user personas by orgId */
  const fxAPIFetchProjectsByOrgId = async (orgId) => {
    await getProjectsByOrgId(orgId, setError, (data) => {
      setProjectsByOrgData(data); // Set the projects data
      setTotalProjects(data.length); // Update total with the length of the returned data
    });
    console.log('all projects by org Id');
    console.dir(projectsByOrgId);
  };

  /* OBJECTIVES */
  const [viewObjectivesProjectsByOrg, setViewObjectivesProjectsByOrgData] = useState([]); // Initialize as an array
  const [totalObjectives, setTotalObjectives] = useState(0); // State for total projects
  /* get objectives by orgId */
  const fxAPIFetchViewObjectivesProjectsByOrgId = async (orgId) => {
    await getViewObjectivesProjectsByOrgId(orgId, setError, (data) => {
      setViewObjectivesProjectsByOrgData(data); // Set the projects data
      setTotalObjectives(data.length); // Update total with the length of the returned data
    });
    console.log('all view objectives projects by org Id');
    console.dir(viewObjectivesProjectsByOrg);
  };

  /* SUBJECTIVES */

  const [subjectivesObjectivesByOrgId, setSubjectivesObjectivesByOrgData] = useState([]); // Initialize as an array
  const [totalSubjectives, setTotalSubjectives] = useState(0); // State for total projects
  /* get subjective - objective by orgId */
  const fxAPIFetchSubjectivesObjectivesByOrgId = async (orgId) => {
    await getViewObjectivesProjectsByOrgId(orgId, setError, (data) => {
      setSubjectivesObjectivesByOrgData(data); // Set the projects data
      setTotalSubjectives(data.length); // Update total with the length of the returned data
    });
    console.log('all subjectives by org Id');
    console.dir(subjectivesObjectivesByOrgId);
  };

  useEffect(() => {
    const currentOrgId = localStorage.getItem("fxLocalOrgID");
    fxAPIFetchProjectsByOrgId(currentOrgId);
    fxAPIFetchViewObjectivesProjectsByOrgId(currentOrgId);
    fxAPIFetchSubjectivesObjectivesByOrgId(currentOrgId);
  }, []);

  const [screenLayout, setScreenLayout] = useState('columns');

  const handleScreenToggle = (event, newScreenLayout) => {
    console.log('taskbar setting layout as: ' + newScreenLayout);
    setScreenLayout(newScreenLayout);
    localStorage.setItem("fxScreenLayout", newScreenLayout);
    handleRefreshElement();
  };

    /* Use Global Context to refresh Subjective Panel */
    const { triggerElementRefresh } = useContext(PanelContext);

    const handleRefreshElement = () => {
      triggerElementRefresh('mainLayout'); // Trigger refresh for the subjectivePanel
    };

    const localUserRole = localStorage.getItem("fxLocalUserRole");

  return (
    <>
    {localUserRole !== 'end-user' &&
    <div className="fx-comp-global-task-bar-container">
      {/*
      {localEnv !== 'production' &&
        <CompChatDrawer />
      }
        <ToggleButtonGroup
          value={screenLayout}
          exclusive
          onChange={handleScreenToggle}
          aria-label="text alignment"
          size="small"
          style={{
            height: `28px`
          }}
        >
          <ToggleButton value="columns" aria-label="left aligned">
            <ViewColumnIcon />
          </ToggleButton>
          <ToggleButton value="full" aria-label="full">
            <WidthFullIcon />
          </ToggleButton>
        </ToggleButtonGroup>
        */}
        <div className="fx-comp-global-task-bar-totals-item"><div className="fx-comp-global-task-bar-totals-title">Playbooks: </div><div className="fx-comp-global-task-bar-totals-number">{totalProjects}</div></div>
        <div className="fx-comp-global-task-bar-totals-item"><div className="fx-comp-global-task-bar-totals-title">Objectives: </div><div className="fx-comp-global-task-bar-totals-number">{totalObjectives}</div></div>
        <div className="fx-comp-global-task-bar-totals-item"><div className="fx-comp-global-task-bar-totals-title">Subjectives: </div><div className="fx-comp-global-task-bar-totals-number">{totalSubjectives}</div></div>
        {/*    
      <div>
      <Button variant='outlined'>Create</Button>
      </div>
      */}
    </div>
    }
    </>
  );
};

export default CompGlobalTaskBar;