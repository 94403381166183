import React, { useEffect, useState } from "react";
import './CompAccessiblityDashboard.css';
import axe from "axe-core";
import CompCollapsibleContainer from "./CompCollapsibleContainer";
import CompOrgAccessibilityChecksList from "./CompOrgAccessibilityChecksList";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import CompAccessibilityReportList from "./CompAccessibilityReportList";


const CompAccessiblityDashboard = (props) => {

  const [tabValue, setTabValue] = useState(0);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <div>
      <Tabs value={tabValue} onChange={handleTabChange}>
              <Tab label="Organization Compliance Checks" />
              <Tab label="Accessibility Reports" />
              {/*<Tab label="Accessibility Tests" />*/}      
            </Tabs>
      {tabValue === 0 && (
        <>
             <CompOrgAccessibilityChecksList />   
        </>
      )}
      {tabValue === 1 && (
        <>
             <CompAccessibilityReportList />  
        </>
      )}
      {/*
      {tabValue === 1 && (
        <>
             <h2>Manual Checks</h2>
             <div>Manual Checks here ...</div>   
        </>
      )}
      {tabValue === 2 && (
        <>
             <h2>Accessibility Tests</h2>  
             <div>Accessibility Tests here ...</div>  
        </>
      )}
        */}
    </div>
  );
};

export default CompAccessiblityDashboard;