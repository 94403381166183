import React, { useState, useEffect, useContext } from 'react';
import './CompSideNavObjectiveCreate.css';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { useParams } from "react-router-dom";
import { createObjective } from '../fxAPI/fxObjectivesAPI.js';
import { getAnchorsByProjectId } from '../fxAPI/fxAnchorsAPI.js';
import { getMilestonesByProjectId } from '../fxAPI/fxMilestonesAPI.js';
import { createSubjective } from '../fxAPI/fxSubjectivesAPI.js';
import objectiveTemplates from '../Data/ObjectiveTemplates/template-options.json';
import accessibilityTasks from '../Data/ObjectiveTemplates/accessibility-basic.json';
import uiReviewTasks from '../Data/ObjectiveTemplates/ui-review.json';
import { PanelContext } from '../Contexts/PanelContext';

const CompSideNavObjectiveCreate = (props) => {

    const [error, setError] = useState(null);
    
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [newMilestone, setNewMilestone] = useState(null);
  const [milestonesByProject, setMilestonesByProjectData] = useState([]);
  const [assignedAnchor, setAssignedAnchor] = useState(props.relatedAnchorId);
  const [anchorsByProject, setAnchorsByProjectData] = useState([]);
  const [useTemplate, setUseTemplate] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState('');
  const params = useParams();
  const { triggerElementRefresh } = useContext(PanelContext);

  const fxAPIFetchAnchorsByProjectId = async (projectId) => {
    await getAnchorsByProjectId(projectId, setError, setAnchorsByProjectData);
  };

  const fxAPIFetchMilestonesByProjectId = async (projectId) => {
    await getMilestonesByProjectId(projectId, setError, setMilestonesByProjectData);
  };

  useEffect(() => {
    fxAPIFetchAnchorsByProjectId(params.compId);
    fxAPIFetchMilestonesByProjectId(params.compId);
  }, []);

  const handleCreateObjective = async (event) => {
    event.preventDefault();
    const newRelatedProject = params.projectId;
    const relatedOrg = localStorage.getItem("fxLocalOrgID");
    const relatedAnchor = assignedAnchor;
    const progress = '0';
    const objectiveId = generateUUID();
    const relatedBusinessValue = null;
    const relatedMilestone = newMilestone;

    await createObjective(objectiveId, name, description, relatedOrg, newRelatedProject, progress, relatedAnchor, relatedMilestone, relatedBusinessValue, null);
  
    triggerElementRefresh('objectiveList');
    props.onObjectiveCreated();

    if(selectedTemplate) {
      triggerTasks(selectedTemplate, objectiveId); 
    }

    //handleCloseModal(); // Close the modal after creating the objective
  };

  const handleTemplateChange = (event) => {
    setSelectedTemplate(event.target.value);
  };

  const triggerTasks = (templateKey, newObjectiveId) => {
    let tasks = [];

    switch (templateKey) {
      case 'accessibility-basic':
        tasks = accessibilityTasks.subjectives;
        break;
      case 'ui-review':
        tasks = uiReviewTasks.subjectives;
        break;
      default:
        tasks = [];
    }

    tasks.forEach((task) => createTask(task, newObjectiveId));
  };

  const createTask = (task, newObjectiveId) => {
    const currentOrgId = localStorage.getItem("fxLocalOrgID");
    const relatedProject = params.projectId;

    createSubjective(task.name, task.description, currentOrgId, newObjectiveId, relatedProject);
  };

  function generateUUID() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      const r = (Math.random() * 16) | 0, v = c === 'x' ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  }
  

  return (
    <div>
      <form onSubmit={handleCreateObjective}>
        <div>
          <label style={{ color: 'white' }} htmlFor="objective-name">Objective Name</label>
          <TextField
            id="objective-name"
            variant="outlined"
            onChange={(e) => setName(e.target.value)}
            fullWidth
            InputProps={{ style: { backgroundColor: 'white' } }}
          />
        </div>   
        <br />
        <div>
          <label style={{ color: 'white' }} htmlFor="objective-description">Objective Description</label>
          <TextField
            id="objective-description"
            multiline
            maxRows={4}
            onChange={(e) => setDescription(e.target.value)}
            fullWidth
            InputProps={{ style: { backgroundColor: 'white' } }}
          />  
        </div>
        <br />
        <div className="fx-material-override-select-wrap">
          <label style={{ color: 'white' }} htmlFor="new-milestone">Assigned Milestone</label><br />
          <select id="new-milestone" name="new-milestone" onChange={(e) => setNewMilestone(e.target.value)}>
            <option>Select Milestone...</option>
            {milestonesByProject.map((milestone) => (
              <option key={milestone.milestone_id} value={milestone.milestone_id}>{milestone.name}</option>
            ))}
          </select>
        </div>
        <br />
        <div>
          <label style={{ color: 'white' }}>Use an Objective Template?</label><br />
          <label>
            <input
              type="radio"
              name="useTemplate"
              value="yes"
              onChange={() => setUseTemplate(true)}
            />
            Yes
          </label>
          <label>
            <input
              type="radio"
              name="useTemplate"
              value="no"
              onChange={() => {
                setUseTemplate(false);
                setSelectedTemplate('');
              }}
            />
            No
          </label>

          {useTemplate && (
            <div className="fx-material-override-select-wrap">
              <label style={{ color: 'white' }} htmlFor="template-select">Select a Template</label><br />
              <select id="template-select" value={selectedTemplate} onChange={handleTemplateChange}>
                <option value="">-- Select Template --</option>
                {objectiveTemplates.templateOptions.map((template) => (
                  <option key={template.key} value={template.key}>
                    {template.name}
                  </option>
                ))}
              </select>
            </div>
          )}
        </div>
        <br />
        <div className="fx-material-override-select-wrap">
          <label style={{ color: 'white' }} htmlFor="assigned-anchor">Assigned Anchor</label><br />
          <select id="assigned-anchor" name="assigned anchor" onChange={(e) => setAssignedAnchor(e.target.value)}>
            <option value={props.relatedAnchorId} selected>Current Anchor...</option>
            {anchorsByProject.map((anchor) => (
              <option key={anchor.anchor_id} value={anchor.anchor_id}>{anchor.name}</option>
            ))}
          </select>
        </div>
        <br />
        <Button type="submit" variant="contained" color="primary" fullWidth>Create Objective</Button>
      </form>
    </div>
  );
};

export default CompSideNavObjectiveCreate;