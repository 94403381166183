import React, { useState, useEffect } from 'react';
import CompDashCardGallery from './CompDashCardGallery';

const CompJourneyMapGallery = (props) => {
    return (
        <div>
                <CompDashCardGallery
                galleryType="journey-map"
                relatedProject={props.relatedProject}
                />
        </div>
    );
};

export default CompJourneyMapGallery;
