import React, { useState } from 'react';
import { TextField, Button, Box, CircularProgress } from '@mui/material';
import axios from 'axios';
import './CompHyperlogicDashboard.css';
import CompHyperlogicDashCard from './CompHyperlogicDashCard';

const CompHyperlogicDashboard = () => {
    const [prompt, setPrompt] = useState('');
    const [researchData, setResearchData] = useState({
        Observations: [],
        Opportunities: [],
        Challenges: [],
        UserPersonas: [],
        CompetitiveAnalysis: [],
    });
    const [loading, setLoading] = useState(false);

    const handleGenerateIdeas = async () => {
        setLoading(true); // Set loading to true when the request starts
        try {
            const response = await axios.post('http://localhost:4000/hyperlogic/research-start', { prompt });
            console.log('Research Data:', response.data.researchData);
            setResearchData(response.data.researchData); // Update state after receiving the response
        } catch (error) {
            console.error('Error generating research data:', error.message);
        } finally {
            setLoading(false); // Set loading to false when the request completes
        }
    };

    const handleRemoveItem = (column, index) => {
        console.log(`Removing item from column: ${column}, index: ${index}`); // Log the column and index
        setResearchData((prevData) => {
            const updatedColumn = [...prevData[column]];
            updatedColumn.splice(index, 1); // Remove the item at the specified index
            return {
                ...prevData,
                [column]: updatedColumn, // Update the column with the remaining items
            };
        });
    };

    const handleAddItem = async (column) => {
        console.log(`Adding new items to column: ${column}`); // Log the column
        try {
            const response = await axios.post('http://localhost:4000/hyperlogic/research-add', {
                prompt, // Reuse the same prompt
                type: column,
                existingData: researchData[column] // Send existing data to avoid duplicates
            });
            const additionalData = response.data.additionalData;

            setResearchData((prevData) => {
                const updatedColumn = [...prevData[column], ...additionalData];
                return {
                    ...prevData,
                    [column]: updatedColumn, // Update the column with the new items
                };
            });
        } catch (error) {
            console.error(`Error adding items to ${column}:`, error.message);
        }
    };

    const renderNestedData = (data) => {
        if (!data || !data.heading || !data.description) {
            return <p>No data available.</p>; // Handle cases where data is missing or incomplete
        }
        return (
            <div>
                {/*<strong>{data.heading}</strong>*/}
                <p>{data.description}</p>
            </div>
        );
    };

    return (
        <div>
            <div>Use a prompt to generate research ideas.</div>
            <Box 
                sx={{ 
                    display: 'flex', 
                    alignItems: 'center', 
                    gap: 1, 
                    width: '100%',
                    marginBottom: '16px'
                }}
            >
                <TextField 
                    sx={{ flexGrow: 1 }} 
                    variant="outlined" 
                    placeholder="Enter your prompt..." 
                    value={prompt}
                    onChange={(e) => setPrompt(e.target.value)}
                />
                <Button 
                    sx={{ flexGrow: 0 }} 
                    variant="contained" 
                    color="primary"
                    onClick={handleGenerateIdeas}
                    disabled={loading} // Disable button while loading
                >
                    {loading ? <CircularProgress size={24} /> : 'Get Ideas'}
                </Button>
            </Box>
            <div className="fx-comp-hyperlogic-dashboard-dash-header-wrapper">
                <div className="fx-comp-hyperlogic-dashboard-dash-heading">
                    <div>Observations</div>
                </div>
                <div className="fx-comp-hyperlogic-dashboard-dash-heading">
                    <div>Opportunities</div>
                </div>
                <div className="fx-comp-hyperlogic-dashboard-dash-heading">
                    <div>Challenges</div>
                </div>
                <div className="fx-comp-hyperlogic-dashboard-dash-heading">
                    <div>User Personas</div>
                </div>
                <div className="fx-comp-hyperlogic-dashboard-dash-heading">
                    <div>Competitive Analysis</div>
                </div>
            </div>

            <div className="fx-comp-hyperlogic-dashboard-dash-columns-wrapper">
                <div className="fx-comp-hyperlogic-dashboard-dash-column">
                    {researchData.Observations.map((obs, index) => (
                        <CompHyperlogicDashCard 
                            key={index} 
                            title={obs.heading || `Observation ${index + 1}`} 
                            description={obs.description} // Pass description as a prop
                            columnHeading="Observations" // Pass column heading
                            onRemove={() => handleRemoveItem('Observations', index)} // Pass onRemove prop
                        />
                    ))}
                    {researchData.Observations.length > 0 && ( // Show button only if cards exist
                        <Button 
                            variant="outlined" 
                            color="primary" 
                            onClick={() => handleAddItem('Observations')} // Fetch more Observations
                        >
                            Add Observation
                        </Button>
                    )}
                </div>
                <div className="fx-comp-hyperlogic-dashboard-dash-column">
                    {researchData.Opportunities.map((opp, index) => (
                        <CompHyperlogicDashCard 
                            key={index} 
                            title={opp.heading || `Opportunity ${index + 1}`} 
                            description={opp.description} // Pass description as a prop
                            columnHeading="Opportunities" // Pass column heading
                            onRemove={() => handleRemoveItem('Opportunities', index)} // Pass onRemove prop
                        />
                    ))}
                    {researchData.Opportunities.length > 0 && ( // Show button only if cards exist
                        <Button 
                            variant="outlined" 
                            color="primary" 
                            onClick={() => handleAddItem('Opportunities')} // Fetch more Opportunities
                        >
                            Add Opportunity
                        </Button>
                    )}
                </div>
                <div className="fx-comp-hyperlogic-dashboard-dash-column">
                    {researchData.Challenges.map((chal, index) => (
                        <CompHyperlogicDashCard 
                            key={index} 
                            title={chal.heading || `Challenge ${index + 1}`} 
                            description={chal.description} // Pass description as a prop
                            columnHeading="Challenges" // Pass column heading
                            onRemove={() => handleRemoveItem('Challenges', index)} // Pass onRemove prop
                        />
                    ))}
                    {researchData.Challenges.length > 0 && ( // Show button only if cards exist
                        <Button 
                            variant="outlined" 
                            color="primary" 
                            onClick={() => handleAddItem('Challenges')} // Fetch more Challenges
                        >
                            Add Challenge
                        </Button>
                    )}
                </div>
                <div className="fx-comp-hyperlogic-dashboard-dash-column">
                    {researchData.UserPersonas.map((persona, index) => (
                        <CompHyperlogicDashCard 
                            key={index} 
                            title={persona.heading || `User Persona ${index + 1}`} 
                            description={persona.description} // Pass description as a prop
                            columnHeading="User Personas" // Pass column heading
                            onRemove={() => handleRemoveItem('UserPersonas', index)} // Pass onRemove prop
                        />
                    ))}
                    {researchData.UserPersonas.length > 0 && ( // Show button only if cards exist
                        <Button 
                            variant="outlined" 
                            color="primary" 
                            onClick={() => handleAddItem('UserPersonas')} // Fetch more User Personas
                        >
                            Add User Persona
                        </Button>
                    )}
                </div>
                <div className="fx-comp-hyperlogic-dashboard-dash-column">
                    {researchData.CompetitiveAnalysis.map((analysis, index) => (
                        <CompHyperlogicDashCard 
                            key={index} 
                            title={analysis.heading || `Competitor ${index + 1}`} 
                            description={analysis.description} // Pass description as a prop
                            columnHeading="Competitive Analysis" // Pass column heading
                            onRemove={() => handleRemoveItem('CompetitiveAnalysis', index)} // Pass onRemove prop
                        />
                    ))}
                    {researchData.CompetitiveAnalysis.length > 0 && ( // Show button only if cards exist
                        <Button 
                            variant="outlined" 
                            color="primary" 
                            onClick={() => handleAddItem('CompetitiveAnalysis')} // Fetch more Competitive Analysis
                        >
                            Add Competitor
                        </Button>
                    )}
                </div>
            </div>
        </div>
    );
};

export default CompHyperlogicDashboard;
