import React, { useEffect, useState, useContext } from 'react';
import { getMilestonesByProjectId } from '../fxAPI/fxMilestonesAPI';
import { getObjectivesByMilestoneId, updateObjective } from '../fxAPI/fxObjectivesAPI'; // Import the new function and updateObjective
import Button from '@mui/material/Button'; // Import Material-UI Button
import IconButton from '@mui/material/IconButton'; // Import IconButton
import DeleteIcon from '@mui/icons-material/Delete'; // Import DeleteIcon
import { PanelContext } from '../Contexts/PanelContext'; // Import PanelContext
import './CompMilestoneList.css';
import CompSubjectivePanel from './CompSubjectivePanel'; // Import the subjective panel component
import CompObjectiveToolset from './CompObjectiveToolset';

const CompMilestoneList = (props) => {
    const [milestones, setMilestones] = useState([]);
    const [error, setError] = useState(null);
    const [expandedMilestone, setExpandedMilestone] = useState(null);
    const [objectives, setObjectives] = useState({}); // State to store objectives by milestone
    const { triggerElementRefresh, elements, resetElementRefresh } = useContext(PanelContext); // Use PanelContext
    const elementId = 'milestoneList';

    const handleRefresh = async () => {
        console.log('Refreshing milestone list data');
        await getMilestonesByProjectId(props.relatedProject, setError, setMilestones);
        resetElementRefresh(elementId); // Reset the refresh flag after completion
    };

    useEffect(() => {
        if (elements[elementId]?.refresh) {
            handleRefresh(); // Trigger the refresh only when needed
        }
    }, [elements[elementId]?.refresh]);

    useEffect(() => {
        const fetchMilestones = async () => {
            await getMilestonesByProjectId(props.relatedProject, setError, setMilestones);
        };

        fetchMilestones();
    }, [props.relatedProject]);

    useEffect(() => {
        const fetchObjectives = async () => {
            const objectivesData = {};
            for (const milestone of milestones) {
                try {
                    const response = await getObjectivesByMilestoneId(milestone.milestone_id, setError, (data) => {
                        objectivesData[milestone.milestone_id] = data;
                    });
                } catch (error) {
                    console.error(`Error fetching objectives for milestone ${milestone.milestone_id}:`, error);
                }
            }
            setObjectives(objectivesData);
        };

        if (milestones.length > 0) {
            fetchObjectives();
        }
    }, [milestones]);

    const sortedMilestones = milestones.sort((a, b) => new Date(a.date_start) - new Date(b.date_start));

    const handleAccordionChange = (milestoneId) => (event, isExpanded) => {
        setExpandedMilestone(isExpanded ? milestoneId : null);
    };

    const handleCreateMilestoneClick = () => {
        // Switch the side panel context
        localStorage.setItem("fxSidePanelContext", 'create-milestone');
        localStorage.setItem("fxSidePanelObjectId", null);
        localStorage.setItem("fxSidePanelObject", null);
        triggerElementRefresh('sideContextPanel');
        console.log('Create Milestone button clicked');
    };

    const handleDeleteMilestoneClick = (milestoneId, milestoneData) => {
        console.log(`Delete Milestone button clicked for milestone ${milestoneId}`);
        localStorage.setItem("fxSidePanelContext", 'delete-milestone');
        localStorage.setItem("fxSidePanelObjectId", milestoneId);
        localStorage.setItem("fxSidePanelObject", milestoneData);
        triggerElementRefresh('sideContextPanel');
        console.log('Create Milestone button clicked');
    };

    const handleDrop = async (event, milestoneId) => {
        event.preventDefault();
        const data = event.dataTransfer.getData('object');
        if (data) {
            const parsedData = JSON.parse(data);
            console.log(`Dropped data:`, parsedData);
            console.log(`Dropped on milestoneId:`, milestoneId);

            if (parsedData.type === 'objective') {
                try {
                    await updateObjective(
                        parsedData.objective_id,
                        null, // name
                        null, // description
                        null, // related_org
                        null, // related_anchor
                        milestoneId, // Update the related milestone
                        null, // related_business_value
                        null, // progress
                        null  // date_due
                    );
                    console.log(`Objective ${parsedData.objective_id} updated with new milestone ${milestoneId}`);

                    // Update the state to reflect the dropped objective
                    setObjectives((prevObjectives) => {
                        const updatedObjectives = { ...prevObjectives };
                        if (!updatedObjectives[milestoneId]) {
                            updatedObjectives[milestoneId] = [];
                        }
                        updatedObjectives[milestoneId].push(parsedData);
                        return updatedObjectives;
                    });
                } catch (error) {
                    console.error(`Error updating objective ${parsedData.objective_id} with new milestone ${milestoneId}:`, error);
                }
            } else {
                console.log(`Unhandled drop type: ${parsedData.type}`);
            }
        }
    };

    const handleDragOver = (event) => {
        event.preventDefault();
    };

    const formatDate = (dateString) => {
        const options = { year: '2-digit', month: '2-digit', day: '2-digit' };
        return new Date(dateString).toLocaleDateString(undefined, options);
    };

    return (
        <>
            <div className="toolbar" style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', marginBottom: '16px' }}>
                <Button variant="outlined" onClick={handleCreateMilestoneClick}>Create Milestone</Button>
            </div>
        <div className="milestone-list-container">
            {error && <div>Error: {error}</div>}
            
            <div className="milestone-list">
                {sortedMilestones.map((milestone) => (
                    <div 
                        key={milestone.milestone_id} 
                        className="milestone-item"
                        onDrop={(event) => handleDrop(event, milestone.milestone_id)}
                        onDragOver={handleDragOver}
                    >
                        <div className="milestone-box" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <div className="milestone-box-content" style={{ flex: 1 }}>
                                <div className='milestone-header'>
                                    <div>{milestone.name}</div>
                                    <div>{formatDate(milestone.date_start)} - {formatDate(milestone.date_end)}</div>
                                </div>
                            </div>
                            <IconButton onClick={() => handleDeleteMilestoneClick(milestone.milestone_id, milestone)}>
                                <DeleteIcon />
                            </IconButton>
                        </div>
                        <div className="milestone-details">
                            {/*
                            <div>{milestone.description}</div>
                            <div>Start Date: {milestone.date_start}</div>
                            <div>End Date: {milestone.date_end}</div>
                            */}
                            <div>
                                {/*<strong>Objectives:</strong>*/}
                                    {objectives[milestone.milestone_id] && objectives[milestone.milestone_id].map((objective) => (
                                        <CompSubjectivePanel 
                                            panelName={objective.name} 
                                            relatedObjective={objective.objective_id} 
                                            actionSet={<CompObjectiveToolset relatedObjectiveId={objective.objective_id} relatedObjectiveData={objective} />} 
                                        />    
                                    ))}
                            </div>
                        </div>
                        <div>
                            ...
                        </div>
                    </div>
                ))}
            </div>
        </div>
        </>
    );
};

export default CompMilestoneList;
