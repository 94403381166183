import React, { useEffect, useState, useContext } from 'react';
import './CompCRUDFormComponent.css';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import CompModalButtons from './CompCRUDModalButtons';
import EditIcon from '@mui/icons-material/Edit';
import CompCRUDModalActionBar from './CompCRUDModalActionBar';
import { createComponent, updateComponent, generateUUID } from '../fxAPI/fxComponentsAPI.js';
import { createObjectLink } from '../fxAPI/fxObjectLinksAPI.js';
import { useParams } from "react-router-dom";
import { PanelContext } from '../Contexts/PanelContext';

const CompCRUDFormComponent = (props) => {

  const [currentEditableState, setCurrentEditableState] = useState('view');
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [componentResponse, setComponentResponse] = useState(null);
  const [componentCategory, setComponentCategory] = useState('');
  const [updateComponentName, setUpdateComponentName] = useState(props.currentComponentData?.name || '');
  const [updateComponentDescription, setUpdateComponentDescription] = useState(props.currentComponentData?.description || '');
  const [updateComponentCategory, setUpdateComponentCategory] = useState(props.currentComponentData?.config_cat || '');

  console.log('current Component data on form Component: ');
  console.dir(props.currentComponentData);

  const storedLocalOrgConfig = localStorage.getItem('fxLocalOrgConfig');
  const parsedOrgConfig = storedLocalOrgConfig ? JSON.parse(storedLocalOrgConfig) : null;
  console.log('parsed categories: ');
  console.dir(parsedOrgConfig.componentCategories);
  const categoryList = parsedOrgConfig.componentCategories;

  const triggerViewMode = (currentViewMode) => {
    setCurrentEditableState(currentViewMode);
  };

  const handleCloseModal = () => {
    props.onClose();
  };

  const params = useParams();

  const handleCreateComponent = async (event) => {
    event.preventDefault();
    const newRelatedOrg = localStorage.getItem("fxLocalOrgID");
    const newVersion = null;
    const newConfigCategory = componentCategory;
    const newCategory = 'test-hardcode2';
    const relatedAnchorId = props.relatedAnchorId;
    const newComponentId = generateUUID(); // Generate component_id

    const componentResponse = await createComponent(newComponentId, name, description, newVersion, newRelatedOrg, newConfigCategory, null, newCategory);

    await createObjectLink(
      relatedAnchorId,
      "anchor",
      newComponentId,
      "component",
      null,
      params.projectId,
      newRelatedOrg
    );
    handleRefreshElement();
    handleCloseModal(); // Close the modal after creating the component
  };

  const handleUpdateComponentNameChange = (e) => {
    setUpdateComponentName(e.target.value);
  }

  const handleUpdateComponentDescriptionChange = (e) => {
    setUpdateComponentDescription(e.target.value);
  }

  const handleUpdateComponentCategoryChange = (e) => {
    setUpdateComponentCategory(e.target.value);
  }

  const handleUpdateComponent = async (e) => {
    console.log('ran update component: ' + props.currentComponentData.component_id);
    e.preventDefault();
    const relatedOrg = localStorage.getItem("fxLocalOrgID");
  
    //const projectId = props.currentComponentData.project_id; // Replace with the actual project ID
    const componentId = props.currentComponentData.component_id;
    const updateName = updateComponentName;
    const updateDescription = updateComponentDescription;
    const related_org = relatedOrg;
    const updateVersion = null;
    const updateConfigCategory = updateComponentCategory;

    await updateComponent(componentId, updateName, updateDescription, updateVersion, related_org, updateConfigCategory, null);
    handleCloseModal(); // Close the modal after updating the component
  };

  /* Use Global Context to refresh Subjective Panel */
    const { triggerElementRefresh } = useContext(PanelContext);
  
    const handleRefreshElement = () => {
      console.log("Triggering refresh for node breakdown");
      triggerElementRefresh('nodeBreakdown');
    };

  return (
    <div className={props.crudMethod === "create" && props.formStyle === "darkBG" ? "fx-crud-form-darkBG" : ""}>
        {props.crudMethod === "create" &&
        <div>
            <br />
            <form onSubmit={handleCreateComponent}>
              <div>
                <TextField id="outlined-basic" label="Component Name" variant="outlined" onChange={(e) => setName(e.target.value)} />
              </div>   
              <br />
              <div className="fx-global-form-text-area-description">
              <TextField
                id="outlined-multiline-flexible"
                label="Component Description"
                multiline
                maxRows={4}
                onChange={(e) => setDescription(e.target.value)}
                fullWidth
                />  
              </div>
              <div className='fx-material-override-select-wrap'>
                  <label for="component-category">Category</label><br />
                  <select id="component-category" name="component-category" onChange={(e) => setComponentCategory(e.target.value)}>
                    <option>Select Category...</option>
                    {categoryList.map((category) => (
                      <option value={category.id}>{category.name}</option>
                    ))}
                  </select>
              </div>
              <CompModalButtons>
                <Button className="fx-component-form-button" type="submit">Create Component</Button>
              </CompModalButtons>         
            </form>
        </div>
        }
        {props.crudMethod === "view-edit" &&
          <div>
            <CompCRUDModalActionBar>
            { currentEditableState === "view" &&
              <Button onClick={() => triggerViewMode("edit")}><EditIcon /></Button>
            }
            { currentEditableState === "edit" &&
              <Button variant="outlined" onClick={() => triggerViewMode("view")}>Save</Button>
            }
          </CompCRUDModalActionBar>

          { currentEditableState === "view" &&
            <div>
              <div>
                <div className='fx-global-form-read-only-label'>Component Name</div>
                <div>{props.currentComponentData?.name}</div>
              </div> 
              <div>
                <div className='fx-global-form-read-only-label'>Component Description</div>
                <div>{props.currentComponentData?.description}</div>
              </div> 
            </div>
          } 

          { currentEditableState === "edit" &&
            <div>
              <form onSubmit={handleUpdateComponent}>
                <div>
                  <TextField id="outlined-basic" label="Component Name" variant="outlined" value={updateComponentName} onChange={handleUpdateComponentNameChange} />
                </div>   
                <br />
                <div className="fx-global-form-text-area-description">
                <TextField
                  id="outlined-multiline-flexible"
                  label="Component Description"
                  value={updateComponentDescription}
                  onChange={handleUpdateComponentDescriptionChange}
                  multiline
                  maxRows={4}
                  fullWidth
                  />  
                </div>
                <div className='fx-material-override-select-wrap'>
                  <label for="component-category">Category</label>
                  <br />
                  <select id="component-category" name="component-category" onChange={handleUpdateComponentCategoryChange}>
                    <option>Select Category...</option>
                    {categoryList.map((category) => (
                      <option value={category.id}>{category.name}</option>
                    ))}
                  </select>
                </div>
                <CompModalButtons>
                  <Button type='submit'>Save Component</Button>
                  <Button type="button" onClick={handleCloseModal}>Cancel</Button>
                </CompModalButtons>         
              </form>
            </div>
          }     

          </div>
        }
    </div>
  );
};

export default CompCRUDFormComponent;