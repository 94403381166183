import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Auth0Provider } from '@auth0/auth0-react';

const auth0Domain = process.env.REACT_APP_AUTH0_DOMAIN;
const auth0ClientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
const auth0RedirectURI = process.env.REACT_APP_AUTH0_REDIRECT_URI || window.location.origin;
const envPrint = process.env.REACT_APP_NODE_ENV;

/*
console.log("Auth0 Domain:", auth0Domain);
console.log("Auth0 Client ID:", auth0ClientId);
console.log("Auth0 Redirect URI:", auth0RedirectURI);
console.log("Environment: " + envPrint);
*/
localStorage.setItem("fxEnv", envPrint);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Auth0Provider
    domain={auth0Domain}
    clientId={auth0ClientId}
    authorizationParams={{
      redirect_uri: auth0RedirectURI,
    }}
    cacheLocation="localstorage"
    useRefreshTokens={true}
    onRedirectCallback={(appState) => {
      try {
        // Handle the callback after redirect to prevent issues with the state
        window.history.replaceState(
          {},
          document.title,
          appState?.returnTo || window.location.pathname
        );
        sessionStorage.removeItem('auth_state'); // Clear the state parameter after successful redirect
        console.log('Redirect callback handled successfully');
      } catch (error) {
        console.error("Error handling redirect callback:", error);
        console.error("Error details:", {
          message: error.message,
          stack: error.stack,
          appState: appState,
          location: window.location.href
        });
        // Optionally, you can redirect to an error page or show a message to the user
      }
    }}
  >
    <App />
  </Auth0Provider>
);

reportWebVitals();

