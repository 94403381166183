import React from 'react';
import './PageStart.css'; // using start page css for now until components 100%
import CompWebHeader from '../Components/CompWebHeader';
import CompWebFooter from '../Components/CompWebFooter';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid'; // Import Material-UI Grid
import Box from '@mui/material/Box'; // Import Material-UI Box
import StartFeatureInvestor1Img from '../images/fx-web-feature-investors1.png';
import StartFeatureInvestor2Img from '../images/fx-web-feature-investors2.png';
import StartFeatureInvestor3Img from '../images/fx-web-feature-investors3.png';
import Typography from '@mui/material/Typography';

const PageWebInvestor = (props) => {

  return (
    <div>
      <CompWebHeader />

      <div className="fx-page-start-login-splash-wrap">
        <Typography variant="h1" className="fx-page-start-marquee-text-white">
          The Future of Product Innovation
        </Typography>
        <Typography variant="h4" className="fx-page-start-marquee-text-teal">
          Revolutionizing Product Development & Execution
        </Typography>
        <Typography variant="h2" className="fx-page-start-marquee-text-white-lg">
          Invest in the Future of Digital Transformation
        </Typography>
      </div>
      
    <div className="fx-page-start-content-block-container fx-page-start-content-block-container-gray">
            <div className="fx-page-start-content-block-container-inner">
              <h2>Organize, forecast, and execute with clarity</h2>
              <p className="fx-page-start-feature-paragraph">
              Enterprises today face a critical challenge: outdated workflows, inefficient product execution, and fractured collaboration that slow down innovation and impact the bottom line. Traditional project management tools and siloed communication create bottlenecks, misalignment, and wasted resources—leaving businesses struggling to keep pace in an ever-evolving market.
              </p>
              <div className="fx-page-investor-organize-and-execute-inner-container">
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <h3>The Problem We Solve</h3>
                        <div>
                            <ul className="fx-page-investor-bullets-list">
                                <li>✅ Eliminate workflow inefficiencies</li>
                                <li>✅ Bridge gaps in cross-team collaboration</li>
                                <li>✅ Accelerate product execution with data-driven insights</li>
                                <li>✅ Drive innovation without distractions</li>
                                <li>✅ Reduce project delays with AI-powered risk detection</li>
                                <li>✅ Enhance accountability with real-time performance tracking</li>
                            </ul>
                        </div>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <h3>Our Solution</h3>
                        <p className="fx-page-start-feature-bullet">
                            We bridge the gap between stakeholders, reducing misalignment and increasing transparency across all levels of an organization. Whether you’re managing cross-functional teams, tracking progress in real-time, or making data-driven decisions, Fluxity ensures that everyone stays connected, aligned, and productive.
                        </p>
                    </Grid>
                </Grid>
              </div>

              
              {/*
              <div className='fx-page-investor-button-center'>
                <p className="fx-page-start-feature-paragraph"><Button>Experience Fluxity</Button></p>
              </div>
              */}
              
            </div>
    </div>

    <div className="fx-page-start-content-block-container fx-page-start-section-outer-ideation-completion">
      <div className="fx-page-start-content-block-container-inner">
        <h2>Fluxity Vision & Business Model</h2>
        <p className="fx-page-start-feature-paragraph">
        Monetization & Revenue Strategy
        </p>
        <Grid container spacing={2}>
        <Grid item xs={12} md={4} className="fx-grid-item-xs-full">
          <Box sx={{ width: '100%' }}>
            <h3>SaaS pricing model</h3>
            <p className="fx-page-start-feature-bullet">A recurring revenue stream with scalable subscription plans tailored for businesses of all sizes, ensuring long-term growth and predictable ARR.</p>
          </Box>
        </Grid>
        <Grid item xs={12} md={4} className="fx-grid-item-xs-full">
          <Box sx={{ width: '100%' }}>
            <div className='fx-page-start-flex-item-ideation-completion'>
              <h3>Tiered licensing</h3>
              <p className="fx-page-start-feature-bullet">Multi-level pricing designed to maximize adoption, with deep integrations into enterprise ecosystems, increasing stickiness and lifetime value.</p>
            </div>
          </Box>
        </Grid>
        <Grid item xs={12} md={4} className="fx-grid-item-xs-full">
          <Box sx={{ width: '100%' }}>
            <div className='fx-page-start-flex-item-ideation-completion'>
              <h3>Consulting</h3>
              <p className="fx-page-start-feature-bullet">Fluxity unlocks multiple profit streams through premium advisory services, empowering businesses to optimize their operations with our expertise.</p>
            </div>
          </Box>
        </Grid>
      </Grid>
        <p className="fx-page-start-feature-paragraph">This expands market penetration, maximizes scalability, and drives sustained profitability, creating a powerful competitive advantage.</p>
      </div>
      
    </div>

    <div className="fx-page-start-content-block-container fx-page-start-content-block-container-gray">
          <div className="fx-page-start-content-block-container-inner">
            <h2>In a World of Interruptions</h2>
            <p className="fx-page-start-feature-paragraph">
            Revolutionizing How Teams Succeed
            </p>
            <Grid container spacing={2}>
              <Grid item xs={12} md={4}>
                <div className='fx-page-start-flex-item-interrupt-section'>
                  <div>
                    <img src={StartFeatureInvestor1Img} width="100%" height="auto" alt="Effort Estimation" />
                  </div>
                  <h3>Effort Estimation</h3>
                  <p className="fx-page-start-feature-bullet">
                    Accurately forecast task completion with AI-driven effort estimates, improving planning, resource allocation, and productivity.
                  </p>
                </div>
              </Grid>
              <Grid item xs={12} md={4}>
                <div className='fx-page-start-flex-item-interrupt-section'>
                  <div>
                    <img src={StartFeatureInvestor2Img} width="100%" height="auto" alt="Execution Tracking" />
                  </div>
                  <h3>Execution Tracking</h3>
                  <p className="fx-page-start-feature-bullet">
                    Real-time visibility into project progress, blockers, and team performance, ensuring seamless execution and accountability.
                  </p>
                </div>
              </Grid>
              <Grid item xs={12} md={4}>
                <div className='fx-page-start-flex-item-interrupt-section'>
                  <div>
                    <img src={StartFeatureInvestor3Img} width="100%" height="auto" alt="HR Integration" />
                  </div>
                  <h3>HR Integration</h3>
                  <p className="fx-page-start-feature-bullet">
                    Connect work efforts to HR systems for performance insights, skill tracking, and workforce optimization, driving efficiency at scale.
                  </p>
                </div>
              </Grid>
            </Grid>
          </div>
        </div>

    <div className="fx-page-start-content-block-container fx-page-start-content-block-container-blue-form">
        <div className="fx-page-start-content-block-container-inner">
            <h2>Fluxity Vision & Business Model</h2>
            <p className="fx-page-start-feature-paragraph">
            Are you ready to revolutionize your product development process? Join us in shaping the future of product innovation and execution. Contact us today to learn more about how Fluxity can transform your organization.
            </p>
            <div className="fx-page-investor-button-center">
            <p className="fx-page-start-feature-paragraph"><a href="/contact"><Button>Contact Us</Button></a></p>
            </div>
        </div>
    </div>

      <CompWebFooter />
    </div>
  );
};

export default PageWebInvestor;