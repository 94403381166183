import React, { useContext, useState } from 'react';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { PanelContext } from '../Contexts/PanelContext';
import CompQuickActionsPopover from './CompQuickActionsPopover';
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import AdsClickIcon from '@mui/icons-material/AdsClick';
import CompCRUDModal from './CompCRUDModal.jsx';
import './CompObjectiveToolset.css';
import CompQuickStatusTrigger from './CompQuickStatusTrigger.jsx';

const CompObjectiveToolset = (props) => {
    const { triggerElementRefresh } = useContext(PanelContext);

    const [error, setError] = useState(null);
        const [isOpen, setIsOpen] = useState(false);
        const [itemType, setItemType] = useState(null);
          const [actionModalTitle, setActionModalTitle] = useState(null);
          const [crudMethod, setCRUDMethod] = useState(null);
          const [modalComponentData, setModalComponentData] = useState([]);
          const [relatedComponentId, setRelatedComponentId] = useState(null);
          const [relatedComponentName, setRelatedComponentName] = useState(null);
          const [relatedComponentType, setRelatedComponentType] = useState(null);

    const openModal = (itemType, currentComponentId, currentComponentName, currentComponentType, currentCRUDMethod, currentModalComponentData) => {
        setItemType(itemType);
        setRelatedComponentId(currentComponentId);
        setRelatedComponentName(currentComponentName);
        setRelatedComponentType(currentComponentType);
        setCRUDMethod(currentCRUDMethod);
        setModalComponentData(currentModalComponentData);
        setIsOpen(true);
      };
    
      const closeModal = () => {
        setIsOpen(false);
      };

    const handleRefreshElement = () => {
        triggerElementRefresh('sideContextPanel');
    };

    const handleSwitchPanelContext = (object_id, type, object) => {
        localStorage.setItem("fxSidePanelContext", type);
        localStorage.setItem("fxSidePanelObjectId", object_id);
        localStorage.setItem("fxSidePanelObject", JSON.stringify(object));
        handleRefreshElement();
    };

    return (
        <div className="fx-comp-objective-toolset-container">
            {/* Component content goes here */}
            {/*<CompCRUDModal isOpen={isOpen} onClose={closeModal} modalTitle={actionModalTitle} itemType={itemType} crudMethod={crudMethod} modalComponentData={modalComponentData} relatedComponentId={relatedComponentId} relatedComponentName={relatedComponentName} relatedComponentType={relatedComponentType}></CompCRUDModal>*/}
            <CompQuickActionsPopover relatedObjectType="objective" relatedObjectId={props.relatedObjectiveId}  componentData={props.relatedObjectiveData} />
                    {/*<div><CompQuickCreateStatusModal /></div>*/}
                    <div><CompQuickStatusTrigger relatedObjectType="objective" relatedObjectId={props.relatedObjectiveId} /></div>
                    <div onClick={() => handleSwitchPanelContext(props.relatedObjectiveId, "objective", props.relatedObjectiveData)}><ArrowOutwardIcon /></div>
        </div>
    );
};

export default CompObjectiveToolset;
