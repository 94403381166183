import React, { useState, useEffect, useContext } from 'react';
import './CompNodeBreakdown.css';
import Typography from '@mui/material/Typography';
import { getObjectivesByProjectIdAndAnchorId, updateObjective} from '../fxAPI/fxObjectivesAPI'; // Import updateObjective and getComponentsByAnchor
import { createObjectLink } from '../fxAPI/fxObjectLinksAPI';
import { getComponentsByAnchor } from '../fxAPI/fxComponentsAPI.js';
import { getFeedbackByAnchor } from '../fxAPI/fxFeedbackAPI'; // Import getFeedbackByAnchor
import { getDataElementsByAnchor } from '../fxAPI/fxDataElementsAPI'; // Import getDataElementsByAnchor
import { getDesignSessionsByAnchorId } from '../fxAPI/fxDesignSessionsAPI'; // Import getDesignSessionsByAnchorId
import { getResearchElementsByAnchor } from '../fxAPI/fxResearchElementsAPI'; // Import getResearchElementsByAnchor
import CompObjectiveAnchorCard from './CompObjectiveAnchorCard';
import AdsClickIcon from '@mui/icons-material/AdsClick';
import CompCRUDModal from './CompCRUDModal.jsx';
import PageviewIcon from '@mui/icons-material/Pageview';
import CompQuickActionsPopover from './CompQuickActionsPopover.jsx';
import { PanelContext } from '../Contexts/PanelContext';
import WidgetsIcon from '@mui/icons-material/Widgets';
import FlagCircleIcon from '@mui/icons-material/FlagCircle';
import DatasetIcon from '@mui/icons-material/Dataset';
import AccessibleIcon from '@mui/icons-material/Accessible';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import CompCollapsibleContainer from './CompCollapsibleContainer.jsx';
import CompSubjectivePanel from './CompSubjectivePanel.jsx';
import CompObjectiveToolset from './CompObjectiveToolset.jsx';

const CompNodeBreakdown = (props) => {
  const [objectives, setObjectives] = useState([]);
  const [components, setComponents] = useState([]); // Add state for components
  const [feedback, setFeedback] = useState([]); // Add state for feedback
  const [dataElements, setDataElements] = useState([]); // Add state for data elements
  const [researchElements, setResearchElements] = useState([]); // Add state for research elements
  const [designSessions, setDesignSessions] = useState([]); // Add state for design sessions
  const [error, setError] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [itemType, setItemType] = useState(null);
  const [actionModalTitle, setActionModalTitle] = useState(null);
  const [crudMethod, setCRUDMethod] = useState(null);
  const [modalComponentData, setModalComponentData] = useState([]);
  const [relatedComponentId, setRelatedComponentId] = useState(null);
  const [relatedComponentName, setRelatedComponentName] = useState(null);
  const [relatedComponentType, setRelatedComponentType] = useState(null);

  const { triggerElementRefresh, elements, resetElementRefresh } = useContext(PanelContext); // Use PanelContext
  const elementId = 'nodeBreakdown';

  const openModal = (itemType, currentComponentId, currentComponentName, currentComponentType, currentCRUDMethod, currentModalComponentData) => {
    setItemType(itemType);
    setRelatedComponentId(currentComponentId);
    setRelatedComponentName(currentComponentName);
    setRelatedComponentType(currentComponentType);
    setCRUDMethod(currentCRUDMethod);
    setModalComponentData(currentModalComponentData);
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    const fetchObjectivesComponentsFeedbackDataElements = async () => {
      if (props.relatedProject && props.anchorId) {
        console.log('Fetching objectives, components, feedback, and data elements for project:', props.relatedProject, 'and anchor:', props.anchorId);
        await getObjectivesByProjectIdAndAnchorId(props.relatedProject, props.anchorId, setError, setObjectives);
        await getComponentsByAnchor(props.anchorId, setError, setComponents); // Fetch components by anchor
        const feedbackData = await getFeedbackByAnchor(props.anchorId, setError); // Fetch feedback by anchor
        console.log('Fetched feedback data:', feedbackData);
        setFeedback(Array.isArray(feedbackData) ? feedbackData : []);
        console.log('Feedback state updated:', feedbackData); // Log the updated feedback state
        const dataElementData = await getDataElementsByAnchor(props.anchorId, setError); // Fetch data elements by anchor
        setDataElements(Array.isArray(dataElementData.items) ? dataElementData.items : []);
        console.log('Data Elements state updated:', dataElementData.items); // Log the updated data elements state
        await getDesignSessionsByAnchorId(props.anchorId, setError, setDesignSessions); // Fetch design sessions by anchor
        const researchElementData = await getResearchElementsByAnchor(props.anchorId, setError);
        setResearchElements(Array.isArray(researchElementData.items) ? researchElementData.items : []);
      }
    };

    fetchObjectivesComponentsFeedbackDataElements();
  }, [props.relatedProject, props.anchorId]);

  useEffect(() => {
    if (elements[elementId]?.refresh) {
      const fetchObjectives = async () => {
        if (props.relatedProject && props.anchorId) {
          await getObjectivesByProjectIdAndAnchorId(props.relatedProject, props.anchorId, setError, setObjectives);
        }
      };
  
      fetchObjectives();
      resetElementRefresh(elementId); // Reset the refresh flag after completion
    }
  }, [elements[elementId]?.refresh]);


  const handleRefreshElement = () => {
    triggerElementRefresh('sideContextPanel'); // Trigger refresh for the subjectivePanel
  };

  const handleSwitchPanelContext = (object_id, type, object) => {
    //updatePanelContext("objective", objective_id, object);
    localStorage.setItem("fxSidePanelContext", type);
    localStorage.setItem("fxSidePanelObjectId", object_id);
    localStorage.setItem("fxSidePanelObject", JSON.stringify(object));
    handleRefreshElement();
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const data = event.dataTransfer.getData('object');
    if (data) {
      const parsedData = JSON.parse(data);
      console.log('Dropped data:', parsedData);
      console.log('Dropped on anchorId:', props.anchorId);
      // Stubbed function to handle the dropped data
      handleObjectDrop(parsedData, props.anchorId);
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleObjectDrop = (data, anchorId) => {
    // Stubbed function to handle the dropped data
    console.log(`Data ${data.name} of type ${data.type} dropped on anchor ${anchorId}`);
    
    if (data.type === 'objective') {
      updateObjective(
        data.objective_id,
        null,
        null,
        null,
        anchorId, // Use the current anchorId for related_anchor
        null,
        null,
        null,
        null
      ).then(() => {
        console.log(`Objective ${data.name} updated with new anchor ${anchorId}`);
        acknowledgeDrop(data);
        triggerElementRefresh(elementId); // Trigger refresh for the nodeBreakdown
      }).catch((error) => {
        console.error('Error updating objective:', error);
      });
    } else if (data.type === 'component') {
      // Handle component drop
      console.log(`Handling drop for component: ${data.name}`);
      // Add your logic here for handling component drop
    } else if (data.type === 'userFeedback') {
      // Handle user feedback drop
      console.log(`Handling drop for user feedback: ${data.name}`);
      // Add your logic here for handling user feedback drop
    } else if (data.type === 'data') {
      // Handle data drop
      console.log(`Handling drop for data: ${data.name}`);
      // Add your logic here for handling data drop
    } else if (data.type === 'accessibility') {
      // Handle accessibility drop
      console.log(`Handling drop for accessibility: ${data.name}`);
      // Add your logic here for handling accessibility drop
    } else {
      console.log(`Unhandled drop type: ${data.type}`);
    }
  };

  const acknowledgeDrop = (data) => {
    console.log(`Acknowledged drop of ${data.type} with name ${data.name}`);
  };


  return (
    <div className="comp-node-breakdown" onDrop={handleDrop} onDragOver={handleDragOver}>
      <CompCRUDModal isOpen={isOpen} onClose={closeModal} modalTitle={actionModalTitle} itemType={itemType} crudMethod={crudMethod} modalComponentData={modalComponentData} relatedComponentId={relatedComponentId} relatedComponentName={relatedComponentName} relatedComponentType={relatedComponentType}></CompCRUDModal>
      
      <div className='fx-comp-node-breakdown-collapsible-wrapper'>
      <CompCollapsibleContainer containerHeading="Objectives">
            <div>
            {objectives.map((objective) => (
            <div key={objective.objective_id}>
                <CompSubjectivePanel
                panelName={objective.name} 
                relatedObjective={objective.objective_id} 
                actionSet={<CompObjectiveToolset relatedObjectiveId={objective.objective_id} relatedObjectiveData={objective} />}
                />
                {/*<CompObjectiveAnchorCard relatedObjective={objective.objective_id} relatedProject={props.relatedProject} />*/}
            </div>
            ))}
            <div className="fx-comp-node-breakdown-add-item-phrase" onClick={() => handleSwitchPanelContext(props.anchorId, "create-objective", null)}><AddCircleOutlineIcon /> Add Objective</div>
        </div>
      </CompCollapsibleContainer>
      </div>

      <div className='fx-comp-node-breakdown-collapsible-wrapper'>
      <CompCollapsibleContainer containerHeading="Components">
        {components.map((component) => (
          <div key={component.component_id} className="fx-comp-node-breakdown-list-item-container">
            <div className="fx-comp-node-breakdown-list-item-text">{component.name}</div>
            <div className="fx-comp-node-breakdown-list-item-actions">
                  <div className="fx-comp-subjective-panel-view-icon" onClick={() => handleSwitchPanelContext(component.component_id, "component", component)}>
                    <ArrowOutwardIcon />
                  </div>
            </div>
          </div>
        ))}
        <div className="fx-comp-node-breakdown-add-item-phrase" onClick={() => handleSwitchPanelContext(props.anchorId, "create-component", null)}><AddCircleOutlineIcon /> Add Component</div>
      </CompCollapsibleContainer>
      </div>

      <div className='fx-comp-node-breakdown-collapsible-wrapper'>
      <CompCollapsibleContainer containerHeading="User Feedback">
        {feedback.map((fb) => (
          <div key={fb.feedback_id} className="fx-comp-node-breakdown-list-item-container">
            <div className="fx-comp-node-breakdown-list-item-text">{fb.name}</div>
            <div className="fx-comp-node-breakdown-list-item-actions">
              <div className="fx-comp-subjective-panel-view-icon" onClick={() => handleSwitchPanelContext(fb.feedback_id, "user-feedback", fb)}>
                <ArrowOutwardIcon />
              </div>
            </div>
          </div>
        ))}
        <div className="fx-comp-node-breakdown-add-item-phrase" onClick={() => handleSwitchPanelContext(props.anchorId, "create-user-feedback", null)}><AddCircleOutlineIcon /> Add User Feedback</div>
      </CompCollapsibleContainer>
      </div>

      <div className='fx-comp-node-breakdown-collapsible-wrapper'>
      <CompCollapsibleContainer containerHeading="Data">
        {dataElements.map((dataElement) => (
          <div key={dataElement.data_element_id} className="fx-comp-node-breakdown-list-item-container">
            <div className="fx-comp-node-breakdown-list-item-text">{dataElement.property_key}</div>
            <div className="fx-comp-node-breakdown-list-item-actions">
              <div className="fx-comp-subjective-panel-view-icon" onClick={() => handleSwitchPanelContext(dataElement.data_element_id, "data-element", dataElement)}>
                <ArrowOutwardIcon />
              </div>
            </div>
          </div>
        ))}
        <div className="fx-comp-node-breakdown-add-item-phrase" onClick={() => handleSwitchPanelContext(props.anchorId, "create-data", null)}><AddCircleOutlineIcon /> Add Data</div>
      </CompCollapsibleContainer>
      </div>

      <div className='fx-comp-node-breakdown-collapsible-wrapper'>
      <CompCollapsibleContainer containerHeading="Design Sessions">
        {designSessions.map((session) => (
          <div key={session.design_session_id} className="fx-comp-node-breakdown-list-item-container">
            <div className="fx-comp-node-breakdown-list-item-text">{session.name}</div>
            <div className="fx-comp-node-breakdown-list-item-actions">
              <div className="fx-comp-subjective-panel-view-icon" onClick={() => handleSwitchPanelContext(session.design_session_id, "design-session", session)}>
                <ArrowOutwardIcon />
              </div>
            </div>
          </div>
        ))}
        <div className="fx-comp-node-breakdown-add-item-phrase" onClick={() => handleSwitchPanelContext(props.anchorId, "create-design-session", null)}><AddCircleOutlineIcon /> Add Design Session</div>
      </CompCollapsibleContainer>
      </div>

      <div className='fx-comp-node-breakdown-collapsible-wrapper'>
      <CompCollapsibleContainer containerHeading="Research">   
      {researchElements.map((researchElement) => (
          <div key={researchElement.research_element_id} className="fx-comp-node-breakdown-list-item-container">
            <div className="fx-comp-node-breakdown-list-item-text">{researchElement.name}</div>
            <div className="fx-comp-node-breakdown-list-item-actions">
              <div className="fx-comp-subjective-panel-view-icon" onClick={() => handleSwitchPanelContext(researchElement.research_element_id, "research-element", researchElement)}>
                <ArrowOutwardIcon />
              </div>
            </div>
          </div>
        ))}
        <div className="fx-comp-node-breakdown-add-item-phrase" onClick={() => handleSwitchPanelContext(props.anchorId, "create-research-element", null)}><AddCircleOutlineIcon /> Add Research Element</div>
      </CompCollapsibleContainer>
      </div>

      {/*
      <div className='fx-comp-node-breakdown-collapsible-wrapper'>
      <CompCollapsibleContainer containerHeading="Accessibility">
        <div className="fx-comp-node-breakdown-add-item-phrase" onClick={() => handleSwitchPanelContext(props.anchorId, "create-accessibility", null)}><AddCircleOutlineIcon /> Add Accessibility</div>    
      </CompCollapsibleContainer>
      </div>
      */}

    </div>
  );
};

export default CompNodeBreakdown;