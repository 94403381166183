import React, { useState } from 'react';
import './CompPageTempWizard.css';
import Container from '@mui/material/Container';
import { Box } from '@mui/material';
import Grid from '@mui/material/Grid';
import CompSideNavMaster from './CompSideNavMaster';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import FluxityLogo from '../images/Fluxity_Logo.svg';


const CompPageTempWizard = (props) => {

  function getOuterClass() {
    if(props.maxWidth === "25") {
      var outerClass = "fx-comp-page-temp-wizard-outer max-width-25";
    } else {
      var outerClass = "fx-comp-page-temp-wizard-outer max-width-40";
    }
    return outerClass;
  }

  const outerCurrentClass = getOuterClass();  


  return (
    <div className='fx-comp-page-temp-wizard-container'>
          <div className='fx-comp-page-temp-wizard-logo'>
            <img src={FluxityLogo} alt="Fluxity Logo" className="logo" />
          </div> 
          <div className={outerCurrentClass}>
            <Grid container>
              <Grid item xs={12}>
                <h1>{props.headingOne}</h1>
                <div className="fx-comp-page-temp-wizard-inner">
                  <div className="">
                    {props.children}
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
    </div>
  );
};

export default CompPageTempWizard;