import React, { useState, useEffect, useContext } from 'react';
import AdsClickIcon from '@mui/icons-material/AdsClick';
import Popover from '@mui/material/Popover';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import { getUsersByOrgId } from '../fxAPI/fxUsersAPI';
import { createStatus } from '../fxAPI/fxStatusAPI';
import { PanelContext } from '../Contexts/PanelContext';
import './CompQuickStatusTrigger.css';

const CompQuickStatusTrigger = (props) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [users, setUsers] = useState([]);
    const [error, setError] = useState(null);
    const [selectedUser, setSelectedUser] = useState("");
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const { triggerElementRefresh, elements, resetElementRefresh } = useContext(PanelContext); // Use PanelContext

    const currentOrgId = localStorage.getItem("fxLocalOrgID");
    const currentUserId = localStorage.getItem("fxLocalUserID");

    useEffect(() => {
        if (currentOrgId) {
            getUsersByOrgId(currentOrgId, setError, setUsers);
        }
    }, [currentOrgId]);

    const handleClick = (event) => {
        console.log("Popover clicked");
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        console.log("Closing popover");
        setAnchorEl(null);
    };

    const handleCreateStatus = () => {
        console.log("Creating status");
        createStatus(
            props.relatedObjectId,
            currentOrgId,
            null,
            selectedUser,
            currentUserId,
            "Quick Status",
            "No description",
            props.relatedObjectType,
            (response) => {
                console.log("Status created:", response);
                triggerElementRefresh('sideContextPanel'); // Trigger refresh of SideContextPanel
            },
            (error) => {
                console.error("Error creating status:", error);
            }
        ).finally(() => {
            handleClose();
            setSnackbarOpen(true); // Open the snackbar
            triggerElementRefresh('sideContextPanel');
        });
    };

    const handleSnackbarClose = () => {
        console.log("Closing snackbar");
        setSnackbarOpen(false);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <div>
            <div onClick={handleClick}><AdsClickIcon /></div>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                style={{ padding: '8px' }}
            >
                <div style={{ display: 'flex', alignItems: 'center', padding: '8px' }}>
                    <Select
                        value={selectedUser}
                        onChange={(e) => setSelectedUser(e.target.value)}
                        displayEmpty
                    >
                        <MenuItem value="" disabled>
                            Select User...
                        </MenuItem>
                        {users.map(user => (
                            <MenuItem key={user.user_id} value={user.user_id}>{user.username}</MenuItem>
                        ))}
                    </Select>
                    <Button
                        variant="outlined"
                        style={{ marginLeft: '10px', height: '50px' }}
                        onClick={handleCreateStatus}
                    >
                        Get Status
                    </Button>
                </div>
            </Popover>
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
                message="Status Created"
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            />
        </div>
    );
};

export default CompQuickStatusTrigger;
