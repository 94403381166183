import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import './PageJourneyMapGallery.css';
import Box from '@mui/material/Box';
import CompPageTempMaster3Panel from '../Components/CompPageTempMaster3Panel';
import CompContentBlock from '../Components/CompContentBlock';
import Button from '@mui/material/Button';
import axios from 'axios';
import CompJourneyMapGallery from '../Components/CompJourneyMapGallery';


const PageJourneyMapGallery = (props) => {

  const params = useParams();
  const projectId = params.projectId;

  const [error, setError] = useState(null);

  return (
    <CompPageTempMaster3Panel headingOne="Journey Maps">

      <CompJourneyMapGallery relatedProject={projectId} />

    </CompPageTempMaster3Panel>
  );
}

export default PageJourneyMapGallery;