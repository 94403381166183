import axios from 'axios';

const getReportsDataByReportId = async (reportId, setError, setReportsDataData) => {
  setError(null);
  setReportsDataData([]); // Reset to empty array

  if (!reportId) {
    setError('reportId parameter is required');
    return;
  }

  try {
    console.log(`Fetching reports data for reportId: ${reportId}`);
    const response = await axios.get('/fxapi/reports/reports-data-by-report', {
      params: { reportId }
    });

    console.log('Full API Response:', response.data);
    console.log('API response received:', response.data.items);

    const items = Array.isArray(response.data.items) ? response.data.items : [];
    const parsedItems = items.map(item => ({
      ...item,
      data: JSON.parse(item.data)
    }));
    setReportsDataData(parsedItems);
  } catch (error) {
    console.error('Error fetching reports data:', error);
    setError(error.response ? error.response.data : 'Failed to fetch reports data');
  }
};

export { getReportsDataByReportId };