import React, { useEffect, useState, useContext } from 'react';
import './CompHyperlogicDropzone.css';
import { createResearchElement, getResearchElementsByFocusOrg } from '../fxAPI/fxResearchElementsAPI';
import { dragFunctionMap } from './CompHyperlogicDashCard';
import { v4 as generateUUID } from 'uuid';
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import { PanelContext } from '../Contexts/PanelContext';

const CompHyperlogicDropzone = ({ containerHeading, relatedResearchType }) => {
    const [researchElements, setResearchElements] = useState([]);
    const [error, setError] = useState(null);
    const { triggerElementRefresh, elements, resetElementRefresh } = useContext(PanelContext); // Use PanelContext

    const fetchResearchElementsByFocus = async (focusArea) => {
        const orgId = localStorage.getItem('fxLocalOrgID');
        focusArea = focusArea.trim().toLowerCase(); // Normalize focusArea
        console.log('Fetching research elements for normalized focusArea:', focusArea, 'and orgId:', orgId);

        try {
            const response = await getResearchElementsByFocusOrg(focusArea, orgId, setError, setResearchElements);
            console.log('API response data:', response); // Debugging log
        } catch (err) {
            console.error('Error fetching research elements by focus:', err);
            setError(err.response?.data || 'Failed to fetch research elements');
        }
    };

    useEffect(() => {
        console.log('useEffect triggered for containerHeading:', containerHeading);
        fetchResearchElementsByFocus(containerHeading);
    }, [containerHeading]);

    const handleDrop = async (e) => {
        e.preventDefault();
        const dragData = JSON.parse(e.dataTransfer.getData('text/plain'));
        console.log('Dropped Data:', dragData);

        const research_element_id = generateUUID();
        const name = dragData.heading;
        const description = dragData.description || '';
        const related_files = null;
        const related_links = null;
        const related_research_type = dragData.columnHeading.toLowerCase().replace(/\s+/g, '-'); // Use column heading
        const related_research_focus = containerHeading.toLowerCase();

        console.log('Creating Research Element:', {
            research_element_id,
            name,
            description,
            related_files,
            related_links,
            related_research_type,
            related_research_focus,
        });

        await createResearchElement(
            research_element_id,
            name,
            description,
            related_files,
            related_links,
            new Date().toISOString(),
            new Date().toISOString(),
            related_research_type,
            related_research_focus,
            localStorage.getItem('fxLocalOrgID'),
            (response) => console.log('Research Element Created:', response)
        );

        // Refresh the list after a drop
        fetchResearchElementsByFocus(containerHeading.trim().toLowerCase());

        // Call the onRemove function if it exists
        if (dragData.id && dragFunctionMap.has(dragData.id)) {
            const onRemove = dragFunctionMap.get(dragData.id);
            onRemove();
            dragFunctionMap.delete(dragData.id);
        }
    };

    const handleDragOver = (e) => {
        e.preventDefault();
    };

    const handleIconClick = (element) => {
        console.log('ArrowOutwardIcon clicked for element:', element);
        handleSwitchPanelContext(element.research_element_id, "research-element", element);
    };

    const handleRefreshElement = () => {
        triggerElementRefresh('sideContextPanel'); // Trigger refresh for the subjectivePanel
      };
    
    const handleSwitchPanelContext = (object_id, type, object) => {
    //updatePanelContext("objective", objective_id, object);
    localStorage.setItem("fxSidePanelContext", type);
    localStorage.setItem("fxSidePanelObjectId", object_id);
    localStorage.setItem("fxSidePanelObject", JSON.stringify(object));
    handleRefreshElement();
    };

    return (
        <div className="fx-comp-hyperlogic-dropzone">
            {error && <div className="error-message">Error: {error}</div>}
            <ul className="research-elements-list">
                {researchElements.length > 0 ? (
                    researchElements.map((element) => (
                        <li key={element.research_element_id}>
                            <div className="research-elements-list-item-row">
                                <div className="research-elements-list-item-text">
                                    {element.name}
                                </div>
                                <div className="research-elements-list-item-icons">
                                    <ArrowOutwardIcon onClick={() => handleIconClick(element)} />
                                </div>
                            </div>
                            {/*<strong>{element.name}</strong>: {element.description}*/}
                        </li>
                    ))
                ) : (
                    <li>No research elements found.</li>
                )}
            </ul>
            <div onDrop={handleDrop} onDragOver={handleDragOver} className='fx-comp-hyperlogic-dropzone-wrapper'>
                Drop items here for {containerHeading}
            </div>
        </div>
    );
};

export default CompHyperlogicDropzone;
