import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import './PageAccessibilityReportDetail.css';
import Box from '@mui/material/Box';
import CompPageTempMaster3Panel from '../Components/CompPageTempMaster3Panel';
import CompAccessibilityReportDetail from '../Components/CompAccessibilityReportDetail';


const PageAccessibilityReportDetail = (props) => {

  const [error, setError] = useState(null);

  

  return (
    <CompPageTempMaster3Panel headingOne="Accessibility Report Detail">

      <CompAccessibilityReportDetail />
      
    </CompPageTempMaster3Panel>
  );
}

export default PageAccessibilityReportDetail;