import React from 'react';
import { useNavigate } from 'react-router-dom';
import './CompWebFooter.css';
import FluxityLogo from '../images/Fluxity_Logo.svg';
import Button from '@mui/material/Button';

const CompWebFooter = () => {
  const navigate = useNavigate();

  const handleContactClick = () => {
    navigate('/contact');
  };

  return (
    <footer className="fx-comp-web-footer">
      <div className="fx-comp-web-footer-content">
        <div className="fx-comp-web-footer-logo">
          <img src={FluxityLogo} alt="Fluxity Logo" />
        </div>
        <div className="fx-comp-web-footer-contact">
          <Button variant="contained" onClick={handleContactClick}>Contact Us</Button>
        </div>
      </div>
    </footer>
  );
};

export default CompWebFooter;
