import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import './PageContentful.css';
import Box from '@mui/material/Box';
import CompPageTempMaster3Panel from '../Components/CompPageTempMaster3Panel';
import CompContentBlock from '../Components/CompContentBlock';
import axios from 'axios';
import CompComponentDashboard from '../Components/CompComponentDashboard';
import client from "../contentfulClient";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import CompPageTempWebArticle from '../Components/CompPageTempWebArticle';

const PageContentful = (props) => {
    const { slug } = useParams();
    const [content, setContent] = useState(null);
    const [imageUrl, setImageUrl] = useState("");
  
    useEffect(() => {
      const fetchContent = async () => {
        try {
          const response = await client.getEntries({
            content_type: "fluxityArticle",  // Replace with your content type ID
            "fields.slug": slug,  // Query by slug
            limit: 1
          });
  
          if (response.items.length > 0) {
            const entry = response.items[0].fields;
            setContent(entry);
  
            // Fetch splash image if available
            if (entry.splashImage) {
              const imageResponse = await client.getAsset(entry.splashImage.sys.id);
              setImageUrl(imageResponse.fields.file.url);
            }
          } else {
            setContent(null);
          }
        } catch (error) {
          console.error("Error fetching content:", error);
        }
      };
  
      fetchContent();
    }, [slug]);
  
    if (!content) return <h2>404 - Page Not Found</h2>;
  
    return (
      <div>
        <CompPageTempWebArticle heading={content.heading} splashImage={imageUrl && <img src={`https:${imageUrl}`} alt={content.heading} style={{ maxWidth: "100%" }} />}>
            <div>{documentToReactComponents(content.articleContentBlock)}</div>
        </CompPageTempWebArticle>
        
      </div>
    );
  };
  

export default PageContentful;