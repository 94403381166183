import React, { useEffect, useState } from 'react';
import './CompUIAssetsGalleryItem.css';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import FlagIcon from '@mui/icons-material/Flag';
import CompCRUDModal from './CompCRUDModal';
import DeleteIcon from '@mui/icons-material/Delete';
import AddLinkIcon from '@mui/icons-material/AddLink';
import { updateUIAsset } from '../fxAPI/fxUIAssetsAPI.js';
import { getAnchorsByProjectId } from '../fxAPI/fxAnchorsAPI.js';
import Popover from '@mui/material/Popover';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import AdsClickIcon from '@mui/icons-material/AdsClick';


const CompUIAssetsGalleryItem = (props) => {

  const [error, setError] = useState(null);

  const [isOpen, setIsOpen] = useState(false);
  const [modalMode, setModalMode] = useState(false);
  const [modalItemType, setModalItemType] = useState(props.itemType);
  const [anchorEl, setAnchorEl] = useState(null);
  const [assignedAnchor, setAssignedAnchor] = useState(null);
  const [anchorsByProject, setAnchorsByProjectData] = useState([]);
  const [currentUIAsset, setCurrentUIAsset] = useState(null);

  /* ANCHORS */
  const fxAPIFetchAnchorsByProjectId = async (projectId) => {
    const data = await getAnchorsByProjectId(projectId, setError, setAnchorsByProjectData);
    console.log('Fetched anchors:', data); // This will log the actual returned data
};

  const handleChange = (event) => {
    console.log('Selected Anchor ID:', event.target.value); // Debugging log
    setAssignedAnchor(event.target.value);
  };

  const handleAddLinkClick = (event) => {
    setAnchorEl(event.currentTarget);
    console.log('UI asset for: ' + props.componentType);
    console.dir(props.componentData);
    setCurrentUIAsset(props.componentData.ui_asset_id);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const openModal = (currentModalMode, currentModalItemType) => {
    setModalMode(currentModalMode);
    setModalItemType(currentModalItemType);
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

      const modalTitleCompPortion = "UI Asset";

      useEffect(() => {
        const fetchAnchors = async () => {
          const currentProjectId = props.relatedProject;
          console.log('Fetching anchors for projectId:', currentProjectId); // Log projectId before API call
          fxAPIFetchAnchorsByProjectId(props.relatedProject);
        };
        if (props.relatedProject) {
          fetchAnchors();
        } else {
          console.warn('No relatedProject available.');
        }
      }, [props.relatedProject]);
      
      useEffect(() => {
        console.log('anchorsByProject state:', anchorsByProject); // Debugging log
      }, [anchorsByProject]);

  return (
    <>
    <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        className="fx-user-assign-popover-container"
        PaperProps={{
          style: {
            padding: '16px', // Adjust padding
            minWidth: '200px' // Adjust min-width
          },
        }}
      >
        <div className="fx-user-assign-popover-row">
          <FormControl fullWidth>
            <InputLabel id="anchor-assigned-label">Related Anchor</InputLabel>
            <Select
              labelId="anchor-assigned-label"
              id="anchor-assigned"
              value={assignedAnchor}
              label="Assign To"
              onChange={handleChange}
            >
              
              {anchorsByProject.map((anchor) => (
                <MenuItem key={anchor.anchor_id} value={anchor.anchor_id}>
                  {`${anchor.name} - ${anchor.type}`}
                </MenuItem>
              ))}

            </Select>
          </FormControl>
          <Button onClick={() => updateUIAsset(currentUIAsset, null, null, null, assignedAnchor)}>Assign</Button>
        </div>
      </Popover>
    
    <CompCRUDModal isOpen={isOpen} onClose={closeModal} modalTitle={modalTitleCompPortion + ": " + props.uiTitle} modalComponentData={props.componentData} itemType="ui-asset" crudMethod={modalMode}></CompCRUDModal>
      <div className="fx-comp-ui-assets-gallery-item">
        <div className="fx-comp-ui-assets-gallery-item-inner">
          <h4>{props.uiTitle}</h4>
          <img src={props.uiImgSrc} width="100%" height="auto" />
        </div>
        <div className="fx-comp-ui-assets-gallery-item-footer">
          {/*<Button><FlagIcon /></Button>*/}
          <Button onClick={() => openModal("delete", "status")}>
                  <DeleteIcon />
          </Button>
          <Button href={`/ui-asset-detail/${props.componentData.ui_asset_id}`}><OpenInFullIcon /></Button>
          <div aria-describedby={id} onClick={handleAddLinkClick}><AddLinkIcon /></div>
        </div>
        {/*A: {props.componentData.related_anchor}*/}
      </div>

    </>
  );
};

export default CompUIAssetsGalleryItem;