import React from 'react';
import './PageWebContact.css';
import CompWebHeader from '../Components/CompWebHeader';
import CompWebFooter from '../Components/CompWebFooter';
import Button from '@mui/material/Button';
import CompWebSendGridForm from '../Components/CompWebSendGridForm';
import { Grid, Box } from '@mui/material';
import Typography from '@mui/material/Typography';

const PageWebContact = (props) => {

  return (
    <div>
      <CompWebHeader />
      
      <div className="fx-page-start-login-splash-wrap">
        <Typography variant="h1" className="fx-page-start-marquee-text-white">
          Drop us a Line
        </Typography>
        <Typography variant="h4" className="fx-page-start-marquee-text-teal">
          We'll Assist you with Transforming your Design Process Soon
        </Typography>
      </div>

    <div className="fx-page-start-content-block-container fx-page-start-content-block-container-gray">
      <div className="fx-page-start-content-block-container-inner">
        <h2>Sign up as an Early-Adopter or ask a general question</h2>
        <Grid container spacing={2}>
          <Grid item xs={12} className="fx-grid-item-xs-full">
            <Box sx={{ width: '100%' }}>
              <p className="fx-page-start-feature-paragraph">
                Give us some background on your organization and how you are looking to improve your product development process. We will be in touch with you soon.
              </p>
              <p className="fx-page-start-feature-paragraph">
                Connect with us:
                <div><a href="http://www.linkedin.com/company/fluxity"><Button>On LinkedIn</Button></a></div>
              </p>
            </Box>
          </Grid>
          <Grid item xs={12} className="fx-grid-item-xs-full">
            <Box sx={{ width: '100%' }}>
              <div className="fx-page-investor-organize-and-execute-inner-container">
                <div className="fx-page-investor-organize-and-execute-item-wrap">
                  <CompWebSendGridForm />
                </div>
              </div>
            </Box>
          </Grid>
        </Grid>
      </div>
    </div>

      <CompWebFooter />
    </div>
  );
};

export default PageWebContact;