import React, { useState, useEffect } from 'react';
import './CompObjectiveStack.css';
import { getObjectivesByProjectId } from '../fxAPI/fxObjectivesAPI.js';
import CompSubjectivePanel from './CompSubjectivePanel.jsx';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import CompObjectiveToolset from './CompObjectiveToolset.jsx';

const CompObjectiveStack = (props) => {
  const [error, setError] = useState(null);
  const [isChecked, setIsChecked] = useState(false);
  const [objectivesByProjectId, setObjectivesByProjectData] = useState([]);

  const handleToggle = (event) => {
    setIsChecked(event.target.checked);
  };

  const fxAPIFetchObjectivesByProjectId = async (projectId) => {
    await getObjectivesByProjectId(projectId, setError, setObjectivesByProjectData);
  };

  useEffect(() => {
    fxAPIFetchObjectivesByProjectId(props.relatedProject);
  }, [props.relatedProject]);

  // Filter objectives based on the toggle
  const filteredObjectives = isChecked
    ? objectivesByProjectId.filter((objective) => objective.progress < 100)
    : objectivesByProjectId;

  return (
    <div>
      <h4>Objectives</h4>
      <div className="fx-comp-objective-stack-toggle-row">
        <FormGroup>
          <FormControlLabel
            control={
              <Switch
                checked={isChecked}
                onChange={handleToggle}
                color="primary"
              />
            }
            label="Hide Completed"
          />
        </FormGroup>
      </div>
      <>
        {filteredObjectives.map((objectiveItem) => (
          <div
            key={objectiveItem.objective_id}
            data-flag-progress={objectiveItem.progress}
          >
            <CompSubjectivePanel
              relatedObjective={objectiveItem.objective_id}
              panelName={objectiveItem.name}
              deferLoading={true} // Add this prop to defer loading
              actionSet={<CompObjectiveToolset relatedObjectiveId={objectiveItem.objective_id} relatedObjectiveData={objectiveItem} />} 
            />
          </div>
        ))}
      </>
    </div>
  );
};

export default CompObjectiveStack;