import React from 'react';
import './CompHyperlogicDashCard.css';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import Tooltip from '@mui/material/Tooltip';
import DeleteIcon from '@mui/icons-material/Delete'; // Import the Trash icon

// Create a global map to store functions
export const dragFunctionMap = new Map();

const CompHyperlogicDashCard = (props) => {
    const handleDragStart = (e) => {
        const uniqueId = `${props.title}-${Date.now()}`; // Generate a unique identifier
        dragFunctionMap.set(uniqueId, props.onRemove); // Store the onRemove function in the map

        const dragData = {
            heading: props.title,
            description: props.description || '', // Use the description prop
            id: uniqueId, // Pass the unique identifier
            columnHeading: props.columnHeading, // Include the column heading
        };
        console.log('Drag Data:', dragData); // Log the drag data
        e.dataTransfer.setData('text/plain', JSON.stringify(dragData));
    };

    const handleContentDragStart = (e) => {
        e.stopPropagation();
    };

    const handleRemoveClick = () => {
        if (props.onRemove) {
            props.onRemove(); // Call the onRemove function passed via props
        }
    };

    const truncateText = (text, maxLength) => {
        return text.length > maxLength ? `${text.substring(0, maxLength)}...` : text;
    };

    return (
        <div
            className="fx-comp-hyperlogic-dash-card-wrapper"
            draggable
            onDragStart={handleDragStart}
        >
            <div className="fx-comp-hyperlogic-dash-card-drag-handle">
                <div className="fx-comp-hyperlogic-dash-card-drag-handle-icon-left">
                    <DragHandleIcon />
                </div>
                <div className="fx-comp-hyperlogic-dash-card-drag-handle-icon-right">
                    <ArrowOutwardIcon />
                    <DeleteIcon onClick={handleRemoveClick} style={{ cursor: 'pointer' }} />
                </div>
            </div>
            <div className="fx-comp-hyperlogic-dash-card-header">
                <div className="fx-comp-hyperlogic-dash-card-header-text">{props.title}</div>
            </div>
            <div
                className="fx-comp-hyperlogic-dash-card-content"
                onDragStart={handleContentDragStart}
            >
                <Tooltip title={props.description || ''} arrow>
                    <span>{truncateText(props.description || '', 100)}</span>
                </Tooltip>
            </div>
            <div className="fx-comp-hyperlogic-dash-card-footer">
                <div className="fx-comp-hyperlogic-dash-card-footer-text">{props.footer}</div>      
            </div>
        </div>
    );
};

export default CompHyperlogicDashCard;
