import React, { useState, useEffect, useContext } from 'react';
import './CompObjectiveTimeline.css';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import CompSubjectivePanel from './CompSubjectivePanel';
import Avatar from '@mui/material/Avatar';
import Slider from '@mui/material/Slider';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { getViewObjectivesProjectsByOrgId, getObjectivesByProjectId, getViewObjectivesProjectsByProjectId } from '../fxAPI/fxObjectivesAPI.js';
import CompTimeline from './CompTimeline.jsx';
import dayjs from "dayjs";
import CompQuickCreateObjective from './CompQuickCreateObjective.jsx';
import PageviewIcon from '@mui/icons-material/Pageview';
import { PanelContext } from '../Contexts/PanelContext';
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';


const CompObjectiveTimeline = (props) => {

  const [error, setError] = useState(null);
  const [refresh, setRefresh] = useState(false);
  const [viewObjectivesProjectsByProjectData, setViewObjectivesProjectsByProjectData] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [selectedTag, setSelectedTag] = useState("");
  const [selectedDeadline, setSelectedDeadline] = useState("");
  const [selectedPhase, setSelectedPhase] = useState("");


  const handleRefreshAction = () => {
    // Perform some action in the parent
    // After the action, toggle the refresh state
    setRefresh(prev => !prev);
  };

  /* OBJECTIVES */
  /* get objectives by projectId */
  const fxAPIFetchviewObjectivesProjectsByProjectId = async (projectId) => {
    await getViewObjectivesProjectsByProjectId(projectId, setError, setViewObjectivesProjectsByProjectData);
    console.log('all view objectives projects by project Id');
    console.dir(viewObjectivesProjectsByProjectData);
  };

  const items = viewObjectivesProjectsByProjectData;

  const itemsPerPage = 4;

  const handleTagChange = (e) => {
    setSelectedTag(e.target.value);
    setCurrentIndex(0);
  };

  const handleDeadlineChange = (e) => {
    setSelectedDeadline(e.target.value);
    setCurrentIndex(0);
  };

  const handlePhaseChange = (e) => {
    setSelectedPhase(e.target.value);
    setCurrentIndex(0);
  };

  // Filter items based on selected tag, deadline, and phase
  const filteredItems = items.filter((item) => {
    const tagMatch = selectedTag ? item.tags.includes(selectedTag) : true;
    const deadlineMatch = selectedDeadline ? item.deadline === selectedDeadline : true;
    const phaseMatch = selectedPhase ? item.phase === selectedPhase : true;
    return tagMatch && deadlineMatch && phaseMatch;
  });

  const handleNext = () => {
    setCurrentIndex((prevIndex) => Math.min(prevIndex + 1, filteredItems.length - itemsPerPage));
    setRefresh(prev => !prev); // Trigger refresh
  };

  const handlePrev = () => {
    setCurrentIndex((prevIndex) => Math.max(prevIndex - 1, 0));
    setRefresh(prev => !prev); // Trigger refresh
  };

  const visibleItems = filteredItems.slice(currentIndex, currentIndex + itemsPerPage);

  useEffect(() => {
    const currentOrgId = localStorage.getItem("fxLocalOrgID");
    fxAPIFetchviewObjectivesProjectsByProjectId(props.relatedProject);
  }, []);

  const milestones = [
    [
      { name: "Milestone 1", date: dayjs().subtract(2, 'day').format("YYYY-MM-DD"), duration: 1 },
      { name: "Milestone 2", date: dayjs().format("YYYY-MM-DD"), duration: 2 },
      { name: "Milestone 3", date: dayjs().add(3, 'day').format("YYYY-MM-DD"), duration: 1 },
    ],
    [
      { name: "Milestone A", date: dayjs().subtract(1, 'day').format("YYYY-MM-DD"), duration: 2 },
      { name: "Milestone B", date: dayjs().add(4, 'day').format("YYYY-MM-DD"), duration: 3 },
    ]
  ];

  const { updatePanelContext } = useContext(PanelContext);

  const handleSwitchContext = (objective_id, object) => {
    updatePanelContext("objective", objective_id, object);
  };


  return (
    <div>
      <div>
        <CompQuickCreateObjective relatedProject={props.relatedProject} refreshParent={props.refreshParent} />
      </div>
      <div className="carousel-container">
        <div className="filter-toolbar">
          <div className="filter-container">
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Filter by Tag:</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={selectedTag}
                onChange={handleTagChange}
              >
                <MenuItem value="">All</MenuItem>
                {/* Add other tag options */}
              </Select>
            </FormControl>
          </div>

          <div className="filter-container">
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Filter by deadline:</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={selectedDeadline}
                onChange={handleDeadlineChange}
              >
                <MenuItem value="">All</MenuItem>
                {/* Add other deadline options */}
              </Select>
            </FormControl>
          </div>

          <div className="filter-container">
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Filter by phase:</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={selectedPhase}
                onChange={handlePhaseChange}
              >
                <MenuItem value="">All</MenuItem>
                {/* Add other phase options */}
              </Select>
            </FormControl>
          </div>
        </div>

        {/* Carousel */}
        <button onClick={handlePrev} className="carousel-button left">←</button>
        <div className="carousel-items">
          {visibleItems.map((item) => (
            <div key={item.id} className="carousel-item">
              <div className="carousel-item-inner">
                <div className="objective-item-progress">
                  <Slider defaultValue={50} aria-label="Default" valueLabelDisplay="auto" />
                </div>
                <div className="objective-item-heading-container">
                  <Avatar>H</Avatar>
                  <div className='fx-objective-header-content-item-heading'>
                    <div className='fx-objective-header-content-item-heading-primary'>{item.objective_view_name}</div>
                    <div className='fx-objective-header-content-item-heading-secondary'>{item.project_view_name}</div>
                  </div>
                  <div className="fx-objective-header-content-preview-icon" onClick={() => handleSwitchContext(item.objective_id, item)}><ArrowOutwardIcon /></div>
                </div>
              </div>
              {/* Pass refresh and relatedObjective to CompSubjectivePanel */}
              <CompSubjectivePanel 
                refreshParent={props.refreshParent} 
                panelName="Subjectives" 
                relatedObjective={item.objective_id} 
                relatedProject={props.relatedProject} 
                refresh={refresh}  // Use refresh to trigger panel update
                //updatePanelContext={(contextType, objectId, object) => props.updatePanelContext(contextType, objectId, object)}
              />
            </div>
          ))}
        </div>
        <button onClick={handleNext} className="carousel-button right">→</button>
      </div>
    </div>
  );
};

export default CompObjectiveTimeline;