import React, { useEffect, useState, useContext } from 'react';
import { useParams } from "react-router-dom";
import './PageTeam.css';
import CompTeamMemberList from '../Components/CompTeamMemberList';
import CompPageTempMaster3Panel from '../Components/CompPageTempMaster3Panel';
import CompCRUDFormTeamMember from '../Components/CompCRUDFormTeamMember';

const PageTeamAddTeamMember = (props) => {
  


  return (
    <CompPageTempMaster3Panel headingOne="Add Team Member">
      
      <CompCRUDFormTeamMember crudMethod="create" />
   
    </CompPageTempMaster3Panel>
  );
};

export default PageTeamAddTeamMember;
