import React, { useEffect, useState } from "react";
import axios from "axios";
import { getComponentsByOrgId } from "../fxAPI/fxComponentsAPI";
import { getObjectLinksByInitialObjectAndLinkTypeAndOrg } from "../fxAPI/fxObjectLinksAPI";
import { getAnchorsByProjectId } from "../fxAPI/fxAnchorsAPI";

const ComponentUseMatrix = ({ orgId, projectId }) => {
  const [components, setComponents] = useState([]);
  const [anchors, setAnchors] = useState([]);
  const [objectLinks, setObjectLinks] = useState([]);
  const [error, setError] = useState(null);

  // Fetch Components
  useEffect(() => {
    getComponentsByOrgId(orgId, setError, (data) => {
      console.log("Fetched Components:", data);
      setComponents(data);
    });
  }, [orgId]);

  // Fetch Anchors and then Object Links
  useEffect(() => {
    getAnchorsByProjectId(projectId, setError, (anchorData) => {
      console.log("Fetched Anchors:", anchorData);
      setAnchors(anchorData);

      // Fetch Object Links for each anchor_id
      if (anchorData.length > 0) {
        const allLinks = [];

        // Create an array of promises to fetch links for each anchor_id
        const fetchLinksPromises = anchorData.map((anchor) =>
          getObjectLinksByInitialObjectAndLinkTypeAndOrg(
            anchor.anchor_id, // Use anchor_id instead of "anchor"
            orgId,
            "component",
            setError,
            (data) => {
              console.log(`Fetched Object Links for anchor ${anchor.anchor_id}:`, data);
              allLinks.push(...data);
            }
          )
        );

        // Wait for all requests to complete before updating state
        Promise.all(fetchLinksPromises).then(() => setObjectLinks(allLinks));
      }
    });
  }, [projectId, orgId]);

  // Function to find if a component is linked to an anchor
  const getLinkStatus = (componentId, anchorId) => {
    console.log("Checking link:", { componentId, anchorId });

    // Convert to strings to ensure type consistency
    const normalizedComponentId = String(componentId);
    const normalizedAnchorId = String(anchorId);

    const linkExists = objectLinks.some(
      (link) =>
        String(link.initial_object_id) === normalizedAnchorId &&
        String(link.linking_object_id) === normalizedComponentId
    );

    console.log(`Link exists for component ${componentId} and anchor ${anchorId}:`, linkExists);
    return linkExists ? "✔️" : "-";
  };

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "10px", padding: "20px" }}>
      {error && <div style={{ color: "red" }}>{error}</div>}
      
      {/* Header Row */}
      <div style={{ display: "flex", fontWeight: "bold", borderBottom: "2px solid black", paddingBottom: "5px" }}>
        <div style={{ width: "150px" }}>Component</div>
        {anchors.map((anchor) => (
          <div key={anchor.anchor_id} style={{ width: "100px", textAlign: "center" }}>
            {anchor.name}
          </div>
        ))}
      </div>

      {/* Data Rows */}
      {components.map((component) => (
        <div key={component.component_id} style={{ display: "flex", alignItems: "center" }}>
          {/* Row Header (Component Name) */}
          <div style={{ width: "150px", fontWeight: "bold", borderRight: "1px solid #ddd", padding: "5px" }}>
            {component.name}
          </div>
          {/* Columns (Anchor Links) */}
          {anchors.map((anchor) => (
            <div key={anchor.anchor_id} style={{ width: "100px", textAlign: "center", border: "1px solid #ddd", padding: "5px" }}>
              {getLinkStatus(component.component_id, anchor.anchor_id)}
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

export default ComponentUseMatrix;
