import axios from 'axios';

const createResearchElement = async (research_element_id, name, description, related_files, related_links, date_time_created, date_time_updated, related_research_type, related_research_focus, related_org, setResearchElementResponse) => {
  try {
    if (!research_element_id || !name) {
      console.warn('Missing required parameters: research_element_id or name.');
      return;
    }

    console.log(`Creating research element with ID: ${research_element_id}, name: ${name}`);

    const response = await axios.post('/fxapi/research-elements/create', {
      research_element_id,
      name,
      description,
      related_files,
      related_links,
      date_time_created,
      date_time_updated,
      related_research_type,
      related_research_focus,
      related_org
    });

    console.log('API response received:', response.data);

    setResearchElementResponse(response.data);
  } catch (error) {
    console.error('Error creating research element:', error);
  }
};

const getResearchElementsByOrg = async (orgId, setError, setResearchElementsByOrgData) => {
  setError(null);
  setResearchElementsByOrgData([]);

  if (!orgId) {
    setError('orgId parameter is required');
    return [];
  }

  try {
    console.log(`Fetching research elements for orgId: ${orgId}`);
    const response = await axios.get('/fxapi/research-elements/research-elements-by-org', {
      params: { orgId }
    });
    console.log('API response received:', response.data);

    const items = Array.isArray(response.data) ? response.data : [];
    setResearchElementsByOrgData(items);
    return items;
  } catch (error) {
    console.error('Error fetching research elements by org:', error);
    setError(error.response ? error.response.data : 'Failed to fetch research elements by org');
    return [];
  }
};

const getResearchElementsByAnchor = async (anchorId, setError) => {
  try {
    const response = await axios.get('/fxapi/research-elements/research-elements-by-anchor', {
      params: { anchorId }
    });
    console.log('API response received:', response.data);

    return response.data;
  } catch (error) {
    console.error('Error fetching research elements:', error);
    if (setError) {
      setError(error.response ? error.response.data : 'Failed to fetch research elements');
    }
    return [];
  }
};

const updateResearchElement = async (research_element_id, name, description, related_files, related_links, date_time_created, date_time_updated, related_research_type, related_research_focus, related_org) => {
  try {
    if (!research_element_id) {
      console.warn('Missing required parameter: research_element_id.');
      return;
    }

    console.log(`Updating research element with ID: ${research_element_id}`);

    const response = await axios.put(`/fxapi/research-elements/update/${research_element_id}`, {
      name,
      description,
      related_files,
      related_links,
      date_time_created,
      date_time_updated,
      related_research_type,
      related_research_focus,
      related_org
    });

    console.log('API response received:', response.data);
  } catch (error) {
    console.error('Error updating research element:', error);
  }
};

const deleteResearchElement = async (researchElementId, setError, setDeleteResponse) => {
  setError(null);
  setDeleteResponse(null);

  if (!researchElementId) {
    setError('researchElementId parameter is required');
    return;
  }

  try {
    console.log(`Deleting research element with ID: ${researchElementId}`);
    const response = await axios.delete(`/fxapi/research-elements/delete/${researchElementId}`);
    console.log('API response received:', response.data);

    const successMessage = `Successfully deleted research element with ID: ${researchElementId}`;
    setDeleteResponse(successMessage);
    console.log('Updated delete response:', successMessage);
  } catch (error) {
    console.error('Error deleting research element:', error);

    const errorMessage = error.response ? error.response.data : 'Failed to delete research element';
    setError(typeof errorMessage === 'string' ? errorMessage : JSON.stringify(errorMessage));
  }
};

const getResearchElementsByFocusOrg = async (focusArea, orgId, setError, setResearchElementsByFocusOrgData) => {
  if (setError) setError(null); // Ensure setError is called only if it exists
  if (setResearchElementsByFocusOrgData) setResearchElementsByFocusOrgData([]);

  if (!focusArea || !orgId) {
    if (setError) setError('Both focusArea and orgId parameters are required');
    return [];
  }

  try {
    console.log(`Fetching research elements for focusArea: ${focusArea} and orgId: ${orgId}`); // Debugging log
    const response = await axios.get(`/fxapi/research-elements/research-elements-by-focus-org/${focusArea}/${orgId}`);
    console.log('API response received:', response.data); // Debugging log

    if (response.data && typeof response.data === 'object') {
      const items = Array.isArray(response.data.items) ? response.data.items : [];
      console.log('Extracted items:', items); // Debugging log
      if (setResearchElementsByFocusOrgData) setResearchElementsByFocusOrgData(items);
      return items;
    } else {
      throw new Error('Unexpected response format');
    }
  } catch (error) {
    console.error('Error fetching research elements by focus and org:', error);
    if (setError) setError(error.response ? error.response.data : 'Failed to fetch research elements by focus and org');
    return [];
  }
};

export { 
  createResearchElement, 
  getResearchElementsByOrg, 
  getResearchElementsByAnchor, 
  updateResearchElement, 
  deleteResearchElement, 
  getResearchElementsByFocusOrg 
};
