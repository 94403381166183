import React, { useState, useEffect } from 'react';
import './CompComponentDashboardItem.css';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { getUIAssetById } from '../fxAPI/fxUIAssetsAPI';


const CompComponentDashboardItem = (props) => {

    const [error, setError] = useState(null);

    const [tabValue, setTabValue] = useState(0);
    
    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    /* UI Asset By ID */
  const [uiAssetById, setUIAssetByIdData] = useState([]); // Initialize as an array
  /* get UI Assets by Id */
  const fxAPIFetchUIAssetById = async (uiAssetId) => {
    await getUIAssetById(uiAssetId, setError, setUIAssetByIdData);
    console.log('all ui assets by org Id');
    console.dir(uiAssetById);
  };

  useEffect(() => {
    fxAPIFetchUIAssetById(props.componentImage);
    console.log('found UI Asset for component: ');
    console.dir(uiAssetById);
  }, []);

  return (
    <div className='fx-comp-component-dash-item-wrap'>
        <div>
            <h4>Component: {props.componentName}</h4>
        </div>
        <div className='fx-comp-component-dash-item-row'>
        <div className='fx-comp-component-dash-item-image-portion'>
        {props.componentImage && uiAssetById.length > 0 ? (
            <img
                src={uiAssetById[0]?.img_thumb_url}
                width="200px"
                height="200px"
                alt="Component Thumbnail"
            />
        ) : (
            <p>No image provided.</p>
        )}
        </div>
        <div className='fx-comp-component-dash-item-detail-portion'>
            <div>
            <Tabs value={tabValue} onChange={handleTabChange}>
                    <Tab label="Description" />
                    {/*<Tab label="Manual Checks" />
                    <Tab label="Accessibility Tests" />*/}      
                    </Tabs>
                {tabValue === 0 && (
                    <>
                        
                        <div>
                            {props.componentDescription}
                        </div>  
                    </>
                )}
                {/*
                {tabValue === 1 && (
                    <>
                        <div>other component aspects future...</div> 
                    </>
                )}
                {tabValue === 2 && (
                    <>
                        <div>other component aspects future...</div> 
                    </>
                )}
                    */}
            </div>
        </div>
        </div>
    </div>
  );
};

export default CompComponentDashboardItem;