import React, { useState, useEffect, useContext, useLayoutEffect } from 'react';
import './CompPageTempMaster3Panel.css';
import { Box } from '@mui/material';
import CompSideNavMaster from './CompSideNavMaster';
import CompHeader from './CompHeader';
import CompGlobalTaskBar from './CompGlobalTaskBar';
import CompSideContextPanel from './CompSideContextPanel';
import CompActivityPanel from './CompActivityPanel';
import { PanelContext } from '../Contexts/PanelContext';
import { PanelProvider } from '../Contexts/PanelContext';
import CompBottomTray from './CompBottomTray';
import { ActivityHistoryProvider } from '../Contexts/ActivityHistoryContext';

export const refreshMainLayoutContext = (resetElementRefresh, triggerElementRefresh) => {
  const elementId = 'mainLayout';
  const handleRefresh = async () => {
    const currentLayout = localStorage.getItem('fxScreenLayout');
    console.log('ran main layout refresh');
    resetElementRefresh(elementId);
  };

  handleRefresh();
  triggerElementRefresh(elementId);
};

const CompPageTempMaster3Panel = ({ children, headingOne }) => {
  const { elements, triggerElementRefresh, resetElementRefresh } = useContext(PanelContext);
  const elementId = 'mainLayout';

  const [isScrolled, setIsScrolled] = useState(false);
  const [height, setHeight] = useState(0);
  const [col1Width, setCol1Width] = useState(0);
  const [col3Width, setCol3Width] = useState(0);
  const [currentScreenLayout, setCurrentScreenLayout] = useState('columns');
  const [transitionsEnabled, setTransitionsEnabled] = useState(false);

  const calculateHeight = () => window.innerHeight - 195;

  useEffect(() => {
    const handleRefresh = async () => {
      const currentLayout = localStorage.getItem('fxScreenLayout');
      setCurrentScreenLayout(currentLayout);
      console.log('ran main layout refresh');
      resetElementRefresh(elementId);
    };

    if (elements[elementId]?.refresh) {
      handleRefresh();
      triggerElementRefresh(elementId);
    }
  }, [elements[elementId]?.refresh, triggerElementRefresh, resetElementRefresh]);

  useLayoutEffect(() => {
    const handleScroll = () => setIsScrolled(window.scrollY > 125);

    const handleResize = () => {
      setHeight(calculateHeight());
      const col1Outer = document.querySelector('.comp-page-master-3panel-col-1-outer');
      const col3Outer = document.querySelector('.comp-page-master-3panel-col-3-outer');
      if (col1Outer) setCol1Width(col1Outer.offsetWidth);
      if (col3Outer) setCol3Width(col3Outer.offsetWidth);
    };

    handleResize(); // Initial measurements
    window.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', handleResize);

    // Enable transitions after initial render
    const timer = setTimeout(() => setTransitionsEnabled(true), 100);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleResize);
      clearTimeout(timer);
    };
  }, []);

  const testLayoutValue = localStorage.getItem('fxScreenLayout') || null;

  return (
    <div className="comp-page-master-3panel">
      <CompHeader />
      <CompGlobalTaskBar />
      {testLayoutValue !== 'full' && (
        <div className="comp-page-master-3panel-column-set">
          <div className="comp-page-master-3panel-col-1-outer">
            <div
              className="comp-page-master-3panel-col-1"
              style={{
                width: `${col1Width}px`,
                position: 'fixed',
                top: isScrolled ? '0px' : '125px',
                left: '0px',
                height: `${height}px`,
                transition: transitionsEnabled ? 'top 0.3s ease-in-out, height 0.2s ease-in-out, width 0.2s ease-in-out' : 'none',
                marginLeft: '8px',
              }}
            >
              <CompSideNavMaster isCollapsed="not collapsed" />
            </div>
          </div>
          <div className="comp-page-master-3panel-col-2-outer">
            <div className="comp-page-master-3panel-col-2">
              <h1>{headingOne}</h1>
              {children}
            </div>
          </div>
          <div className="comp-page-master-3panel-col-3-outer">
            <div
              className="comp-page-master-3panel-col-3"
              style={{
                width: `${col3Width}px`,
                position: 'fixed',
                top: isScrolled ? '0px' : '125px',
                right: '0px',
                height: `${height}px`,
                transition: transitionsEnabled ? 'top 0.3s ease-in-out, height 0.2s ease-in-out, width 0.2s ease-in-out' : 'none',
                marginRight: '8px',
              }}
            >
              <CompSideContextPanel />
              {!localStorage.getItem('fxSidePanelContext') && <CompActivityPanel />}
            </div>
          </div>
        </div>
      )}
      {testLayoutValue === 'full' && (
        <div className="comp-page-master-full-column-set">
          <h1>{headingOne}</h1>
          {children}
        </div>
      )}
      <div className="bottom-tray">
        <CompBottomTray />
      </div>
    </div>
  );
};

const WrappedCompPageTempMaster3Panel = (props) => (
  <PanelProvider>
    <ActivityHistoryProvider>
      <CompPageTempMaster3Panel {...props} />
    </ActivityHistoryProvider>
  </PanelProvider>
);

export default WrappedCompPageTempMaster3Panel;


