import React, { useState, useEffect } from 'react';
import './CompUIAssetBulkProcessor.css';
import {
  Box,
  Typography,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  TextField,
  Select,
  MenuItem,
  Button,
  Collapse,
  FormControlLabel,
  Checkbox
} from '@mui/material';
import { getProjectsByOrgId } from '../fxAPI/fxProjectsAPI.js';
import { createUIAsset } from '../fxAPI/fxUIAssetsAPI.js';
import { getComponentsByOrgId, updateComponent } from '../fxAPI/fxComponentsAPI.js';
import { createAnchor, getAnchorsObjectivesByProjectId, updateAnchor } from '../fxAPI/fxAnchorsAPI.js';
import CompCollapsibleContainer from './CompCollapsibleContainer.jsx';
import { createObjectLink } from '../fxAPI/fxObjectLinksAPI.js';
import { getUserPersonasByOrgId } from '../fxAPI/fxUserPersonasAPI.js'; // Import getUserPersonasByOrgId

const CompUIAssetBulkProcessor = (props) => {

  const [error, setError] = useState(null);

  const [imageUrl, setImageUrl] = useState('');
  const [uiAssetResponse, setUIAssetResponse] = useState(null);
  const [uploadedImages, setUploadedImages] = useState([]);
  const [journeyStepName, setJourneyStepName] = useState('');
  const [userPersonas, setUserPersonas] = useState([]); // State for user personas
  const [selectedPersonas, setSelectedPersonas] = useState([]); // State for selected personas
  const [expanded, setExpanded] = useState(false); // State for accordion expansion

  const fetchUserPersonas = async () => {
    const orgId = localStorage.getItem("fxLocalOrgID");
    await getUserPersonasByOrgId(orgId, setError, setUserPersonas);
  };

  useEffect(() => {
    fetchUserPersonas();
  }, []);

  const handlePersonaCheckboxChange = (id, event) => {
    const { value, checked, name } = event.target;
    setUploadedImages((prevImages) =>
      prevImages.map((img) => {
        if (img.id === id) {
          const updatedSelection = checked
            ? [...(img.selectedPersonas || []), { user_persona_id: value, name }]
            : (img.selectedPersonas || []).filter((persona) => persona.user_persona_id !== value);
          return { ...img, selectedPersonas: updatedSelection };
        }
        return img;
      })
    );
  };

  const handleAccordionChange = () => {
    setExpanded(!expanded);
  };

/* PROJECTS */
  const [projectsByOrgId, setProjectsByOrgData] = useState([]); // Initialize as an array
  /* get user personas by orgId */
  const fxAPIFetchProjectsByOrgId = async (orgId) => {
    await getProjectsByOrgId(orgId, setError, setProjectsByOrgData);
    console.log('all projects by org Id');
    console.dir(projectsByOrgId);
  };

  /* COMPONENTS */
  const [componentsByOrgId, setComponentsByOrgData] = useState([]); // Initialize as an array
  /* get user personas by orgId */
  const fxAPIFetchComponentsByOrgId = async (orgId) => {
    await getComponentsByOrgId(orgId, setError, setComponentsByOrgData);
    console.log('all components by org Id');
    console.dir(componentsByOrgId);
  };

  /* ANCHORS */
  const [anchorsObjectivesByProjectId, setAnchorsObjectivesByProjectData] = useState([]); // Initialize as an array
  /* get anchors objectives by project */
  const fxAPIFetchAnchorsObjectivesByProjectId = async (projectId) => {
    await getAnchorsObjectivesByProjectId(projectId, setError, setAnchorsObjectivesByProjectData);
    console.log('all anchors objectives by project Id');
    console.dir(anchorsObjectivesByProjectId);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const files = Array.from(e.dataTransfer.files).filter((file) =>
      file.type.startsWith('image/')
    );
    const images = files.map((file) => ({
      id: URL.createObjectURL(file),
      file,
      title: '',
      project: '',
    }));
    setUploadedImages(images);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleTitleChange = (id, newTitle) => {
    setUploadedImages((prevImages) =>
      prevImages.map((img) =>
        img.id === id ? { ...img, title: newTitle } : img
      )
    );
  };

  const handleProjectChange = (id, newProject) => {
    setUploadedImages((prevImages) =>
      prevImages.map((img) =>
        img.id === id ? { ...img, project: newProject } : img
      )
    );
  };
  
  const handleAnchorChange = (id, newAnchor) => {
    setUploadedImages((prevImages) =>
      prevImages.map((img) =>
        img.id === id ? { ...img, anchor: newAnchor } : img
      )
    );
  };

  const handleComponentChange = (id, newComponent) => {
    setUploadedImages((prevImages) =>
      prevImages.map((img) =>
        img.id === id ? { ...img, component: newComponent } : img
      )
    );
  };

  const handleJourneyStepChange = (id, newJourneyStep) => {
    setUploadedImages((prevImages) =>
      prevImages.map((img) =>
        img.id === id ? { ...img, journeyStep: newJourneyStep } : img
      )
    );
    console.log(`Journey step for image ${id} set to: ${newJourneyStep}`);
  };

const handleCreateUIAsset = async (currentUIAssetId, currentImgUrl, imageTitle, imageRelatedObject) => {
  console.log('current image url: ' + currentImgUrl);
  const imgFull = currentImgUrl;
  const imgThumb = `${currentImgUrl.replace("/public", "")}/w=200,h=200,fit=crop,crop=centered`;
  const version = 'version here';
  const relatedOrg = localStorage.getItem("fxLocalOrgID");
  const relatedProject = props.context === "default" ? imageRelatedObject : null;
  const name = imageTitle;
  const uiAssetId = currentUIAssetId;
  // const uiAssetId = generateUUID();

  

  try {
    // Create UI Asset
    await createUIAsset(
      uiAssetId,
      relatedOrg,
      relatedProject,
      name,
      imgFull,
      imgThumb,
      version,
      setUIAssetResponse,
      setError
    );

    if (props.context === "component") {
      // Update the Component only if context is "component"
      await handleUpdateComponent(imageRelatedObject, uiAssetId);
    }

    if (props.context === "anchor") {
      // Update the Anchor only if context is "anchor"
      await handleUpdateAnchor(imageRelatedObject, uiAssetId);
    }

  } catch (error) {
    console.error('Error creating UI asset or updating component/anchor:', error);
  }
};

function generateUUID() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    const r = (Math.random() * 16) | 0, v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

const handleAddImage = async (id) => {
  const uiAssetId = generateUUID();
  const image = uploadedImages.find((img) => img.id === id);
  console.log(`Adding image at handleAddImage with ID: ${id}`);
  console.dir(image);
  if (!image || !image.file) {
    console.error('No valid image file found for the provided ID');
    return;
  }

  const formData = new FormData();
  formData.append('file', image.file);

  try {
    const response = await fetch('https://fluxity.io/fxapi/upload-image', {
      method: 'POST',
      body: formData,
    });

    if (!response.ok) {
      throw new Error(`Failed to upload image: ${response.statusText}`);
    }

    const data = await response.json();
    const cdnUrl = data?.result?.variants?.[0];
    if (!cdnUrl) {
      throw new Error('Invalid response structure or upload failed');
    }

    await handleCreateUIAsset(uiAssetId, cdnUrl, image.title, image.project || image.component || image.anchor, journeyStepName);

    if (props.context === "journey-wizard") {
      // Create Anchor for journey-wizard context
      const relatedOrg = localStorage.getItem("fxLocalOrgID");
      console.log('Image found:', image);
      if (!image || !image.journeyStep || !relatedOrg) {
        console.error('Missing required parameters:', {
          journeyStep: image?.journeyStep,
          relatedOrg
        });
        return;
      }
      const currentAnchorId = generateUUID();
      const newStepName = image.journeyStep;
      const currentPrimaryUIAsset = uiAssetId;
      const currentOrgId = localStorage.getItem("fxLocalOrgID");
      const newStepType = 'assignment';
      const currentRelatedProject = props.relatedProject;
      const currentPosition = null;

      createAnchor(currentAnchorId, newStepName, currentRelatedProject, currentOrgId, currentPosition, newStepType, currentPrimaryUIAsset);
      console.log('Creating anchor for journey-wizard with relatedOrg:' + currentAnchorId + relatedOrg + image.journeyStep);

      createObjectLink(currentAnchorId, "anchor", props.journeyID, "path", null, null, props.relatedProject, currentOrgId);

      // Create object links for selected personas
      (image.selectedPersonas || []).forEach(persona => {
        createObjectLink(currentAnchorId, "anchor", persona.user_persona_id, "user-persona", null, props.relatedProject, currentOrgId);
      });
    }

    // Update the image state to mark it as added
    setUploadedImages((prevImages) =>
      prevImages.map((img) =>
        img.id === id ? { ...img, added: true } : img
      )
    );

  } catch (error) {
    console.error('Error uploading or processing image:', error);
  }
};

const handleUpdateComponent = async (imageRelatedObject, uiAssetId) => {
  if (!imageRelatedObject || !uiAssetId) {
    console.error('Invalid parameters for updateComponent:', {
      imageRelatedObject,
      uiAssetId,
    });
    return;
  }

  console.log(
    `Updating component: Object ID: ${imageRelatedObject}, related UI Asset ID: ${uiAssetId}`
  );

  try {
    await updateComponent(
      imageRelatedObject,
      null, // other parameters as needed
      null,
      null,
      null,
      null,
      uiAssetId,
      null
    );
    console.log('Component updated successfully');
  } catch (error) {
    console.error('Error updating component:', error);
  }
};

const handleUpdateAnchor = async (imageRelatedObject, uiAssetId) => {
  if (!imageRelatedObject || !uiAssetId) {
    console.error('Invalid parameters for updateAnchor:', {
      imageRelatedObject,
      uiAssetId,
    });
    return;
  }

  console.log(
    `Updating anchor: Object ID: ${imageRelatedObject}, Image URL: ${uiAssetId}`
  );

  try {
    await updateAnchor(
      imageRelatedObject,
      null, // other parameters as needed
      null,
      null,
      null,
      null,
      uiAssetId
    );
    console.log('Anchor updated successfully');
  } catch (error) {
    console.error('Error updating anchor:', error);
  }
};


  const handleCancel = () => {
    setUploadedImages([]);
  };

  useEffect(() => {
      const currentOrgId = localStorage.getItem("fxLocalOrgID");
      fxAPIFetchProjectsByOrgId(currentOrgId);
      fxAPIFetchComponentsByOrgId(currentOrgId);
      if(props.relatedProject) {
        fxAPIFetchAnchorsObjectivesByProjectId(props.relatedProject);
      }
    }, []);

  useEffect(() => {
    console.log('Uploaded image set changed to: ');
    console.dir(uploadedImages);
  }, [uploadedImages]);

  

  return (
    <Box sx={{ p: 2 }}>
      {uploadedImages.length === 0 ? (
        <Box
          onDrop={handleDrop}
          onDragOver={handleDragOver}
          sx={{
            border: '2px dashed #ccc',
            borderRadius: '8px',
            textAlign: 'center',
            padding: '40px',
            cursor: 'pointer',
          }}
        >
          <Typography variant="h6">Drag and Drop Images Here</Typography>
          <Typography variant="body2">
            Or click to browse your files.
          </Typography>
        </Box>
      ) : (
        <>
          <Box sx={{ mb: 2, display: 'flex', justifyContent: 'space-between' }}>
            <Typography variant="h6">Uploaded Images</Typography>
            <Button
              variant="outlined"
              color="primary"
              onClick={handleCancel}
            >
              Cancel
            </Button>
          </Box>
          <List>
            {uploadedImages.map((img) => (
              <>
              <ListItem
                key={img.id}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  borderBottom: '1px solid #e0e0e0',
                  py: 1,
                  flexDirection: 'column'
                }}
              >
                <div style={{ display: 'flex', flexDirection: 'row', width: '100%', columnGap: '8px', marginBottom: '8px' }}>
                  <ListItemAvatar>
                    <Avatar
                      variant="square"
                      src={img.id}
                      alt="Thumbnail"
                      sx={{ width: 56, height: 56 }}
                    />
                  </ListItemAvatar>
                  <div className="fx-comp-ui-asset-bulk-processor-list-item" style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                    <Grid container spacing={2} alignItems="center">
                      <Grid item xs={4}>
                        {img.added ? (
                          <Typography sx={{ color: '#292444' }}>{img.title}</Typography>
                        ) : (
                          <TextField
                            label="Title"
                            value={img.title}
                            onChange={(e) =>
                              handleTitleChange(img.id, e.target.value)
                            }
                            fullWidth
                          />
                        )}
                      </Grid>
                      <Grid item xs={4}>
                        {props.context === "default" && (
                          img.added ? (
                            <Typography sx={{ color: '#292444' }}>{projectsByOrgId.find(project => project.project_id === img.project)?.name}</Typography>
                          ) : (
                            <Select
                              value={img.project}
                              onChange={(e) =>
                                handleProjectChange(img.id, e.target.value)
                              }
                              displayEmpty
                              fullWidth
                            >
                              <MenuItem value="">
                                <em>Select Project</em>
                              </MenuItem>
                              {projectsByOrgId.map((project) => (
                                <MenuItem key={project.project_id} value={project.project_id}>{project.name}</MenuItem>
                              ))}
                            </Select>
                          )
                        )}
                        {props.context === "component" && (
                          img.added ? (
                            <Typography sx={{ color: '#292444' }}>{componentsByOrgId.find(component => component.component_id === img.component)?.name}</Typography>
                          ) : (
                            <Select
                              value={img.component}
                              onChange={(e) =>
                                handleComponentChange(img.id, e.target.value)
                              }
                              displayEmpty
                              fullWidth
                            >
                              <MenuItem value="">
                                <em>Select Component</em>
                              </MenuItem>
                              {componentsByOrgId.map((component) => (
                                <MenuItem key={component.component_id} value={component.component_id}>{component.name}</MenuItem>
                              ))}
                            </Select>
                          )
                        )}
                        {props.context === "journey-wizard" && (
                          img.added ? (
                            <Typography sx={{ color: '#292444' }}>{img.journeyStep}</Typography>
                          ) : (
                            <>
                            {/*<div>debug journey ID: {props.journeyID}</div>*/}
                            <TextField
                              label="Step or Screen Name"
                              value={img.journeyStep || ''}
                              onChange={(e) =>
                                handleJourneyStepChange(img.id, e.target.value)
                              }
                              fullWidth
                            />
                            </>
                          )
                        )}
                        {props.context === "anchor" && (
                          img.added ? (
                            <Typography sx={{ color: '#292444' }}>{anchorsObjectivesByProjectId.find(anchor => anchor.anchor_id === img.anchor)?.anchor_name}</Typography>
                          ) : (
                            <Select
                              value={img.anchor}
                              onChange={(e) =>
                                handleAnchorChange(img.id, e.target.value)
                              }
                              displayEmpty
                              fullWidth
                            >
                              <MenuItem value="">
                                <em>Select Anchor</em>
                              </MenuItem>
                              {anchorsObjectivesByProjectId.map((anchor) => (
                                <MenuItem key={anchor.anchor_id} value={anchor.anchor_id}>{anchor.anchor_name}</MenuItem>
                              ))}
                            </Select>
                          )
                        )}
                      </Grid>
                      <Grid item xs={2}>
                        {img.added ? (
                          <Typography sx={{ color: '#292444' }}>Added</Typography>
                        ) : (
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() => handleAddImage(img.id)}
                          >
                            Add
                          </Button>
                        )}
                      </Grid>
                    </Grid>
                  </div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                  {props.context === "journey-wizard" && (
                    img.added ? (
                      <Typography sx={{ color: '#292444' }}>User Personas List</Typography>
                    ) : (
                      <CompCollapsibleContainer containerHeading="User Personas for this Step" expanded={expanded} onChange={handleAccordionChange}>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                          {userPersonas.map((persona) => (
                            <FormControlLabel
                              key={persona.user_persona_id}
                              control={
                                <Checkbox
                                  checked={(img.selectedPersonas || []).some(selected => selected.user_persona_id === persona.user_persona_id)}
                                  onChange={(e) => handlePersonaCheckboxChange(img.id, e)}
                                  value={persona.user_persona_id}
                                  name={persona.name}
                                />
                              }
                              label={<Typography sx={{ color: '#292444' }}>{persona.name}</Typography>}
                            />
                          ))}
                        </div>
                      </CompCollapsibleContainer>
                    )
                  )}
                </div>
              </ListItem>
              </>
            ))}
          </List>
        </>
      )}
    </Box>
  );
};

export default CompUIAssetBulkProcessor;
