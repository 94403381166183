import React, { useState, useEffect, useContext } from 'react';
import './CompSubjectivePanel.css';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import PageviewIcon from '@mui/icons-material/Pageview';
import { PanelContext } from '../Contexts/PanelContext';
import CompUserAssignment from './CompUserAssignment.jsx';
import CompQuickActionsPopover from './CompQuickActionsPopover.jsx';
import CompQuickCreateSubjective from './CompQuickCreateSubjective.jsx';
import { getSubjectivesByObjectiveId } from '../fxAPI/fxSubjectivesAPI.js';
import CompCollapsibleContainer from './CompCollapsibleContainer.jsx';
import CompQuickComplete from './CompQuickComplete.jsx';
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';

const CompSubjectivePanel = (props) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [subjectiveCreateIsExpanded, setSubjectiveCreateIsExpanded] = useState(false);
  const [subjectivesByObjective, setSubjectivesByObjectiveData] = useState([]);
  const [subjectiveCount, setSubjectiveCount] = useState(0);
  const [error, setError] = useState(null);
  const [panelToUpdate, setPanelToUpdate] = useState('subjectivePanel');
  const [isContentLoaded, setIsContentLoaded] = useState(!props.deferLoading);

  const { elements, triggerElementRefresh, resetElementRefresh } = useContext(PanelContext);
  //const elementId = 'subjectivePanel';

  // Fetch subjective data
  const fxAPIFetchSubjectivesByObjectiveId = async (objectiveId) => {
    const data = await getSubjectivesByObjectiveId(objectiveId, setError, setSubjectivesByObjectiveData);
    if (data) {
      setSubjectiveCount(data.length);
    } else {
      setSubjectiveCount(0);
    }
    console.log('Fetched subjectives by objective ID');
  };

  // Handle refresh for the specific element
  const handleRefresh = async () => {
    console.log('Refreshing subjective panel data');
    await fxAPIFetchSubjectivesByObjectiveId(props.relatedObjective);
    resetElementRefresh(panelToUpdate); // Reset the refresh flag after completion
  };

  // Effect to fetch data on component mount and when refresh flag is triggered
  useEffect(() => {
    if (!props.deferLoading) {
      fxAPIFetchSubjectivesByObjectiveId(props.relatedObjective);
    } else {
      // Fetch only the count initially
      getSubjectivesByObjectiveId(props.relatedObjective, setError, (data) => setSubjectiveCount(data.length));
    }
  }, [props.relatedObjective, props.deferLoading]);

  // Effect to handle refresh logic when the flag changes
  useEffect(() => {
    if (elements[panelToUpdate]?.refresh) {
      handleRefresh(); // Trigger the refresh only when needed
      triggerElementRefresh(panelToUpdate); // Re-set refresh flag to prevent re-triggering
    }
  }, [elements[panelToUpdate]?.refresh, triggerElementRefresh, resetElementRefresh]);

  useEffect(() => {
    if (isExpanded && props.deferLoading) {
      setIsContentLoaded(true);
      fxAPIFetchSubjectivesByObjectiveId(props.relatedObjective);
    }
  }, [isExpanded, props.deferLoading, props.relatedObjective]);

  const handleToggleItem = (toggleValue) => {
    setIsExpanded(toggleValue);
    console.log('Panel toggle:', toggleValue);
  };

  const handleToggleSubjectiveCreate = (toggleValue) => {
    setSubjectiveCreateIsExpanded(toggleValue);
    console.log('Toggle subjective create:', toggleValue);
  };

  const handleSwitchPanelContext = (object_id, type, object) => {
    //updatePanelContext("objective", objective_id, object);
    localStorage.setItem("fxSidePanelContext", type);
    localStorage.setItem("fxSidePanelObjectId", object_id);
    localStorage.setItem("fxSidePanelObject", JSON.stringify(object));
    setPanelToUpdate('sideContextPanel');
    triggerElementRefresh(panelToUpdate);
  };



  return (
    <div>
      <CompCollapsibleContainer 
        containerHeading={props.panelName} 
        itemCount={subjectiveCount}
        expanded={isExpanded}
        onChange={(event, expanded) => handleToggleItem(expanded)}
        deferLoading={props.deferLoading}
        actionSet={props.actionSet}
      >
        {isContentLoaded && (
          <div className="fx-comp-subjective-panel-content">
            {subjectivesByObjective.map((subjective) => (
              <div key={subjective.subjective_id} className="fx-comp-subjective-panel-item-outer">
                <div>
                <CompQuickComplete objectType="subjective" relatedObjectId={subjective.subjective_id} currentProgressStatus={subjective.status} />
                </div>
                <div className="fx-comp-subjective-panel-view-name">{subjective.name}</div>
                <div className="fx-comp-subjective-panel-item-right-actions">
                  <div className="fx-comp-subjective-panel-view-icon">
                    <CompUserAssignment
                      userAssigned={subjective.user_assigned}
                      componentType="subjective"
                      componentData={subjective}
                    />
                  </div>
                  <div>
                    <CompQuickActionsPopover
                      relatedObjectType="subjective"
                      relatedObjectId={subjective.subjective_id}
                      componentData={subjective}
                      relatedContext="subjectivePanel"
                    />
                  </div>
                  {/*<div><CompQuickCreateStatusModal /></div>*/}
                  <div className="fx-comp-subjective-panel-view-icon" onClick={() => handleSwitchPanelContext(subjective.subjective_id, "subjective", subjective)}>
                    <ArrowOutwardIcon />
                  </div>
                </div>
              </div>
            ))}
            <div className="fx-comp-node-breakdown-add-item-phrase" onClick={() => handleSwitchPanelContext(props.relatedObjective, "create-subjective", null)}><AddCircleOutlineIcon /> Add Subjective</div>

            {/*
            <CompCollapsibleContainer containerHeading="Create Subjective">
              <CompQuickCreateSubjective
                    relatedObjective={props.relatedObjective}
                    relatedProject={props.relatedProject}
                    refreshParent={props.refreshParent}
                  />
            </CompCollapsibleContainer>
            */}
          </div>
        )}
      </CompCollapsibleContainer>
    </div>
  );
};

export default CompSubjectivePanel;