import axios from 'axios';

const createBusinessValue = async (name, description, category, value, related_org, related_project, status, related_milestone) => {
    const setError = (message) => {
      console.error(message);
    };
  
    const setSuccessMessage = (message) => {
      console.log(message);
    };
  
    setError(null);
    setSuccessMessage(null);

    const business_value_id = generateUUID();
  
    if (!name) {
      setError('Name is required');
      return;
    }
  
    try {
      console.log(`Creating new business value with name: ${name} and related organization: ${related_org}`);
  
      const response = await axios.post('/fxapi/business-value/create', {
        business_value_id,
        name,
        description,
        category,
        value,
        related_org,
        related_project,
        status,
        related_milestone
      });
  
      console.log('API response received:', response.data);
      setSuccessMessage('Business Value created successfully');
    } catch (error) {
      console.error('Error creating business value:', error);
      setError(error.response ? error.response.data : 'Failed to create business value');
    }
  };

const updateBusinessValue  = async (businessValueId, name, description, category, value, related_org, related_project, status) => {
    const setError = (message) => {
      console.error(message);
    };

    setError(null);

    if (!name) {
      setError('Name is required');
      return;
    }

    try {
      const response = await axios.put(`/fxapi/business-value/update/${businessValueId}`, {
        business_value_id: businessValueId,
        name,
        description,
        category,
        value,
        related_org,
        related_project,
        status
      });
  
      console.log('Response:', response.data);
    } catch (error) {
      console.error('Error:', error.response ? error.response.data : error.message);
    }
  };

  const getBusinessValueByOrgId = async (orgId, setError, setBusinessValueByOrgData) => {
    setError(null);
    setBusinessValueByOrgData([]); // Reset Objectives data to an empty array
  
    if (!orgId) {
      setError('orgId parameter is required');
      return;
    }
  
    try {
      console.log(`Fetching BusinessValue data for orgId: ${orgId}`);
      const response = await axios.get('/fxapi/business-value/business-value-by-org', {
        params: { orgId }
      });
      console.log('API response received:', response.data.items);
  
      const items = Array.isArray(response.data.items) ? response.data.items : [];
      console.log('Setting design sessions data:', items);
      setBusinessValueByOrgData(items); // Correctly update the state with received data
      console.log('Updated design sessions data:', items);
    } catch (error) {
      console.error('Error fetching design sessions data:', error);
      setError(error.response ? error.response.data : 'Failed to fetch design sessions data');
    }
  };

  const getBusinessValueById = async (businessValueId, setError, setBusinessValueByIdData) => {
    setError(null);
    setBusinessValueByIdData([]); // Reset Objectives data to an empty array
  
    if (!businessValueId) {
      setError('businessValueId parameter is required');
      return;
    }
  
    try {
      console.log(`Fetching BusinessValue data for businessValueId: ${businessValueId}`);
      const response = await axios.get('/fxapi/business-value/business-value-by-id', {
        params: { businessValueId }
      });
      console.log('API response received:', response.data.items);
  
      const items = Array.isArray(response.data.items) ? response.data.items : [];
      console.log('Setting business value data:', items);
      setBusinessValueByIdData(items); // Correctly update the state with received data
      console.log('Updated business value data:', items);
    } catch (error) {
      console.error('Error fetching business value data:', error);
      setError(error.response ? error.response.data : 'Failed to fetch business value data');
    }
  };

  const deleteBusinessValue = async (businessValueId, setError, setDeleteResponse) => {
    setError(null);
    setDeleteResponse(null); // Reset delete response
  
    if (!businessValueId) {
      setError('businessValueId parameter is required');
      return;
    }
  
    try {
      console.log(`Deleting businessValueId: ${businessValueId}`);
      const response = await axios.delete(`/fxapi/business-value/delete/${businessValueId}`);
      console.log('API response received:', response.data);
  
      // Convert response data to plain text
      const successMessage = `Successfully deleted business value with ID: ${businessValueId}`;
      setDeleteResponse(successMessage); // Update the state with plain text
      console.log('Updated delete response:', successMessage);
    } catch (error) {
      console.error('Error deleting business value:', error);
  
      // Convert error to plain text
      const errorMessage = error.response ? error.response.data : 'Failed to delete business value';
      setError(typeof errorMessage === 'string' ? errorMessage : JSON.stringify(errorMessage));
    }
  };

  // Helper function to generate a UUID
function generateUUID() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      const r = (Math.random() * 16) | 0, v = c === 'x' ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  }

  export { createBusinessValue, updateBusinessValue, getBusinessValueByOrgId, getBusinessValueById, deleteBusinessValue };