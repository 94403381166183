import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Drawer, IconButton, Menu, MenuItem } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import './CompWebMobileMenu.css';

const CompWebMobileMenu = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleNavigation = (route) => {
    navigate(route);
    handleDrawerToggle();
    handleMenuClose();
  };

  return (
    <div className="fx-comp-web-mobile-menu">
      <div className="fx-comp-web-mobile-menu-icon">
        <IconButton onClick={handleDrawerToggle}>
          <MenuIcon />
        </IconButton>
      </div>
      <Drawer
        anchor="left"
        open={drawerOpen}
        onClose={handleDrawerToggle}
        className="fx-comp-web-mobile-drawer"
      >
        <MenuItem onClick={() => handleNavigation('/')}>Home</MenuItem>
        <MenuItem onClick={() => handleNavigation('/for-investors')}>For Investors</MenuItem>
        <MenuItem onClick={() => handleNavigation('/contact')}>Contact</MenuItem>
      </Drawer>
      <div className="fx-comp-web-desktop-menu">
        <IconButton onClick={handleMenuOpen}>
          <MenuIcon />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
        >
          <MenuItem onClick={() => handleNavigation('/')}>Home</MenuItem>
          <MenuItem onClick={() => handleNavigation('/for-investors')}>For Investors</MenuItem>
          <MenuItem onClick={() => handleNavigation('/contact')}>Contact</MenuItem>
        </Menu>
      </div>
    </div>
  );
};

export default CompWebMobileMenu;
