import React, { useEffect, useState, useContext } from 'react';
import { useParams, useLocation } from 'react-router-dom'; // Import useLocation
import { getObjectivesByProjectId } from '../fxAPI/fxObjectivesAPI';
import './CompSideNavObjectiveList.css';
import CompCollapsibleContainer from './CompCollapsibleContainer';
import PageviewIcon from '@mui/icons-material/Pageview';
import { PanelContext } from '../Contexts/PanelContext';
import CompSideNavObjectiveCreate from './CompSideNavObjectiveCreate';
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import DragHandleIcon from '@mui/icons-material/DragHandle'; // Import DragHandleIcon

const CompSideNavObjectiveList = (props) => {
  const { projectId } = useParams();
  const location = useLocation(); // Get the current location
  const [objectives, setObjectives] = useState([]);
  const [error, setError] = useState(null);
  const [panelToUpdate, setPanelToUpdate] = useState('objectiveList');
  const { elements, triggerElementRefresh, resetElementRefresh } = useContext(PanelContext);
  const [isCreateObjectiveExpanded, setIsCreateObjectiveExpanded] = useState(false);

  useEffect(() => {
    const storedProjectId = localStorage.getItem("fxLocalProjectContext");
    const effectiveProjectId = projectId || storedProjectId;
    if (effectiveProjectId) {
      localStorage.setItem("fxLocalProjectContext", effectiveProjectId);
      getObjectivesByProjectId(effectiveProjectId, setError, setObjectives);
    }
  }, [projectId]);

  const handleRefreshElement = () => {
    triggerElementRefresh('sideContextPanel'); // Trigger refresh for the subjectivePanel
  };

  const handleSwitchPanelContext = (object_id, type, object) => {
    console.log('Context Panel attempt: ' + object_id + ' ' + type);
    console.dir(object);

    localStorage.setItem("fxSidePanelContext", type);
    localStorage.setItem("fxSidePanelObjectId", object_id);
    localStorage.setItem("fxSidePanelObject", JSON.stringify(object));
    handleRefreshElement();
  };

  // Handle refresh for the specific element
  const handleRefresh = async () => {
    console.log('Refreshing objective list data');
    const storedProjectId = localStorage.getItem("fxLocalProjectContext");
    const effectiveProjectId = projectId || storedProjectId;
    if (effectiveProjectId) {
      getObjectivesByProjectId(effectiveProjectId, setError, setObjectives);
    }
    resetElementRefresh(panelToUpdate); // Reset the refresh flag after completion
  };

  const handleObjectiveCreated = () => {
    setIsCreateObjectiveExpanded(false);
    handleRefresh();
  };

  useEffect(() => {
    if (elements[panelToUpdate]?.refresh) {
      handleRefresh(); // Trigger the refresh only when needed
      triggerElementRefresh(panelToUpdate); // Re-set refresh flag to prevent re-triggering
    }
  }, [elements[panelToUpdate]?.refresh, triggerElementRefresh, resetElementRefresh]);

  const handleDragStart = (event, object) => {
    const objectWithType = { ...object, type: 'objective' }; // Add type to the object
    event.dataTransfer.setData('object', JSON.stringify(objectWithType));
  };

  const handleDragEnd = (event) => {
    const objectData = event.dataTransfer.getData('object');
    if (objectData) {
      const object = JSON.parse(objectData);
      if (props.onObjectDrop) {
        props.onObjectDrop(object);
      }
    }
  };

  return (
    <div>
      {error && <div className="error">{error}</div>}
      <div className="fx-comp-side-nav-objective-list-create-objective">
        <CompCollapsibleContainer 
          containerHeading="Create Objective" 
          containerStyle="dark" 
          arrowPosition="right"
          expanded={isCreateObjectiveExpanded}
          onChange={() => setIsCreateObjectiveExpanded(!isCreateObjectiveExpanded)}
        >
            <CompSideNavObjectiveCreate onObjectiveCreated={handleObjectiveCreated} />
        </CompCollapsibleContainer>
      </div>
      <ul>
        {objectives.map((objective) => (
          <li 
            key={objective.id} 
            className="fx-comp-side-nav-objective-list-item-wrapper"
            draggable
            onDragStart={(event) => handleDragStart(event, objective)}
            onDragEnd={handleDragEnd} // Add onDragEnd event
          >
            <div className="fx-comp-side-nav-objective-list-item-top-row">
                {(location.pathname.startsWith('/playbook/milestones/') || location.pathname.startsWith('/playbook/journey-map-detail')) && ( // Conditionally render the drag handle
                  <div className="fx-comp-side-nav-objective-list-item-top-row-drag-handle">
                    <DragHandleIcon />
                  </div>
                )}
                <div className="fx-comp-side-nav-objective-list-item-top-row-name" title={objective.name}>
                  {objective.name.length > 20 ? `${objective.name.substring(0, 20)}...` : objective.name}
                </div>
                <div className="fx-comp-side-nav-objective-list-item-top-row-link">
                  <div onClick={() => handleSwitchPanelContext(objective.objective_id, "objective", objective)}>
                    <ArrowOutwardIcon />
                  </div>
                </div>
            </div>
            <div className="fx-comp-side-nav-objective-list-item-top-row-description">{objective.description}</div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default CompSideNavObjectiveList;