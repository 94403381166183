import React, { useState, useEffect } from 'react';
import './CompJourneyImageBlock.css';
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import { getAnchorsPersonasByAnchorId } from '../fxAPI/fxAnchorsAPI';
import { getUIAssetById } from '../fxAPI/fxUIAssetsAPI';

const CompJourneyImageBlock = ({ anchorViewName, anchorId }) => {
    const [anchorData, setAnchorData] = useState(null);
    const [uiAssetData, setUIAssetData] = useState(null);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchAnchorData = async () => {
            await getAnchorsPersonasByAnchorId(anchorId, setError, setAnchorData);
        };

        fetchAnchorData();
    }, [anchorId]);

    useEffect(() => {
        if (anchorData && anchorData.length > 0) {
            const primaryUIAssetId = anchorData[0].primary_ui_asset;
            if (primaryUIAssetId) {
                const fetchUIAssetData = async () => {
                    await getUIAssetById(primaryUIAssetId, setError, setUIAssetData);
                };

                fetchUIAssetData();
            }
        }
    }, [anchorData]);

    return (
        <div>
            <div className='fx-comp-journey-image-block-header-container'>
                <div className='fx-comp-journey-image-block-header-name-item'>{anchorViewName}</div>
                <div className='fx-comp-journey-image-block-header-icon-item'><ArrowOutwardIcon /></div>
            </div>
            {uiAssetData && uiAssetData.length > 0 ? (
                <img src={uiAssetData[0].img_full_url} alt={`Anchor ${anchorViewName}`} style={{ width: '100%', height: 'auto' }} />
            ) : (
                <img src="https://placehold.co/300x400" alt={`Anchor ${anchorViewName}`} style={{ width: '100%', height: 'auto' }} />
            )}
        </div>
    );
};

export default CompJourneyImageBlock;
