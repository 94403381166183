import React, { useState, useEffect, useContext } from 'react';
import './CompObjectiveAnchorCard.css';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import CompSubjectivePanel from './CompSubjectivePanel';
import { getObjectLinksByInitialObjectAndLinkTypeAndOrg } from '../fxAPI/fxObjectLinksAPI';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import { Home, Search, Settings } from '@mui/icons-material';

const CompObjectiveAnchorCard = (props) => {

  const [error, setError] = useState(null);
  const localEnv = localStorage.getItem('fxEnv');
  const [selectedIcons, setSelectedIcons] = useState([]);

  // Handle toggle change
  const handleToggle = (event, newSelection) => {
    if (newSelection === null) return;
    setSelectedIcons(newSelection);
    console.log('toggling selection: ' + newSelection);
  };

  /* OBJECTIVES */
  const [objectLinksByInitialObjectAndLinkTypeAndOrg, setObjectLinksByInitialObjectAndLinkTypeAndOrgData] = useState([]); // Initialize as an array
  /* get objectives by orgId */
  const fxAPIFetchObjectLinksByInitialObjectAndLinkTypeAndOrg = async (initialObject, orgId, linkType) => {
    await getObjectLinksByInitialObjectAndLinkTypeAndOrg(initialObject, orgId, linkType, setError, setObjectLinksByInitialObjectAndLinkTypeAndOrgData);
    console.log('object links by type by org Id');
    console.dir(objectLinksByInitialObjectAndLinkTypeAndOrg);
  };

  {/* 
  const [refresh, setRefresh] = useState(false);

  const handleRefreshAction = () => {
    // Perform some action in the parent
    // After the action, toggle the refresh state
    setRefresh(prev => !prev);
  };

  */}

  useEffect(() => {
    const currentOrgId = localStorage.getItem("fxLocalOrgID");
    fxAPIFetchObjectLinksByInitialObjectAndLinkTypeAndOrg(props.relatedObjective, currentOrgId, "jira-issue");
    console.log('object links by type by org Id in use effect for: ' + props.relatedObjective + ' on org: ' + currentOrgId + "jira issues");
    console.dir(objectLinksByInitialObjectAndLinkTypeAndOrg);
  }, []);

  return (
    <div>
      <CompSubjectivePanel 
                //refreshParent={props.refreshParent} 
                panelName="Subjectives" 
                relatedObjective={props.relatedObjective}
                relatedProject={props.relatedProject}
                deferLoading={true} // Set deferLoading to true
                //relatedObjective={item.objective_id} 
                //relatedProject={props.relatedProject} 
                //refresh={refresh}  // Use refresh to trigger panel update
                //updatePanelContext={(contextType, objectId, object) => props.updatePanelContext(contextType, objectId, object)}
              />

              {/*
      {localEnv !== 'production' &&
      <>
      
        <div className="fx-comp-objective-anchor-card-toggle-row">    
          <div>
            <ToggleButtonGroup
              value={selectedIcons}
              onChange={handleToggle}
              aria-label="icon toggle group"
              multiple
            >
              <ToggleButton value="jira" aria-label="jira">
                J
              </ToggleButton>
            </ToggleButtonGroup>
          </div>
          <div className="fx-comp-objective-anchor-card-toggle-row-label">Related Items: </div>
        </div>
        {selectedIcons.includes('jira') && (
        <>
          <div><strong>Related Jira Issues:</strong></div>
          {objectLinksByInitialObjectAndLinkTypeAndOrg.map((objectLink) => (
                      <div key={objectLink.link_id} className="fx-comp-objective-anchor-card-related-item">
                        {objectLink.linking_object_id}
                      </div>
          ))}
                     
        </>
        
        )}
      </>
      }
       */}
    </div>
  );
};

export default CompObjectiveAnchorCard;